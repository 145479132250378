import React from 'react';
import './PlanOutlineButtons.css';
import '../../colors.css';
import osisConverters from '../../utilities/osisConversions';
import { Capacitor } from '@capacitor/core';
import Devotionals from '../../plans/Devotionals';

const PlanOutlineButtons = (props) => {
    const range = [];
    let content = props;
    let osisRange = "";
    const applicationQuestions = props.applicationQuestions;
    const comprehensionQuestions = props.comprehensionQuestions;
    const centeringPrayer = props.centeringPrayer;
    const commentary = props.commentary;
    const application = props.application;
    const meditation = props.meditation;
    const applicationPrayer = props.applicationPrayer;
    const crossReferences = props.crossReferences;
    const devotional = props.devotional;

    if (!content)
        content = "Undefined!"

    // console.log('osisRangeIn: ', osisRange)
    
    if (typeof content === "string")
        osisRange = content;
    else if (content.osisRange)
        osisRange = content.osisRange;
    else if (content.ref)
        osisRange = content.ref;

    const colorIndex = props.colorIndex || 0;
    const {
        osisBookStart,
        osisBookEnd,
        osisChapterStart,
        osisChapterEnd,
        osisVerseStart,
        osisVerseEnd,
    } = osisConverters.deconstructOsisRange(osisRange);

    let title= osisBookStart + " " + osisChapterStart + "." + osisVerseStart + "-" + osisChapterEnd + "." + osisVerseEnd;

    if (!osisVerseStart)
    {
        if (osisChapterEnd === osisChapterStart)
            title = osisBookStart + " " + osisChapterStart;
        else
            title = osisBookStart + " " + osisChapterStart + "-" + osisChapterEnd;
    }

    if ((osisChapterEnd == osisChapterStart)&&(osisVerseEnd == osisVerseStart))
    {
        title = osisBookStart + " " + osisChapterStart + "." + osisVerseStart;
    }

    if (content.title)
        title = content.title;

    title = title.replace('.',':').replace('.',':');

    if (props.index)
        title = props.index + ". " + title;

    let href= window.getVerseURL(osisRange);

    if (href)
    {
        let url = new URL("http://www.ai-bible.com");

        if (devotional)
            url.searchParams.append('dev', encodeURIComponent(JSON.stringify(devotional)))
        else
        {
            if (applicationQuestions)
                url.searchParams.append('aq', encodeURIComponent(JSON.stringify(applicationQuestions)))

            if (comprehensionQuestions)
                url.searchParams.append('cq', encodeURIComponent(JSON.stringify(comprehensionQuestions)))

            if (centeringPrayer)
                url.searchParams.append('cp', encodeURIComponent(centeringPrayer))

            if (commentary)
                url.searchParams.append('c', encodeURIComponent(commentary))

            if (application)
                url.searchParams.append('a', encodeURIComponent(application))

            if (meditation)
                url.searchParams.append('m', encodeURIComponent(meditation))

            if (applicationPrayer)
                url.searchParams.append('ap', encodeURIComponent(applicationPrayer))

            if (crossReferences)
                url.searchParams.append('cr', encodeURIComponent(JSON.stringify(crossReferences)))
        }

        href = url.href.replace("http://www.ai-bible.com/", href);
    }

    if (content.href)
    {
        href = content.href;
    }

    // console.log("Outline Button: ", title, href, devotional)
    
    return (
        <div className="outline-buttons">
            <a
                className={`color-${colorIndex} outline-button`}
                href={href}
                target={ (window.web_version)?"_blank":"_parent" } 
                onClick={() => { console.log('VerseButton: Click: ', osisRange, href); }}
            >
                <p className="outline-button-text">
                    {title}
                </p>
            </a>
        </div>
    );
 
};

export default PlanOutlineButtons;
