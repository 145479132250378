export default class DailyLocalStorage {
    constructor(storageKey) {
      this.storageKey = storageKey;
    }
  
    // Store data with a date key
    set(date, data) {
      const dateKey = this.getDateKey(date);
      localStorage.setItem(dateKey, JSON.stringify(data));
    }

    setToday(data)
    {
        return this.set(new Date(), data);
    }
  
    // Retrieve data for a specific date
    get(date) {
      const dateKey = this.getDateKey(date);
      const data = localStorage.getItem(dateKey);
      return data ? JSON.parse(data) : null;
    }
  
    // Retrieve data for today
    getToday() {
        return this.get(new Date());
    }

    getYesterday() {
        let yesterday = new Date();
        yesterday.setDate(new Date().getDate() - 1)
        // Subtract one day from the current date
        return this.get(yesterday);
    }
  
    // Utility to generate a date-specific key
    getDateKey(date) {
      const d = new Date(date);
      return `${this.storageKey}-${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    }
  
    // Remove data for a specific date
    remove(date) {
      const dateKey = this.getDateKey(date);
      localStorage.removeItem(dateKey);
    }
  
    // Clear all data managed under this storage key
    clearAll() {
      Object.keys(localStorage)
        .filter(key => key.startsWith(`${this.storageKey}-`))
        .forEach(key => localStorage.removeItem(key));
    }
  }