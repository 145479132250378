/* eslint-disable max-classes-per-file */
import React from 'react';
import { ThemeContext } from '../Core/ThemeContext';
import {
    SourceFigure,
} from '../Core/TextComponents';
import Collapsable from '../Core/Collapsable';
import WordCloud from './WordCloud';

class PersonCloud extends React.Component {
    static contextType = ThemeContext;

    render() {
        const words = [];

        if (this.props.who) {
            this.props.who.known.forEach((value) => {
                words.push({
                    text: value.name,
                    value: value.score,
                });
            });
        }

        return (
            (this.props.who) ? (
                <Collapsable startsOpen={window.web_version} title="WordCloud: People and People Groups">
                    <WordCloud style={{ width: '100%', height: 700, minHeight: 600 }} words={words}>
                        The WordCloud
                    </WordCloud>
                    <SourceFigure
                        {...this.props}
                        figureName="Word cloud of most common people and people groups"
                    />
                </Collapsable>
            ) : null
        );
    }
}

export default PersonCloud;
