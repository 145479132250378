import React from 'react';
import { VerseBox, VerseContainerBox } from './VerseBox';
import VerseButton from './VerseButton';
import './Outline.css';
import bookConverters from '../../utilities/bookConversions';

// Verse outline uses the outline pattern to display a list of verses 
// title
// data - a 
const VerseOutline = (props) => {

    const books = [];
    const title = props.title || 'Outline';
    const colorIndex = props.colorIndex || 0;
    const BookOutlineBox = VerseContainerBox;
    const SectionOutlineBox = VerseBox;

    
    if (props.data)
    {
        Object.keys(props.data).forEach((book)=>{
            books.push(props.data[book]);
        })
    }

    console.log('props.data', props.data, books)

    // eslint-disable-next-line max-len
    const colorText = getComputedStyle(document.documentElement).getPropertyValue(`--color-raw-${colorIndex}`);

    return (
        <div>
            {books.map((book, index1)=>(
                <BookOutlineBox title={book.Name} key={index1} colorIndex={bookConverters.convertSubGroupToIndex(book.Subgroup)} bold>
                    <div>
                        {book.sections.map((section, index5) => {
                            const adjMargin = (index5 === 0) ? 0 : 40;
                            console.log('subgroup: ',bookConverters.convertSubGroupToIndex(book.Subgroup),section)
                            // console.log('book', section, index5, book.sections);
                            // Add each section
                            return (
                                <SectionOutlineBox
                                    style={{ marginBottom: adjMargin }}
                                    title={section.title}
                                    key={index5 + "_" + Math.floor(Math.random() * 1000)}
                                    colorIndex={bookConverters.convertSubGroupToIndex(book.Subgroup)}
                                    subsections={section.subsections}
                                    stronger
                                    >
                                    {
                                        (section.contents)?
                                            section.contents.map((content, index3) => (
                                                <VerseButton
                                                    colorText={colorText}
                                                    content={content}
                                                    key={index3}
                                                    colorIndex={bookConverters.convertSubGroupToIndex(book.Subgroup)}
                                                    />
                                            )):null
                                    }
                                    
                                </SectionOutlineBox>
                            );
                        })}
                    </div>
                </BookOutlineBox>
            ))}
        </div>
    );
};

export default VerseOutline;
