import React, { Component } from 'react';
import { How } from './components/Answers/How';
import { When } from './components/Answers/When';
import { Why } from './components/Answers/Why';
import HeadingsList from './components/Lists/HeadingsList';
import BookFacts from './components/BookFacts';
import BookSummary from './components/BookSummary';
import { ConcordanceList } from './components/Lists/ConcordanceList';
import RepeatedWordCloud from './components/Charts/RepeatedWordCloud';
import { LocationList } from './components/Lists/LocationList';
import { PersonList } from './components/Lists/PersonList';
import PersonCloud from './components/Charts/PersonCloud';
import { ThemeContext } from './components/Core/ThemeContext';
import CardHolder from './components/Cards/CardHolder';
import { Capacitor } from '@capacitor/core';
import {
    SmallGutter, LargeGutter, BodyText, SubheaderText, HeaderExt, SourcePermissions, SourceBibliography,
} from './components/Core/TextComponents';
import { withAlert } from 'react-alert';

const querystring = require('querystring');

class ExegesisResult extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            title: '',
            data: {
                details: {
                    words: [],
                },
            },
            loading: true,
            sources: {},
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        console.log(`ComponentDidMount: ${new Date().getSeconds()}`);
        const values = querystring.parse(window.location.search.substr(1));
        const { token } = localStorage;
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.context.loading(true);

        console.log('values', values);

        let endpoint = 'http://localhost:5001' + `/v1/verses/overview/search`;

        if ((process.env.REACT_APP_AWS_API)||(Capacitor.getPlatform()!=='web'))
        {
            endpoint = window.API_BASE + `/v1/verses/overview/search`;
        }

        console.log('Attempting contact with endpoint: ', endpoint);
        if (endpoint !== '') {
            fetch(endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: "POST",
                body: JSON.stringify({ search: values.verse})
            })
                .then((res) => res.json())
                .then((response) => {
                    // console.log("Response: " + new Date().getSeconds());
                    // console.log("response: " + JSON.stringify(response));
                    const data = response.overview;
                    const sources = response.sourceinfo;

                    const title = `Exegesis: ${values.verse}`;

                    console.log('<><><><><><>LOADED EXEGISIS: ', response);

                    this.setState({
                        loading: false, data, title, sources,
                    });

                    this.context.loading(false);
                }).catch((e)=>{
                    this.context.loading(false);
                    console.log('exception: ', e);
                    this.props.alert.show('could not connect to server or invalid query');
                });
        }
    }

    render() {
        const { verse } = querystring.parse(window.location.search.substr(1));
        document.title = verse ? `Exegesis: ${verse}` : 'Exegisis';

        let notesCounted = 0;

        const noteCounter = (sources) => {
            let count = 0;

            if (sources == null) return notesCounted;

            if (sources === []) return notesCounted;

            // console.log('Sources', sources, typeof sources);

            for (let i = 0; i < sources.length; i++) {
                const source = sources[i];
                if (source) {
                    if (source === 1) {
                        count += 1;
                    } else {
                        for (let j = 0; j < source.length; j++) {
                            const note = source[j];
                            // console.log('Note', note, typeof note);
                            if (note) {
                                if (note.hasOwnProperty('footnote')) count += 1;
                            }
                        }
                    }
                }
            }

            notesCounted += count;
            return notesCounted;
        };

        const aggregated_sources = this.state.sources;
        console.log(`Sources: ${new Date().getSeconds()}`, aggregated_sources);
        console.log('State: ', this.state);
        return (
            <div style={{ padding: '10px' }}>

                {(this.state.data.book)
                    ? (
                        <ol style={{ margin: 0, padding: 0 }}>
                            <CardHolder
                                size="93"
                                value=""
                                src={`/images/${this.state.data.book.BookCode}.jpg`}
                                detailsheader={this.state.data.book.Name}
                                details={this.state.data.book.Summary}
                                detailsFootnote={this.state.data.footnoteStart}
                                link={this.state.data.book.BookCode}
                            />
                        </ol>
                    ) : null}
                <div style={{
                    margin: 15,
                }}
                >
                    {/* <TitleLine title={this.state.title} visible={!this.state.loading} /> */}

                </div>
                <>
                    <HeaderExt description="Information about the book that the selected passage comes from">
                        Book Information
                    </HeaderExt>
                    <LargeGutter />
                    <BookFacts book={this.state.data.book} footnotes={this.state.sources.book} footnoteStart={noteCounter([1])} />
                    <LargeGutter />
                    <BookSummary book={this.state.data.book_summary} footnotes={this.state.sources.book_summary} footnoteStart={noteCounter([this.state.sources.book])} />
                </>
                <BodyText>&nbsp;</BodyText>
                <>
                    <HeaderExt description="Information about timing and tense of the passage">
                        When
                    </HeaderExt>
                    <When when={this.state.data.when} sentences={this.state.data.byverse} sources={aggregated_sources} footnotes={this.state.sources.when} footnoteStart={noteCounter([this.state.sources.when])} footnoteNums={[notesCounted, notesCounted + 2]} />
                </>
                <BodyText>&nbsp;</BodyText>
                <>
                    <HeaderExt description="What themes and repeated terms are in the passage">
                        What
                    </HeaderExt>
                    <HeadingsList literarycontext={this.state.data.literarycontext} sources={this.state.sources.literarycontext} figureNumber={2} />
                    <RepeatedWordCloud sentence={this.state.data.details.words} verses={this.state.data.byverse} showcount="true" showgrammer="true" footnotes={this.state.sources.details} sources={this.state.sources.details} figureNumber={3} footnoteStart={noteCounter([this.state.sources.details])} />
                    <ConcordanceList sentence={this.state.data.details.words} verses={this.state.data.byverse} showcount="true" showgrammer="true" footnotes={this.state.sources.details} sources={this.state.sources.details} figureNumber={3} footnoteStart={noteCounter([this.state.sources.details])} />
                </>
                <BodyText>&nbsp;</BodyText>
                <>
                    <HeaderExt description="Who are the people mentioned in the passages?">
                        Who
                    </HeaderExt>
                    <PersonCloud who={this.state.data.who} sources={this.state.sources.who} footnotes={this.state.sources.who} figureNumber={5} />
                    <PersonList who={this.state.data.who} sources={this.state.sources.who} footnotes={this.state.sources.who} figureNumber={5} footnoteStart={noteCounter([1])} />
                </>
                <BodyText>&nbsp;</BodyText>
                <>
                    <HeaderExt description="What places are mentioned in the passages?">
                        Where
                    </HeaderExt>
                    <LocationList where={this.state.data.where} sources={this.state.sources.where} footnotes={this.state.sources.where} figureNumber={9} footnoteStart={noteCounter([1])} />
                </>
                <BodyText>&nbsp;</BodyText>
                <>
                    <HeaderExt description="How should I apply or take this content?">
                        How
                    </HeaderExt>
                    <How data={this.state.data} sources={this.state.sources} details={this.state.details} figureNumber={12} />
                </>
                <BodyText>&nbsp;</BodyText>
                <>
                    <HeaderExt description="How does this passage fit into larger meta narratives?">
                        Why
                    </HeaderExt>
                    <LargeGutter />
                    <Why why={this.state.data.why} sources={this.state.sources.why} figureNumber={14} />
                </>
                <BodyText>&nbsp;</BodyText>
                <>
                    <HeaderExt description="Copyright notices and bibliography">
                        Bibliography and Credits
                    </HeaderExt>
                    <SmallGutter />
                    <SubheaderText>Copyright and permissions</SubheaderText>
                    <SourcePermissions sources={this.state.sources.all} />
                    <LargeGutter />
                    <SubheaderText>Bibliography</SubheaderText>
                    <SourceBibliography bibliography={this.state.sources.all} />
                </>
            </div>
        );
    }
}

export default withAlert()(ExegesisResult);
