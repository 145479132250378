import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { ThemeContext } from '../Core/ThemeContext';
import '../../index.css';
import '../../bootstrap-4/css/bootstrap.css';
import './Cards.css';
import { FootnoteReference } from '../Core/TextComponents';
import { Collapse } from 'react-bootstrap';

const descriptionStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'wrap',
    flexBasis: 'fill',
    width: '100%',
    paddingBottom: 0,
    marginBottom: 0,
};

class PersonCard extends Component {
    // eslint-disable-next-line
    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = { open: false };
        this.handleSelect = this.handleSelect.bind(this);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    handleSelect(tab) {
        console.log('PersonCard: Selected: ', tab, this);
    }

    render() {
        let color = 'grey';
        if (this.props.gender) {
            if (this.props.gender.indexOf('M') === 0) color = 'blue';
            if (this.props.gender.indexOf('F') === 0) color = 'pink';
            if (this.props.gender.indexOf('P') === 0) color = '1';
        }
        // console.log('person', this.props.detailsheader, color, this.props.gender);

        return (
            <div>
                <Card
                    style={{
                        display: 'flex',
                        background: '#fff',
                        margin: 0,
                        borderRadius: 0,
                    }}
                    className="cards"
                >

                    <div className="card-size" style={{ display: 'inline-block', float: 'left' }}>
                        <img
                            alt=""
                            className={`background-grad-${color} card-size`}
                            style={{
                                pointerEvents: 'none',
                                width: 20,
                                height: '100%',
                                minWidth: 20,
                                display: 'inline-block',
                                opacity: 20,
                            }}
                        />
                    </div>

                    <CardContent
                        style={descriptionStyle}
                        onClick={() => {
                            this.setState((prevState) => ({ open: !prevState.open }));
                        }}
                    >
                        <h4 variant="h6" component="h3">
                            {this.props.detailsheader}
                        </h4>
                        <p
                            style={{
                                display: 'inline',
                                color: '#667',
                            }}
                        >
                            meaning:&nbsp;
                            {this.props.details}
                        </p>
                        {(this.props.footnoteRef)
                            ? <FootnoteReference number={this.props.footnoteRef} /> : null}
                    </CardContent>
                    <CardActions align="end">
                        <a
                            href={`/people?rec=${this.props.link}`}
                        >
                            {(this.props.src.indexOf('UNK') > -1) ? 'SEARCH' : 'EXPLORE'}
                        </a>
                    </CardActions>
                </Card>
                <Collapse in={this.state.open}>
                    <div>
                        <p>{this.props.text}</p>
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default PersonCard;
