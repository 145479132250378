import React, { useState } from 'react';
import {
    Tabs, Tab,
} from 'react-bootstrap-legacy';
import '../../index.css';


const ColorStrip = (props) => {
    const [key, setKey] = useState('all');
    const { items, onSelect } = props;

    console.log('Items:', items, props)

    // const elements = [
    //     '\u00A0\u00A0\u00A0\u00A0\u00A0The Law\u00A0\u00A0\u00A0\u00A0\u00A0',
    //     '\u00A0\u00A0\u00A0Historical\u00A0\u00A0\u00A0',
    //     '\u00A0\u00A0\u00A0\u00A0\u00A0Wisdom\u00A0\u00A0\u00A0\u00A0\u00A0',
    //     '\u00A0Major Prophets\u00A0',
    //     '\u00A0Minor Prophets\u00A0',
    //     '\u00A0Gospel & Acts\u00A0\u00A0',
    //     'Pauline Epistles',
    //     'General Epistles',
    // ];

    // const elements = [
    //     'The Law',
    //     'Historical',
    //     'Wisdom',
    //     'Major Prophets',
    //     'Minor Prophets',
    //     'Gospel & Acts',
    //     'Pauline Epistles',
    //     'General Epistles',
    // ];

    const handleSelect = (tab) => {
        console.log(`ColorStrip: Selected: t:${tab} k:${key} this:${this}`);
        if (tab === key) {
            setKey('all');
            console.log('setkey all');
        }
        if (tab !== '') setKey(tab);
        onSelect(tab);
    };

    return (
        <div>
            <Tabs
                className="customTabsN customTabs-color-n"
                style={{
                    paddingTop: 0,
                }}
                activeKey={key}
                defaultActiveKey="all"
                onSelect={handleSelect}
                fill
                id="uncontrolled-tab-example"
            >
                { items.map((element, index) => (
                    <Tab
                        className={`customTab${index + 20}`}
                        eventKey={element}
                        title={element}
                        key={element}
                    />
                ))}
            </Tabs>
        </div>
    );
};

export default ColorStrip;
