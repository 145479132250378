/* eslint-disable max-classes-per-file */
import React from 'react';
import { ThemeContext } from '../Core/ThemeContext';
import PersonCard from '../Cards/PersonCard';
import {
    SourceCredits, SubheaderText, SourceFigureAndFootnotes, SourceFigure, SourceFootnotes, SmallGutter,
} from '../Core/TextComponents';
import Collapsable from '../Core/Collapsable';

function SinglePerson(props) {
    let src = '/images/MALE.jpg';
    if (props.gender.indexOf('F') === 0) src = '/images/FEMALE.jpg';
    if (props.gender.indexOf('P') === 0) src = '/images/PEOPLEGROUP.jpg';
    if (props.gender.indexOf('U') !== 0) {
        return (
            <PersonCard
                size="55"
                value=""
                gender={props.gender}
                index={props.index}
                src={src}
                key={props.index}
                detailsheader={(props.score)?`${props.name} - mentions: ${props.score}`:props.name}
                details={props.meaning}
                link={props.ref}
                text={props.description}
                footnoteRef={props.footnoteStart}
            />
        );
    }
    return (<div />);
}

class PersonList extends React.Component {
    static contextType = ThemeContext;

    render() {
        const { footnoteStart } = this.props;
        const words = [];

        if (this.props.who) {
            this.props.who.known.forEach((value) => {
                words.push({
                    text: value.name,
                    value: value.score,
                });
            });
        }

        return (
            (this.props.who) ? (
                <div>
                    <Collapsable startsOpen={false} title="10 Most Mentioned Known People and People Groups">
                        <ol style={{ margin: 0, padding: 0, width: '100%' }}>
                            {
                                this.props.who.known.map((value, index) => {
                                    if (index < 11) {
                                        return SinglePerson({
                                            ...value,
                                            index,
                                            footnoteStart,
                                        });
                                    }
                                    return null;
                                })
                            }
                        </ol>
                        <SourceFigureAndFootnotes
                            {...this.props}
                            figureNumber={this.props.figureNumber + 1}
                            figureName="List of 10 most  people and people groups"
                        />
                    </Collapsable>

                    {
                        (this.props.who.known.length > 10)
                            ? (
                                <Collapsable startsOpen={false} title="Remaining Known People and People Groups">
                                    <ol style={{ margin: 0, padding: 0, width: '100%' }}>
                                        {
                                            this.props.who.known.map((value, index) => {
                                                if (index > 10) {
                                                    return SinglePerson({
                                                        ...value,
                                                        index,
                                                        footnoteStart,
                                                    });
                                                }
                                                return null;
                                            })
                                        }
                                    </ol>
                                    <SourceFigureAndFootnotes
                                        {...this.props}
                                        figureNumber={this.props.figureNumber + 2}
                                        figureName="List of all people and people groups"
                                    />
                                </Collapsable>
                            ) : null
                    }
                    {
                        (this.props.who.unknown.length > 0)
                            ? (
                                <Collapsable startsOpen={false} title="Inferred People or People Groups">
                                    { /* eslint-disable-next-line max-len */ }
                                    <p> Inferred people and people groups are words identified as potentially being people and whose names have a known meaning. </p>
                                    <ol style={{ margin: 0, padding: 0, width: '100%' }}>
                                        {
                                            this.props.who.unknown.map((value, index) => {
                                                const src = '/images/UNKNOWN.jpg';
                                                if (value.gender.indexOf('U') === 0) {
                                                    return (
                                                        <PersonCard
                                                            size="55"
                                                            value=""
                                                            gender="UNKNOWN"
                                                            index={index}
                                                            src={src}
                                                            key={index}
                                                            detailsheader={value.name}
                                                            details={value.meaning}
                                                            link={value.ref}
                                                            text={value.description}
                                                            footnoteRef={footnoteStart}
                                                        />
                                                    );
                                                }
                                                return (null);
                                            })
                                        }
                                    </ol>
                                    <SourceFigureAndFootnotes
                                        {...this.props}
                                        figureNumber={this.props.figureNumber + 3}
                                        figureName="List of inferred people or people groups"
                                    />
                                </Collapsable>
                            ) : null
                    }
                </div>
            ) : null
        );
    }
}

class PersonListBasic extends React.Component {
    static contextType = ThemeContext;

    render() {
        const { footnoteStart } = this.props;
        const words = [];

        console.log('PersonListBasic: ',this.props.who)

        if (this.props.who) {
            this.props.who.known.forEach((value) => {
                words.push({
                    text: value.name,
                    value: value.score,
                });
            });
        }

        return (
            (this.props.who) ? (
                <div>
                    {
                        (this.props.who.known.length > 0)
                            ? (
                                <>
                                    <p> All known people mentioned in the passage. </p>
                                    <ol style={{ margin: 0, padding: 0, width: '100%' }}>
                                        {
                                            this.props.who.known.map((value, index) => {
                                                if (index > -1) {
                                                    return SinglePerson({
                                                        ...value,
                                                        index,
                                                        footnoteStart,
                                                    });
                                                }
                                                return null;
                                            })
                                        }
                                    </ol>
                                </>
                            ) : null
                    }
                    {
                        (this.props.who.unknown.length > 0)
                            ? (
                                <>
                                    <p> Inferred people and people groups are words identified as potentially being people and whose names have a known meaning. </p>
                                    <ol style={{ margin: 0, padding: 0, width: '100%' }}>
                                        {
                                            this.props.who.unknown.map((value, index) => {
                                                const src = '/images/UNKNOWN.jpg';
                                                if (value.gender.indexOf('U') === 0) {
                                                    return (
                                                        <PersonCard
                                                            size="55"
                                                            value=""
                                                            gender="UNKNOWN"
                                                            index={index}
                                                            src={src}
                                                            key={index}
                                                            detailsheader={value.name}
                                                            details={value.meaning}
                                                            link={value.ref}
                                                            text={value.description}
                                                            footnoteRef={footnoteStart}
                                                        />
                                                    );
                                                }
                                                return (null);
                                            })
                                        }
                                    </ol>
                                </>
                            ) : null
                    }
                    <div style={{ display: 'block' }}>
                        {(this.props.figureNumber) ? (
                            <SourceFigure
                                sources={this.props.sources}
                                figureNumber={this.props.figureNumber + 1}
                                figureName="Word cloud of commonly repeated words"
                            />
                        ) : null}
                        {(this.props.footnotes) ? (
                            <>
                                <SmallGutter />
                                <Collapsable startsOpen={false} info lightweight title="Footnotes, Sources & Credits">
                                    <SourceFootnotes
                                        footnotes={this.props.footnotes}
                                        footnoteStart={this.props.footnoteStart}
                                    />
                                </Collapsable>
                            </>
                        ) : null}
                    </div>

                </div>
            ) : null
        );
    }
}

class PersonListSimple extends React.Component {
    static contextType = ThemeContext;

    render() {
        let footnoteRef = null;

        //console.log('PersonListSimple', this.props.who)

        if (this.props.footnoteRef) footnoteRef = this.props.footnoteRef;

        // console.log("SimplePersonList: ", this.props.who)
        return (
            (this.props.who) ? (
                <div className='hello'>
                    
                        {
                            ((this.props.who.length > 0)&&(this.props.title)) ? (
                                <div className='hello2'>
                                    <SubheaderText id="example-collapse-text">
                                        {' '}
                                        {this.props.title}
                                    </SubheaderText>
                                </div>
                            ) : null
                        }

                    <ol style={{ margin: 0, padding: 0 }}>
                        {
                            this.props.who.map((value, index) => {
                                let src = '/images/MALE.jpg';
                                if (!value.gender) value.gender = 'U';
                                if (value.gender.indexOf('F') === 0) src = '/images/FEMALE.jpg';
                                if (value.gender.indexOf('P') === 0) src = '/images/PEOPLEGROUP.jpg';
                                if (value.gender.indexOf('U') !== 0) {
                                    return (
                                        <PersonCard
                                            size="30"
                                            value=""
                                            gender={value.gender}
                                            index={index}
                                            src={src}
                                            key={index}
                                            detailsheader={value.name}
                                            details={value.meaning}
                                            link={value.ref}
                                            text={value.description}
                                            footnoteRef={footnoteRef}
                                        />
                                    );
                                }
                                return (<div />);
                            })
                        }
                    </ol>

                    {(this.props.sources) ? <SourceCredits sources={this.props.sources} /> : null}
                </div>
            ) : null
        );
    }
}

class PersonSingle extends React.Component {
    static contextType = ThemeContext;

    render() {
        const value = this.props.who;

        let footnoteRef = null;

        if (this.props.footnoteRef) footnoteRef = this.props.footnoteRef;

        // console.log("person",value);
        let src = '/images/MALE.jpg';
        if (value.gender) {
            if (value.gender.indexOf('F') === 0) src = '/images/FEMALE.jpg';
            if (value.gender.indexOf('P') === 0) src = '/images/PEOPLEGROUP.jpg';
        }
        // console.log("Single Person: ", this.props.who)
        return (
            ((this.props.who) && (value.gender)) ? (
                <div>
                    <div>
                        <SubheaderText id="example-collapse-text">
                            {' '}
                            {this.props.title}
                        </SubheaderText>
                    </div>

                    <ol style={{ margin: 0, padding: 0 }}>
                        {
                            (value.gender.indexOf('U') !== 0)
                                ? (
                                    <PersonCard
                                        size="55"
                                        value=""
                                        src={src}
                                        detailsheader={value.name}
                                        details={value.meaning}
                                        link={value.ref}
                                        text={value.description}
                                        footnoteRef={footnoteRef}
                                    />
                                ) : null
                        }
                    </ol>

                    {(this.props.sources) ? <SourceCredits sources={this.props.sources} /> : null}
                </div>
            ) : null
        );
    }
}

export {
    PersonList, PersonSingle, PersonListBasic, PersonListSimple,
};
