import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Collapse from '@material-ui/core/Collapse';
import { ThemeContext } from '../Core/ThemeContext';
import { SourceFootnotes, SourcePermissions, LargeGutter } from '../Core/TextComponents';
import { ConcordanceList } from '../Lists/ConcordanceList';
import '../../index.css';

const descriptionStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'wrap',
    width: 2000,
    paddingBottom: 0,
    marginBottom: 0,
    cursor: 'pointer',
};

function SizedCardMedia(props) {
    const isTabletOrMobileDevice = useMediaQuery('(max-width: 1224px)');
    const isSmallTabletOrMobileDevice = useMediaQuery('(max-width: 768px)');

    // var size = 92;
    // if (isSmallTabletOrMobileDevice)
    //     size = 112;
    // else if (isTabletOrMobileDevice)
    //     size = 102;

    let size = 88;
    if (isSmallTabletOrMobileDevice) size = 88;
    else if (isTabletOrMobileDevice) size = 88;

    return (
        <CardMedia
            image={props.src}
            title={props.value}
            style={{
                cursor: 'pointer',
                width: 'auto',
                height: { size },
                minWidth: { size },
                minHeight: { size },
            }}
            className=""
            component="img"
            alt="N/A"
            height={size}
            width={size}
        />
    );
}

class ConcordanceCard extends Component {
    // eslint-disable-next-line
    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = { open: false };
        this.handleSelect = this.handleSelect.bind(this);
        this.cardSize = props.size;
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    handleSelect(tab) {
        console.log(`ConcordanceCard: Selected: ${tab} ${this}`);
    }

    render() {
        // console.log('ConcordanceCard Sources', this.props.sources);
        // console.log('ConcordanceCard Sentence', this.props.sentence);
        return (
            <div>
                <Card
                    style={{
                        display: 'flex',
                        background: '#fff',
                        margin: 0,
                        borderRadius: 0,
                    }}
                    className="bookcard"
                    width={1}
                >
                    <SizedCardMedia
                        onClick={() => {
                            this.setState((prevState) => ({ open: !prevState.open }));
                        }}
                        src="/images/UNKNOWN.jpg"
                        value={this.props.value}
                    />
                    <CardContent
                        style={descriptionStyle}
                        onClick={() => {
                            this.setState((prevState) => ({ open: !prevState.open }));
                        }}
                    >
                        <h5 variant="h6" component="h3">
                            Concordance
                        </h5>
                        <p
                            style={{
                                color: '#667',
                            }}
                        >
                            Click to display dictionary entries for orignal language words
                        </p>
                    </CardContent>
                </Card>
                <Collapse in={this.state.open}>
                    <div className="m-3">
                        <ConcordanceList
                            dictionary={this.props.sentence}
                            showcount={false}
                            showstrongs
                            showgrammer={false}
                            sources={[...this.props.sources]}
                            figureNumber={1}
                            footnoteStart={1}
                        />
                        <SourceFootnotes footnotes={[...this.props.sources]} />
                        <LargeGutter />
                        <SourcePermissions sources={[...this.props.sources]} />
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default ConcordanceCard;
