/* eslint-disable max-classes-per-file */
import React from 'react';
import { ThemeContext } from '../Core/ThemeContext';
import LocationCard from '../Cards/LocationCard';
import {
    SourceCredits, SubheaderText, SourceFigure, SourceFigureAndFootnotes,
} from '../Core/TextComponents';
import Collapsable from '../Core/Collapsable';
import Map from '../Charts/Map';

class LocationList extends React.Component {
    static contextType = ThemeContext;

    render() {
        const { footnoteStart } = this.props;

        return (
            (this.props.where) ? (
                <div>
                    <Collapsable startsOpen={window.web_version} title="Map">
                        <Map locations={this.props.where.known} width="100%" height={600} />
                        <SourceFigure
                            {...this.props}
                            figureNumber={this.props.figureNumber}
                            figureName="Map of known entities covered in the text"
                        />
                    </Collapsable>

                    <Collapsable startsOpen={false} title="10 Most Repeated Places">
                        <ol style={{ margin: 0, padding: 0, width: '100%' }}>
                            {
                                this.props.where.known.map((value, index) => {
                                    if (index < 11) {
                                        return (
                                            <LocationCard
                                                key={index}
                                                place={value}
                                                link={value.ref}
                                                footnoteRef={footnoteStart}
                                            />
                                        );
                                    }
                                    return null;
                                })
                            }
                        </ol>
                        <SourceFigureAndFootnotes
                            {...this.props}
                            figureNumber={this.props.figureNumber + 1}
                            figureName="List of 10 most repeated known locations in the text"
                        />
                    </Collapsable>

                    <Collapsable startsOpen={false} title="All Places">
                        <ol style={{ margin: 0, padding: 0, width: '100%' }}>
                            {
                                this.props.where.known.map((value, index) => {
                                    if (index > -1) {
                                        return (
                                            <LocationCard
                                                key={index}
                                                place={value}
                                                link={value.ref}
                                                footnoteRef={footnoteStart}
                                            />
                                        );
                                    }
                                    return null;
                                })
                            }
                        </ol>
                        <SourceFigureAndFootnotes
                            {...this.props}
                            figureNumber={this.props.figureNumber + 2}
                            figureName="List of all known places in the text"
                        />
                    </Collapsable>
                </div>
            ) : null
        );
    }
}

class LocationListBasic extends React.Component {
    static contextType = ThemeContext;

    render() {
        const { footnoteStart } = this.props;

        // console.log('LocationListBasic: Place: ', this.props.where);

        return (
            (this.props.where) ? (
                <div>
                    <Collapsable startsOpen={window.web_version} lightweight title="All Places">
                        <ol style={{ margin: 0, padding: 0, width: '100%' }}>
                            {
                                this.props.where.known.map((value, index) => {
                                    if (index > -1) {
                                        return (
                                            <LocationCard
                                                key={index}
                                                place={value}
                                                link={value.ref}
                                                footnoteRef={footnoteStart}
                                            />
                                        );
                                    }
                                    return null;
                                })
                            }
                        </ol>
                        <SourceFigureAndFootnotes
                            {...this.props}
                            figureNumber={this.props.figureNumber + 1}
                            figureName="List of all known places in the text"
                        />
                    </Collapsable>
                    <Collapsable lightweight startsOpen={false} title="Map">
                        <Map locations={this.props.where.known} width="100%" height={600} />
                        <SourceFigure
                            {...this.props}
                            figureNumber={this.props.figureNumber + 2}
                            figureName="Map of known entities covered in the text"
                        />
                    </Collapsable>

                </div>
            ) : null
        );
    }
}

class LocationSingle extends React.Component {
    static contextType = ThemeContext;

    render() {
        // console.log('LocationSingle: Render: ', this.props.where);
        return (
            (this.props.where) ? (
                <div>
                    <SubheaderText>
                        {this.props.title}
                    </SubheaderText>
                    <ol style={{ margin: 0, padding: 0 }} />
                    <ol style={{ margin: 0, padding: 0 }}>
                        <LocationCard
                            place={this.props.where}
                            link={this.props.where.ref}
                            footnoteRef={this.props.footnoteRef}
                        />
                    </ol>
                    {(this.props.sources) ? <SourceCredits sources={this.props.sources} /> : null}
                </div>
            ) : null
        );
    }
}

export { LocationSingle, LocationList, LocationListBasic };
