import React, { Component } from 'react';
import BibleLineWithInfo from './BibleLineWithInfo';
import bookConverters from '../utilities/bookConversions';
import osisConverters from '../utilities/osisConversions';
import { ThemeContext } from './Core/ThemeContext';
import { SourcePermissions } from './Core/TextComponents';



class BibleReader extends Component {
    static contextType = ThemeContext;

    static processAPIData(response, chapter, verseStart = -1, verseEnd=-1) {
        console.log('Process: ', response.verses.length, chapter, verseStart, verseEnd)
        const text = [];
        console.log(response);
        let data = response.verses;
        const sources = response.sourceinfo;
        const subgroup = response.book.Subgroup;

        let finalVerse = -1;
    
        // used to be only if verse_ref.length == 3;
        if ((verseStart > -1)&&(verseEnd == -1))
        {
            data = [response.verses[verseStart - 1]];
            if ((!data) || (data.length === 0) || !data[0])
                this.handleRefError(chapter);
        }
        if ((verseStart > -1)&&(verseEnd > -1))
        {
            data = []
            let currentVerse = verseStart - 1;
            const tmpVerseEnd = (verseEnd == 0)?9999:verseEnd;

            while((currentVerse < tmpVerseEnd) && (currentVerse < response.verses.length))
            {
                data.push(response.verses[currentVerse]);
                currentVerse++;
                finalVerse = currentVerse;
            }

            console.log('data', data, verseStart, currentVerse, verseEnd);

            if ((!data) || (data.length === 0) || !data[0])
                this.handleRefError(chapter);
        }
    
        let title = '';
        let book = '';
    

        if (data.length > 0)
        {
            for (let i = 0; i < data.length; i += 1) {
                const verseinfo = data[i];
    
                text.push({
                    row: (i + 1),
                    text: verseinfo.verse,
                    data: verseinfo,
                });
    
                if (title === '') { title = `${data[i].what.name} ${data[i].what.chapter}`; }
                if (book === '') { book = `${data[i].what.osisRef.split('.')[0]}`; }

                if ((verseStart > -1)||(finalVerse > -1))
                {
                    title = `${data[i].what.name} ${data[i].what.chapter}:${verseStart}`;
        
                    if (finalVerse > -1)
                        title = `${title}-${finalVerse}`
                }
            }
        }
        else
        {
            book = response.book.BookCode;
            title = response.book.Name;
        }
    
        return {
            loading: false,
            text,
            title,
            sources,
            book,
            colorIndex: bookConverters.convertSubGroupToIndex(subgroup),
        };
    }

    render() {
        const aggregatedSources = this.props.sources;
        return (
            <>
                <ol
                    style={{
                        margin: 0,
                        padding: 0,
                    }}
                >
                    {this
                        .props
                        .text
                        .map((value, index) => (
                            <BibleLineWithInfo
                                key={index}
                                title={osisConverters.osisRefToVerseIndex(value.data.what.osisRef)}
                                date=""
                                contents={value}
                                sources={aggregatedSources}
                                colorIndex={this.props.colorIndex}
                                style={{ }}
                            />
                        ))}
                </ol>
                <div className="m-2">
                    <SourcePermissions
                        sources={(aggregatedSources.verse) ? aggregatedSources.verse : []}
                        ref = { this.sourcePermissionsRef }
                    />
                </div>
            </>
        )
    }
}

export default BibleReader;