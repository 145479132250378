import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { ThemeContext } from '../Core/ThemeContext';
import { SourceFigure, BodyLinkInline, SubheaderText } from '../Core/TextComponents';
import VerseOutline from '../Outlines/VerseOutline';
import books from '../../data/books';
import ConditionallyRender from 'react-conditionally-render';

class CrossReferenceOutline extends React.Component {
    static contextType = ThemeContext;

    render() {
        const xrefs = [];
        let xrefsTmp = [];
        let xrefsRef = [];
        const xrefsgrouped = {};
        // console.log('====props====',this.props)

        if (this.props.why) {
            if (Object.prototype.hasOwnProperty.call(this.props.why, 'cross_references')) {
                xrefsTmp = this.props.why.cross_references;

                if (Object.prototype.hasOwnProperty.call(xrefsTmp, 'refs')) xrefsRef = xrefsTmp.refs;
                else xrefsRef = xrefsTmp;

                // if just top10 are required then sort here
                if ((this.props.top10) && (xrefsRef.length > 10)) {
                    xrefsRef.sort((a, b) => {
                        if (a.votes > b.votes) return -1;
                        if (b.votes > a.votes) return 1;
                        return 0;
                    });

                    xrefsRef = xrefsRef.slice(0, 10);
                }

                // In here we want to group cross references by book
                // lets first work out the book and pit that in the table
                for (let i = 0; i < xrefsRef.length; i += 1) {
                    const book = xrefsRef[i].ref.split('.')[0];
                    const ref = {
                        ref: xrefsRef[i].ref,
                        votes: xrefsRef[i].votes,
                        book,
                        display: xrefsRef[i].ref.replace(`${book}.`, '').replace(`${book}.`, ''),
                    };
                    xrefs[i] = ref;

                    if (book) {
                        if (Object.prototype.hasOwnProperty.call(xrefsgrouped, book)) {
                            xrefsgrouped[book].push(ref);
                        } else {
                            xrefsgrouped[book] = [ref];
                        }
                    }
                }
            }
        }

        console.log('xrefsgrouped', xrefsRef);

        const outlines = books.getOutlines(xrefsRef);

        return (
            (this.props.why) ? (
                <div>
                    <ConditionallyRender
                        condition={this.props.notitle}
                        show={null}
                        elseShow={
                            <SubheaderText id="example-collapse-text">
                                Cross References in Context
                            </SubheaderText>}
                     />
                    <VerseOutline
                        title={"Cross References in Context"}
                        data={outlines}
                    />
                    <ConditionallyRender
                        condition={this.props.figureNumber}
                        show={
                            <SourceFigure
                                sources={this.props.sources}
                                figureNumber={this.props.figureNumber}
                                // eslint-disable-next-line max-len
                                figureName="An outline of cross references between the selected passage and other passages"
                            />
                        }
                        elseShow={null}
                     />
                </div>
            ) : null
        );
    }
}

CrossReferenceOutline.propTypes = {
    why: PropTypes.shape({
        cross_references: PropTypes.arrayOf(
            PropTypes.shape({
                ref: PropTypes.string.isRequired,
                votes: PropTypes.number.isRequired,
                osisRef: PropTypes.string
            })
        )
     }),
    notitle: PropTypes.bool
}

export default CrossReferenceOutline;
