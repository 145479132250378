import './App.css';
import './bootstrap-4/css/bootstrap.min.css';
import './video-react.css';
import React, { Component } from 'react';
import { Player, ControlBar } from 'video-react';
import { ThemeContext } from './components/Core/ThemeContext';
import './App.css';
import './bootstrap-4/css/bootstrap.min.css';
import './video-react.css';
import {Helmet} from "react-helmet";

/**
 * The Home class represents the home page component of the application.
 * It extends the React Component class and is responsible for rendering the home page content.
 * 
 * @class Home
 * @extends Component
 */
class Home extends Component {
    static contextType = ThemeContext;

    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
            loading: true,
        };

        this.videoPlayerRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ mounted: true });
        this.context.loading(true);
        window.show_footer = true;

        this.videoPlayerRef.current.subscribeToStateChange(this.handleStateChange);
        console.log(`PageHome: Mount: ${this.state.mounted}`);
    }

    componentWillUnmount() {
        this.setState({ mounted: false });
        console.log(`PageHome: Unmount: ${this.state.mounted}`);
    }

    handleStateChange = (state) => {
        if (state.readyState > 3) {
            if (this.state.loading) {
                this.videoPlayerRef.current.play();
                this.setState({ loading: false });
                this.context.loading(false);
            }

            if (state.currentTime > 7) {
                this.videoPlayerRef.current.pause();
            }

            console.log(state);
        }
    }

    render() {
        document.title = 'ai|Home';
        return (
            <div
                style={{
                    width: '100vw',
                    height: '100%',
                    backgroundColor: '#fafaca',
                    position: 'relative',
                }}
                className="aspectdiv"
            >
                <Helmet>
                    <link rel="canonical" href="http://www.ai-bible.com" />
                </Helmet>
                <div
                    style={{
                        width: '100vw',
                        height: '100%',
                    }}
                >
                    <Player
                        playsInline
                        src="./ChristLandscape.jpg"
                        poster="./ChristLandscape.jpg"
                        loop={false}
                        muted
                        ref={this.videoPlayerRef}
                    >
                        <ControlBar disableCompletely />
                    </Player>
                </div>
            </div>
        );
    }
}

export default Home;
