import React, { Component } from 'react';
import { ThemeContext } from '../Core/ThemeContext';
import { BodyLinkInline, BodyTextInline, BodyTextAreaInline } from '../Core/TextComponents';
import '../../index.css';

class TranslationCard extends Component {
    static contextType = ThemeContext;

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    static isOdd(num) {
        return num % 2;
    }

    constructor(props) {
        super(props);
        this.handleSelect = this
            .handleSelect
            .bind(this);
        this.cardSize = props.size;
    }

    handleSelect(tab) {
        console.log('TranslationCard: Selected: ', tab, this);
    }

    render() {
        const { showgrammer } = this.props;
        const { showstrongs } = this.props;
        const { showtranslation } = this.props;
        const { showcount } = this.props;
        const spaceIfNoCount = (this.props.showcount) ? -1 : 0 + 6;
        const dictionary = (this.props.showgrammer) ? -1 : 0 + spaceIfNoCount;
        const original = 2;
        const translation = 2;

        if (this.props.data.original) {
            return (
                <tr>
                    {
                        (showcount)
                            ? (
                                <td md={1}>
                                    <BodyTextInline>
                                        <b style={{ paddingLeft: '5px' }}>{this.props.data.count}</b>
                                    </BodyTextInline>
                                </td>
                            ) : null
                    }
                    {
                        (showstrongs)
                            ? (
                                <td md={1}>
                                    <BodyTextInline>
                                        <b style={{ paddingLeft: '5px' }}>{this.props.data.strongsnumber}</b>
                                    </BodyTextInline>
                                </td>
                            ) : null
                    }
                    <td md={original}>
                        <BodyLinkInline>
                            <a
                                className="color-text-link"
                                href={`/search?query=${this.props.data.strongsnumber}`}
                            >
                                <b style={{ paddingLeft: '5px' }}>
                                    {this.props.data.original}
                                </b>
                            </a>
                        </BodyLinkInline>
                    </td>
                    {
                        (showgrammer)
                            ? (
                                <td md={1}>
                                    <BodyTextInline>
                                        <span
                                            style={{ paddingLeft: '5px' }}
                                        >
                                            {this.props.data.parsing.parsing}
                                        </span>
                                    </BodyTextInline>
                                </td>
                            ) : null
                    }
                    {
                        (showtranslation)
                            ? (
                                <td md={translation}>
                                    <BodyTextInline>
                                        <b style={{ paddingLeft: '5px' }}>{this.props.data.text}</b>
                                    </BodyTextInline>
                                </td>
                            ) : null
                    }
                    <td md={dictionary}>
                        <BodyTextAreaInline
                            style={{ paddingLeft: '7px', overflow: 'wrap' }}
                        >
                            {this.props.data.dictionarysummary}
                        </BodyTextAreaInline>
                    </td>
                </tr>
            );
        } return (null);
    }
}

export default TranslationCard;
