import React, { useContext, useEffect } from 'react';
import {
    Annotation,
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    useZoomPan,
} from 'react-simple-maps';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { ThemeContext } from '../Core/ThemeContext';
// import { Map as PigeonMap } from 'pigeon-maps'; import { osm } from
// 'pigeon-maps/providers'; import {     MapContainer, TileLayer, Marker, Popup,
// } from 'react-leaflet';

// const geoUrl = 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojso'
//         + 'n-maps/world-50m.json';

const geoUrl = 'https://cdn.jsdelivr.net/npm/world-atlas@2/land-50m.json';

// const markers = [     {         markerOffset: -15,         name: 'Buenos
// Aires',         coordinates: [-58.3816, -34.6037],     },     { markerOffset:
// -15, name: 'La Paz', coordinates: [-68.1193, -16.4897] },     { markerOffset:
// 25, name: 'Brasilia', coordinates: [-47.8825, -15.7942] },     {
// markerOffset: 25, name: 'Santiago', coordinates: [-70.6693, -33.4489] },
// { markerOffset: 25, name: 'Bogota', coordinates: [-74.0721, 4.711] },     {
// markerOffset: 25, name: 'Quito', coordinates: [-78.4678, -0.1807] },     {
// markerOffset: -15, name: 'Georgetown', coordinates: [-58.1551, 6.8013] },
// { markerOffset: -15, name: 'Asuncion', coordinates: [-57.5759, -25.2637] },
//   { markerOffset: 25, name: 'Paramaribo', coordinates: [-55.2038, 5.852] },
//   { markerOffset: 25, name: 'Montevideo', coordinates: [-56.1645, -34.9011]
// },     { markerOffset: -15, name: 'Caracas', coordinates: [-66.9036, 10.4806]
// },     { markerOffset: -15, name: 'Lima', coordinates: [-77.0428, -12.0464]
// }, ];

// const setLocations = [
//     {
//         name: 'Jerusalem',
//         ref: 'recL1WV82pXaRBQ59',
//         feature: 'City',
//         meaning: 'vision of peace',
//         description: 'Called also Salem, Ariel, Jebus, the “city of God,” the “holy city;” by the mode'
//             + 'rn Arabs el-Khuds, m...',
//         longitude: '35.234167',
//         latitude: '31.776667',
//         score: 9999,
//     },
//     {
//         name: 'Israel',
//         feature: 'Region',
//         longitude: '34.8516',
//         latitude: '31.0461',
//         score: 99999,
//     },
// ];

const setLocations = [];

// function MyPigeonMap() {     const [center, setCenter] = useState([31.7866,
// 35.2486]);     const [zoom, setZoom] = useState(13);     return (
// <div style={{ height: 600 }}>             <PigeonMap
// center={center}                 provider={osm}                 zoom={zoom}
//              onBoundsChanged={({ center, zoom }) => {
// setCenter(center);                     setZoom(zoom);                 }}
//        />         </div>     ); } function MyLeafletMap() {     const
// [center, setCenter] = useState([31.7866, 35.2486]);     const [zoom, setZoom]
// = useState(13);     return (         <div style={{ height: 600 }}>
//  <MapContainer center={center} zoom={zoom} scrollWheelZoom={false}>
//       <TileLayer                     attribution='&copy; <a
// href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//  />             </MapContainer>         </div>     ); } var colors = {
// Region:"2",     Island:"2",     Settlement:"4",     City:"4",
// River:"blue",     Oasis:"blue",     Wateropen:"blue",     Water:"blue",
// Mountain:"3",     Landmark:"5",     Church:"5",     Location:"7", }

function isRegional(feature) {
    return (feature === 'Region' || feature === 'Island');
}

function isSettlement(feature) {
    return (feature === 'City' || feature === 'Settlement' || feature === 'Church');
}

function isWater(feature) {
    return (feature === 'Water' || feature === 'Oasis' || feature === 'Wateropen' || feature === 'River');
}

function isLandmark(feature) {
    return (feature === 'Mountain' || feature === 'Landmark' || feature === 'Location');
}

function isFeature(feature) {
    return (!isSettlement(feature) && !isRegional(feature));
}

function SimpleMarker(props) {
    const theme = useContext(ThemeContext);
    const { marker, scale } = props;

    return (
        <Marker key={marker.name} coordinates={marker.coordinates}>
            {(isLandmark(marker.type))
                ? <circle r={3 / scale} fill={theme.color4} stroke="#fff" strokeWidth={2 / scale} />
                : null}
            {(isSettlement(marker.type))
                ? <circle r={5 / scale} fill={theme.color5} stroke="#fff" strokeWidth={2 / scale} />
                : null}
            {(isWater(marker.type))
                ? (
                    <>
                        <circle r={4 / scale} fill={theme.colorblue} stroke="#fff" strokeWidth={2 / scale} />
                    </>
                )
                : null}
            {(isRegional(marker.type))
                ? (
                    <>
                        <circle r={6 / scale} fill={theme.color8} stroke="#fff" strokeWidth={2 / scale} />
                        <text
                            textAnchor="middle"
                            y={marker.markerOffset / (scale * 1.75)}
                            style={{
                                fontFamily: 'Monteserrat, system-ui',
                                fill: '#5D5A6D',
                                fontSize: 14 / scale,
                            }}
                        >
                            {marker.name.toUpperCase()}
                        </text>
                    </>
                )
                : (
                    <text
                        textAnchor="middle"
                        y={marker.markerOffset / (scale * (0.5 * scale))}
                        style={{
                            fontFamily: 'Monteserrat, system-ui',
                            fill: '#5D5A6D',
                            fontSize: 12 / scale,
                        }}
                    >
                        {marker.name}
                    </text>
                )}

        </Marker>
    );
}

// eslint-disable-next-line no-unused-vars
function SimpleAnnotation(props) {
    const { marker, scale } = props;
    return (
        <Annotation subject={marker.coordinates} dx={0} dy={0}>
            <text
                textAnchor="middle"
                y={marker.markerOffset}
                style={{
                    fontFamily: 'system-ui',
                    fill: '#5D5A6D',
                    fontSize: 16 / scale,
                }}
            >
                {marker
                    .name
                    .toUpperCase()}
            </text>
        </Annotation>
    );
}

function SimpleFeature(props) {
    const { feature, scale } = props;
    return (
        <>
            {
                (isRegional(feature.type))
                    ? <SimpleMarker marker={feature} scale={scale} />
                    : <SimpleMarker marker={feature} scale={scale} />
            }
        </>
    );
}

const CustomZoomableGroup = ({ children, ...restProps }) => {
    const { mapRef, transformString, position } = useZoomPan(restProps);
    return (
        <g ref={mapRef}>
            <rect fill="transparent" />
            <g transform={transformString}>{children(position)}</g>
        </g>
    );
};

function MySimpleMap(props) {
    // eslint-disable-next-line no-unused-vars
    const theme = useContext(ThemeContext);
    const [markers, setMarkers] = React.useState([]);
    const { locations } = props;
    const [state, setState] = React.useState({
        checkedRegions: true,
        checkedSettlements: true,
        checkedFeatures: true,
    });
    const [maxResults, setMaxResults] = React.useState(12);

    const handleSelectChange = (event) => {
        setMaxResults(event.target.value);
    };

    useEffect(() => {
        const tmpMarkers = [];

        const addMarker = (location, tempMarkers) => {
            const mark = {
                markerOffset: -15,
                name: location.name,
                coordinates: [location.longitude, location.latitude],
                type: location.feature,
            };

            if (tempMarkers.length <= maxResults) {
                if (isRegional(location.feature)) {
                    if (state.checkedRegions) tempMarkers.push(mark);
                }

                if (isFeature(location.feature)) {
                    if (state.checkedFeatures) tempMarkers.push(mark);
                }

                if (isSettlement(location.feature)) {
                    if (state.checkedSettlements) tempMarkers.push(mark);
                }
            }
        };

        // Convert setLocations and props.Locations to markers
        setLocations.forEach((location) => {
            addMarker(location, tmpMarkers);
        });

        locations.forEach((location) => {
            addMarker(location, tmpMarkers);
        });

        setMarkers(tmpMarkers);
    }, [state, maxResults, locations]);

    // useEffect(() => {
    //     console.log('position', position);
    // }, [position.zoom]);

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    // function handleMoveEnd(position) {
    //     setPosition(position);
    // }

    return (
        <div style={{ width: props.width }}>
            <ComposableMap
                style={{ backgroundColor: '#EFF5FF', marginBottom: 10, maxHeight: props.height }}
                projection="geoAzimuthalEqualArea"
                projectionConfig={{
                    rotate: [-35 - (window.innerWidth * 0.000), -32 + (window.innerWidth * 0.000), 30],
                    scale: 2000,
                }}
            >
                <CustomZoomableGroup center={[0, 0]} maxZoom={15} minZoom={1}>
                    { (position, index) => (
                        <>
                            <Geographies geography={geoUrl} key={index}>
                                {({ geographies }) => geographies
                                    .filter((d) => d.properties.REGION_UN !== 'Americas')
                                    .map((geo) => (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            fill="#FEFFFC"
                                            stroke="#CCC"
                                            style={{
                                                default: {
                                                    outline: 'none',
                                                },
                                                hover: {
                                                    outline: 'none',
                                                },
                                                pressed: {
                                                    outline: 'none',
                                                },
                                            }}
                                        />
                                    ))}
                            </Geographies>
                            {
                                markers.map((marker, markerIndex) => (
                                    <SimpleFeature
                                        feature={marker}
                                        scale={position.k}
                                        key={markerIndex}
                                    />
                                ))
                            }
                        </>
                    )}
                </CustomZoomableGroup>
            </ComposableMap>
            <FormGroup row style={{ margin: 2 }}>
                <FormControl variant="filled" style={{ marginRight: 15 }}>
                    <InputLabel id="demo-simple-select-outlined-label">Results</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={maxResults}
                        onChange={handleSelectChange}
                        label="Results"
                    >
                        <MenuItem value={12}>Twelve</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                        <MenuItem value={99999}>All</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={state.checkedRegions}
                            onChange={handleChange}
                            name="checkedRegions"
                            color="primary"
                        />
                    )}
                    label="Regions"
                />
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={state.checkedSettlements}
                            onChange={handleChange}
                            name="checkedSettlements"
                            color="primary"
                        />
                    )}
                    label="Settlements"
                />
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={state.checkedFeatures}
                            onChange={handleChange}
                            name="checkedFeatures"
                            color="primary"
                        />
                    )}
                    label="Features"
                />
            </FormGroup>
        </div>
    );
}

export default MySimpleMap;
