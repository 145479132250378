import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import TitleLine from './components/Core/TitleLine';
import { ThemeContext } from './components/Core/ThemeContext';
import bookConverters from './utilities/bookConversions';
import ScrollArea from './components/Core/ScrollArea';
import Outline from './components/Outlines/Outline';
import books from './data/books'
var bcv_parser = require("bible-passage-reference-parser/js/en_bcv_parser").bcv_parser;
var bcv = new bcv_parser;
import { Capacitor } from '@capacitor/core';
const web_version = (Capacitor.getPlatform()==='web');

const querystring = require('querystring');

class Book extends Component {
    static contextType = ThemeContext;

    static xDown = null;

    static yDown = null;

    bodyScrollLockTargetRef = React.createRef();
    bodyScrollLockTargetElement = null;
    bodyScrollOkayTargetRef = React.createRef();
    bodyScrollOkayTargetElement = null;

    sourcePermissionsRef = React.createRef();

    static getTouches(evt) {
        return evt.touches // browser API
               || evt.originalEvent.touches; // jQuery
    }

    static handleTouchStart(evt) {
        console.log('ts');
        const firstTouch = Book.getTouches(evt)[0];
        Book.xDown = firstTouch.clientX;
        Book.yDown = firstTouch.clientY;
    }

    static handleTouchMove(evt) {
        if (!Book.xDown || !Book.yDown) {
            return;
        }

        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;

        const xDiff = Book.xDown - xUp;
        const yDiff = Book.yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) { /* most significant */
            if (xDiff > 50) {
                /* left swipe */
                this.incrementPage('');
            } else if (xDiff < -50) {
                /* right swipe */
                this.decrementPage('');
            }
        } else if (yDiff > 0) {
            /* up swipe */
        } else {
            /* down swipe */
        }
        /* reset values */
        Book.xDown = null;
        Book.yDown = null;
    }

    static incrementPage(book) {
        if (web_version)
            window.location.href =`http://${window.location.host}/read/${book}.1`;
        else
            window.location.href =`/reader?verse=${book}.1`;
    }

    static decrementPage(book) {
        if (web_version)
            window.location.href =`http://${window.location.host}/?noRef=${book}`;
        else
        {
            console.log('going to ' + `/reader?verse=${book}`)
            window.location.href = `/reader?verse=${book}`;
        }
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    constructor() {
        super();

        // window.scrollTo(0,0)

        window.addEventListener('keydown', (ev) => {
            if (ev.key === 'ArrowRight') Book.incrementPage(ev);
            if (ev.key === 'ArrowLeft') Book.decrementPage(ev);
        });

        window.addEventListener('touchstart', (evt) => { Book.handleTouchStart(evt); }, false);
        window.addEventListener('touchmove', (evt) => { Book.handleTouchMove(evt); }, false);

        this.state = {
            title: '',
            outline: [],
            text: [],
            loading: true,
            sources: {},
            colorIndex: 1,
            book: '',
        };
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        //enableBodyScroll(this.bodyScrollLockTargetElement);
        //disableBodyScroll(this.bodyScrollOkayTargetElement);
        document.body.style.overflow = "";
    }

    handleResize = () => {
        this.forceUpdate();
    };

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        console.log('REACT_APP_AWS_API', process.env.REACT_APP_AWS_API);

        window.show_footer = false;

        this.bodyScrollLockTargetElement = this.bodyScrollLockTargetRef.current;
        //disableBodyScroll(this.bodyScrollLockTargetElement);
        this.bodyScrollOkayTargetElement = this.bodyScrollOkayTargetRef.current;
        //enableBodyScroll(this.bodyScrollOkayTargetElement);

        console.log('bstyle', document.body.style.overflow)
        document.body.style.overflow = "hidden";

        window.addEventListener('resize', this.handleResize);
        console.log(`ComponentDidMount: ${new Date().getSeconds()} ${window.innerWidth} ${window.scrollY}`);
        const values = querystring.parse(window.location.search.substr(1));
        const { token } = localStorage;

        this.setState({
            'outline':[...books.booksData.data[values.ref].sections],
            'colorIndex': bookConverters.convertSubGroupToIndex(books.booksData.data[values.ref].Subgroup),
            'title': books.booksData.data[values.ref].Name,
            'loading': false,
            'book': values.ref
        });

        this.context.loading(false);
    }

    
    trimY = this.sourcePermissionsRef?.current?.getBoundingClientRect().y;

    render() {
        //this.trimY = this.sourcePermissionsRef?.current?.getBoundingClientRect().y;
        console.log('Trimy', this.trimY);
        const searchedVerse = querystring.parse(window.location.search.substr(1)).verse;
        document.title = searchedVerse || 'ai-BIBLE';
        const aggregatedSources = this.state.sources;
        console.log(`Sources: ${new Date().getSeconds()}`, aggregatedSources);
        return (
            <SwipeableViews ref={this.bodyScrollLockTargetRef}>
                    <div>
                        <div style={{
                            margin: 0,
                            maxWidth: window.innerWidth,
                        }}
                        >
                            <TitleLine
                                title={this.state.title}
                                book={this.state.book}
                                visible={!this.state.loading}
                                colorIndex={this.state.colorIndex}
                                // onclickBack={()=>Book.decrementPage(this.state.book)}
                                // onclickForward={()=>Book.incrementPage(this.state.book)}
                                hideBot={true}
                                hideArrows={!web_version}
                            />
                            {/*  */}
                            <ScrollArea trimY={0} ref={this.bodyScrollOkayTargetRef}>
                                <Outline outline={this.state.outline} colorIndex={this.state.colorIndex}></Outline>,
                            </ScrollArea>
                            
                        </div>
                    </div>
            </SwipeableViews>
        );
    }
}

export default Book;