import React from 'react';
import { ThemeContext } from '../Core/ThemeContext';
import {
    SourceFigure, SubheaderText,
} from '../Core/TextComponents';
import Collapsable from '../Core/Collapsable';
import WordCloud from './WordCloud';

class RepeatedWordCloud extends React.Component {
    // eslint-disable-next-line
    static contextType = ThemeContext;

    render() {
        let words = [];
        const { showcount } = this.props;

        if (this.props.verses && this.props.verses.sentences) {
            const uniqueWords = {};
            const ignoreWords = [
                'in',
                'through',
                'this',
                'the',
                'he',
                'to',
                'his',
                'from',
                'of',
                'said',
                'and',
                'but',
                'had',
                'so',
                'is',
                'it',
                'up',
                'on',
                'are',
                'i',
                'not',
                'your',
                'there',
                'them',
                'you',
                'get',
                'whether',
                'after',
                'that',
                'will',
                'a',
                'or',
                'they',
                'with',
                'for',
                'she',
                'then',
                'let',
                'us',
                'by',
                'her',
                'our',
                'throughout',
                'well'];

            this.props.verses.sentences.forEach((sentence) => {
                let { verse } = sentence;
                // eslint-disable-next-line no-control-regex
                verse = verse.replace(/[^\x00-\x7F]/g, ' '); // remove unicode characters
                // eslint-disable-next-line no-useless-escape
                verse = verse.replace(/[.,\/#!/?$%\^&\*;:{}=\-_`~()]/g, ''); // remove non alpha characters
                const verseWords = verse.split(' ');
                verseWords.forEach((word) => {
                    let trimmedWord = word.toLowerCase().trim().replace('-', '').replace("'", '')
                        .replace(',', '')
                        .replace('.', '');
                    trimmedWord = trimmedWord.replace(/[0-9]/g, '');

                    if (!ignoreWords.includes(trimmedWord)) {
                        if (!Object.prototype.hasOwnProperty.call(uniqueWords, trimmedWord)) {
                            uniqueWords[trimmedWord] = {
                                text: trimmedWord,
                                value: 1,
                            };
                        } else uniqueWords[trimmedWord].value += 1;
                    }
                });
            });

            words = Object.values(uniqueWords);
        } else if (this.props.sentence) {
            this.props.sentence.forEach((value) => {
                if ((value.count > 1) && (value.parsing.parsing !== 'N-proper-ms')) {
                    words.push({
                        text: value.text,
                        value: value.count,
                    });
                }
            });
        }

        const figureNumber = this.props.figureNumber ? this.props.figureNumber : 0;

        return (
            <div>
                {(showcount) ? null : (
                    <SubheaderText id="example-collapse-text">
                        Concordance
                    </SubheaderText>
                )}
                {(showcount)
                    ? (
                        <Collapsable startsOpen={window.web_version} title="WordCloud: Repeated Words">
                            <WordCloud style={{ width: '100%' }} words={words}>
                                The WordCloud
                            </WordCloud>
                            {(this.props.figureNumber) ? (
                                <SourceFigure
                                    sources={this.props.sources}
                                    figureNumber={figureNumber + 1}
                                    figureName="Word cloud of commonly repeated words"
                                />
                            ) : null}
                        </Collapsable>
                    ) : null}
            </div>
        );
    }
}

export default RepeatedWordCloud;
