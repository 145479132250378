import { BorderOuterRounded } from "@material-ui/icons";

const osisConverters = {
    osisRefToIndex: (osisRef) => {
        const parts = osisRef.split('.');
        return `${parts[1]}.${parts[2]}`;
    },
    osisRefToVerseIndex: (osisRef) => {
        const parts = osisRef.split('.');
        return `${parts[2]}`;
    },
    osisToBook: (osis) => {
        const parts = osis.split('.');
        return `${parts[0]}`;
    },
    osisRangeContainsStartOfRange: (osisRangeOuter, osisRangeInner) => {
        const outer = osisConverters.deconstructOsisRange(osisRangeOuter);
        const inner = osisConverters.deconstructOsisRange(osisRangeInner);

        // console.log('ranges', outer,inner)

        // Assume we are working with the same book
        // onsole.log('chk book')
        if (outer.osisBookStart != inner.osisBookStart)
            return false;

            // console.log('chk cs')
        if (outer.osisChapterStart > inner.osisChapterStart)
            return false;

            // console.log('chk ce', outer.osisChapterEnd < inner.osisChapterStart, outer.osisChapterEnd, inner.osisChapterStart)
        if (outer.osisChapterEnd < inner.osisChapterStart)
            return false;

            // console.log('chk vs')
        if ((outer.osisVerseStart > inner.osisVerseStart) && (outer.osisChapterStart === inner.osisChapterStart))
            return false;

            // console.log('chk ve')
        if ((outer.osisVerseEnd < inner.osisVerseStart) && (outer.osisChapterEnd === inner.osisChapterStart))
            return false;

            // console.log('all good')
        return true;
    },
    deconstructOsisRange: (osisRange) => {
        let [osisRefStart, osisRefEnd] = osisRange.split('-');
        let [osisBookStart, osisChapterStart, osisVerseStart] = osisRefStart.split('.');
        if (!osisRefEnd) osisRefEnd = osisRefStart;
        let [osisBookEnd, osisChapterEnd, osisVerseEnd] = osisRefEnd.split('.');

        if (osisBookStart == 'James') osisBookStart = 'Jas';
        if (osisBookEnd == 'James') osisBookEnd = 'Jas';
    
        return {
            osisRefStart,
            osisRefEnd,
            osisBookStart,
            osisChapterStart: parseInt(osisChapterStart),
            osisVerseStart: parseInt(osisVerseStart),
            osisBookEnd,
            osisChapterEnd: parseInt(osisChapterEnd),
            osisVerseEnd: parseInt(osisVerseEnd),
        };
    },
    formatOsisRange: (osisRange) => {
        let [osisRefStart, osisRefEnd] = osisRange.split('-');
        if (!osisRefEnd)
            return osisRange;
        const [osisBookStart, osisChapterStart] = osisRefStart.split('.');
        console.log('osisRefEnd', osisRefEnd, osisRefStart)
        if (osisRefEnd.includes('.'))
            return osisRange;
        else
            return osisRefStart + "-" + osisBookStart + "." + osisChapterStart + "." + osisRefEnd;
    }
};

export default osisConverters;
