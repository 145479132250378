import ReactGA from 'react-ga';
import Sentiment from 'sentiment';
var sentiment = new Sentiment();
import * as Swetrix from 'swetrix'

const session_id = Math.floor(Math.random() * 1000000000);

/**
 * Set of handlers that are invoked from the message parser
 */
class ActionProvider {
    constructor(
        createChatBotMessage,
        setStateFunc,
        createClientMessage,
        stateRef,
        createCustomMessage,
        // eslint-disable-next-line no-unused-vars
        ...rest
    ) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
        this.createClientMessage = createClientMessage;
        this.stateRef = stateRef;
        this.createCustomMessage = createCustomMessage;
    }

    /**
     * Text for chat response and widget to launch
     * @param {string} msg 
     * @param {string} widget 
     */
    respondInChat = (msg, widget) => {
        const message = this.createChatBotMessage(
            msg,
            {
                widget,
                timestamp: (widget == 'wait')? new Date().getTime() + 100:new Date().getTime() // delay this message a sec so that it doesn't come before printing the user question that happens slowly
            },
        );

        this.addMessageToState(message);
    }

    /**
     * Text for chat response and widget to launch
     * @param {string} msg 
     * @param {string} widget 
     */
    respondInChat2 = (msg, widget) => {
        const message = this.createCustomMessage(
            msg,
            {
                widget,
                timestamp: new Date().getTime()
            },
        );

        this.addMessageToState(message);
    }

    chooseAtRandom = (entries) => {
        // eslint-disable-next-line no-bitwise
        return entries[(entries.length * Math.random()) | 0];
    }

    /**
     * Handle a question given to the ai bot
     * @param {string} question 
     * @param {{}} data 
     */
    handleQuestion = (question, data) => {
        console.log('Handling message', question, data);

        const thinkingAboutItResponses = [
            "two secs, just thinking about this one...",
            "one moment, this requires some thought...",
            "let me think about it...",
            "one sec...",
            "I'll get there, just one moment while I ponder..."
        ]

        this.respondInChat(this.chooseAtRandom(thinkingAboutItResponses), "wait");

        async function fetchData(chatResponder, stateSetter) {
            console.log('Sending over Question: ', question);

            const { token } = localStorage;
        
            let endpoint = '/v1/chat/text';

            console.log('chat endpoint path: ' + endpoint)
            console.log('env', process.env)
        
            if ((process.env.REACT_APP_AWS_API)||(Capacitor.getPlatform()!=='web')) {
                endpoint = `${window.API_BASE}${endpoint}`;
            }
            else {
                endpoint = `http://localhost:5001${endpoint}`;
            }

            console.log('contacting chat endpoint: ' + endpoint)
        
            if (endpoint !== '') {
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        text: question,
                        maxResults: 5,
                    }),
                })
                    .then((res) => res.json())
                    .then((response) => {
                        console.log('Got Chat Response', response);

                        const label = response?.answer?.data?.processed[0]?.intents?.intent?.intent + ":::" + response?.answer?.data?.processed[0]?.intents?.entityExtraction + ":::" + response.answer.widget;

                        const event = {
                            category: session_id,
                            action: response?.text,
                            label,
                            value: sentiment.analyze("" + response?.text).score
                        }
                        console.log('Logging: ', event)
                        // ReactGA.event(event);
                        Swetrix.track("Chat__" + response?.answer?.data?.processed[0]?.intents?.intent?.intent?.trim())

                        if ((response.answer)&&(response.answer.responseText)) {
                            stateSetter((prevState) => ({
                                ...prevState,
                                answer: response.answer,
                                question: question,
                            }));

                            chatResponder(response.answer.responseText, 'response');
                        }
                        else
                            chatResponder('Something went wrong', 'response');
                    }).catch((e)=>{
                        console.log('excetion: ', e);
                        chatResponder('Could not connect to ai, please check internet connection', 'response');
                    });
            }
        }

        fetchData(this.respondInChat, this.setState);
    };

    addMessageToState = (message) => {
       

        const prevState = this.stateRef;

        let prevMessages = [...prevState.messages];
        console.log('message', message, prevMessages);
        prevMessages = prevMessages.map(x => {return {...x, timestamp: x.timestamp||new Date().getTime()}})

        // this.stateRef = {...prevState, messages: [prevMessages, message]}

        this.setState((previousState)=>({
            ...previousState,
            messages: [message, ...previousState.messages.map(x => {return {...x, timestamp: x.timestamp||new Date().getTime()}}).sort((a,b)=>{return b.timestamp - a.timestamp}).filter((x) => x.widget != 'wait')]
        }))

        console.log('added message', this.stateRef.messages);
        // this.setState((prevState) => ({
        //     ...prevState,
        //     messages: [
        //         ...prevState.messages,
        //         // ...prevState.messages.map((x => 
        //         //     { 
        //         //         return {
        //         //             ...x, 
        //         //             timestamp: x.timestamp||new Date().getTime()
        //         //         }
        //         //     })),
        //             // .filter(x => ((x.widget != 'waitxxx'))), 
        //         message
        //     ].reverse(),
        // }));
    };
}

export default ActionProvider;
