/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { ThemeContext } from '../Core/ThemeContext';
import { SourceFootnotes } from '../Core/TextComponents';
import TimingList from '../Lists/TimingList';
import { PastPresentFutureTenseChart } from '../Charts/VerbAnalysisCharts';
import Collapsable from '../Core/Collapsable';

class When extends React.Component {
    render() {
        const {
            footnotes, sources, when, sentences, footnoteStart, footnoteNums,
        } = this.props;

        // eslint-disable-next-line no-console
        console.log('WHEN', when, this.props, sources);

        return (
            <div>
                {(when) ? (
                    <div>
                        <Collapsable startsOpen={window.web_version} title="Timing Information">
                            <TimingList
                                footnotes={footnotes}
                                footnoteStart={footnoteStart}
                                footnoteNums={footnoteNums}
                                when={when}
                            />
                            {(footnotes) ? (
                                <SourceFootnotes
                                    footnotes={footnotes}
                                    footnoteStart={footnoteStart}
                                />
                            ) : null}
                        </Collapsable>
                        <Collapsable startsOpen={window.web_version} title="Past, Present, Future Verb Tense">
                            <PastPresentFutureTenseChart
                                sentences={sentences}
                                sources={sources.details}
                                figureNumber={1}
                            />
                        </Collapsable>
                    </div>
                ) : null}
            </div>
        );
    }
}
When.contextType = ThemeContext;

export {
    When,
};
