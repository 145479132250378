import React from 'react';
import CrossReferenceOutline from '../Lists/CrossReferenceOutline';

/**
 * Convert a list of verses into a list of cross references
 * @param {string[]} verses
 * @returns
 */
function convertToCrossReferences(verses, defaultVotes = 1) {
    return verses.map((verse) => ({
        ref: verse,
        osisRef: verse,
        votes: defaultVotes,
    }));
}

const BibleVerseList = (props) => {
    // console.log('BibleVerseList', props.data.verses.refs);

    const why = {
        cross_references: convertToCrossReferences(props.data.verses.refs),
    };

    return (
        <CrossReferenceOutline notitle why={why} />
    );
};

export default BibleVerseList;
