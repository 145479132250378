import React, { Component } from 'react';
import {
    Row, Col, Collapse, Tabs, Tab, Breadcrumb,
} from 'react-bootstrap-legacy';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapsable from './Core/Collapsable';
import TimingList from './Lists/TimingList';
import { Concordance } from './Lists/ConcordanceList';
import CrossReferenceOutline from './Lists/CrossReferenceOutline';
import { LocationListBasic } from './Lists/LocationList';
import { PersonListBasic as PersonList } from './Lists/PersonList';
import { PrescriptiveDescriptiveGuages, PastPresentFutureTestGuage } from './Charts/VerbAnalysisCharts';
import { SentimentGuage } from './Charts/SentimentCharts';
import { ThemeContext } from './Core/ThemeContext';
import {
    BodyTextInline,
    Container,
    IndexText,
    SourcePermissions,
    SourceFootnotes,
    LargeGutter,
    SmallGutter,
    SubheaderText,
} from './Core/TextComponents';
import '../index.css';
import '../tabs.css';

class BibleLineWithInfo extends Component {
    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = { open: false, key: 'xrefs', opened: false };
        this.handleSelect = this.handleSelect.bind(this);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    handleSelect(tab) {
        console.log('Line: Selected: ', tab, this);
        if (tab !== '') this.setState({ key: tab });
    }

    render() {
        const theme = this.context;
        return (
            <Container style={{ backgroundColor: theme.background, margin: 0, maxWidth: window.innerWidth - 15 }}>
                <Row>
                    <Col
                        className="pt-2 pb-1 mb-0 mr-0"
                        style={{
                            borderRight: '1px dotted #DE979677', maxWidth: 55, minWidth: 48, cursor: 'pointer', paddingLeft: 3, paddingRight: 2,
                        }}
                        xs={1}
                        // eslint-disable-next-line max-len
                        onClick={() => { this.setState((prevState) => ({ open: !prevState.open, opened: true })); }}
                    >
                        <IndexText className="m-0 p-0">
                            {`${this.props.title}.`}
                        </IndexText>
                    </Col>
                    <Col
                        className="pt-2 mt-1 pb-1 mb-1"
                        style={{ display: 'float', paddingLeft: 5 }}
                    >
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                        <div
                            className="hello"
                            style={{
                                width: 'auto', height: 'auto', margin: 'auto', paddingLeft: 5, cursor: 'pointer',
                            }}
                            onClick={() => {
                                this.setState((prevState) => ({ open: !prevState.open, opened: true }));
                            }}
                        >
                            <BodyTextInline>
                                {this.props.contents.text}
                            </BodyTextInline>
                        </div>

                        <Collapse
                            in={this.state.open}
                            style={{ cursor: 'default' }}
                        >
                            <div>
                                {(this.state.open)
                                    ? (
                                        <Tabs
                                            className={`customTabs customTabs-color-${this.props.colorIndex}`}
                                            style={{
                                                paddingTop: 10,
                                            }}
                                            activeKey={this.state.key}
                                            onSelect={this.handleSelect}
                                            id="uncontrolled-tab-example"
                                        >
                                            <Tab className="customTab1 p-3" eventKey="xrefs" title="XREF">
                                                    <CrossReferenceOutline
                                                        why={this.props.contents.data.why}
                                                        sources={this.props.sources.why}
                                                        figureNumber={3}
                                                    />
                                                    <SmallGutter />
                                                    <Collapsable
                                                        title="Footnotes, Sources and Credits"
                                                        lightweight
                                                        info
                                                    >
                                                    <SourcePermissions sources={this.props.sources.why} />
                                                </Collapsable>
                                            </Tab>
                                            <Tab className="customTab2 p-3" eventKey="sentence" title="WHAT">
                                                <Concordance
                                                    sentence={this.props.contents.data.sentence.details}
                                                    showstrongs
                                                    showgrammer
                                                    sources={this.props.sources.details}
                                                    figureNumber={1}
                                                    footnoteStart={1}
                                                    showAllEntries={true}
                                                />
                                                <SmallGutter />
                                                <Collapsable
                                                    title="Footnotes, Sources and Credits"
                                                    lightweight
                                                    info
                                                >
                                                    <SourceFootnotes
                                                    // eslint-disable-next-line max-len
                                                        footnotes={[...this.props.sources.details, ...this.props.sources.what]}
                                                    />
                                                    <LargeGutter />
                                                    <SourcePermissions
                                                    // eslint-disable-next-line max-len
                                                        sources={[...this.props.sources.details, ...this.props.sources.what]}
                                                    />
                                                </Collapsable>
                                            </Tab>
                                            <Tab
                                                className="customTab3 p-3"
                                                eventKey="who"
                                                title="WHO"
                                                // eslint-disable-next-line max-len
                                                disabled={(this.props.contents.data.who.known.length === 0) && (this.props.contents.data.who.unknown.length === 0)}
                                            >
                                                <PersonList
                                                    who={this.props.contents.data.who}
                                                    sources={this.props.sources.who}
                                                    footnotes={this.props.sources.who}
                                                    figureNumber={1}
                                                    footnoteStart={1}
                                                />
                                            </Tab>
                                            <Tab
                                                className="customTab4 p-3"
                                                eventKey="where"
                                                title="WHERE"
                                                disabled={(this.props.contents.data.where.known.length === 0)}
                                            >
                                                <LocationListBasic
                                                    where={this.props.contents.data.where}
                                                    sources={this.props.sources.where}
                                                    footnotes={this.props.sources.where}
                                                    figureNumber={1}
                                                    footnoteStart={1}
                                                />
                                            </Tab>
                                            <Tab className="customTab5 p-3" eventKey="when" title="WHEN">
                                                <PastPresentFutureTestGuage
                                                    sentence={this.props.contents.data.sentence}
                                                    sources={null}
                                                />
                                                <TimingList
                                                    when={this.props.contents.data.when}
                                                    sentence={this.props.contents.data.sentence}
                                                    sources={null}
                                                    footnoteNums={[1, 7]}
                                                />
                                                <SmallGutter />
                                                <Collapsable
                                                    title="Footnotes, Sources and Credits"
                                                    lightweight
                                                    info
                                                >
                                                    <LargeGutter />
                                                    <SourceFootnotes
                                                        // eslint-disable-next-line max-len
                                                        footnotes={[...this.props.sources.when, ...this.props.sources.details]}
                                                    />
                                                    <LargeGutter />
                                                    <SourcePermissions
                                                        // eslint-disable-next-line max-len
                                                        sources={[...this.props.sources.when, ...this.props.sources.details]}
                                                    />
                                                </Collapsable>
                                            </Tab>
                                            <Tab className="customTab6 p-3" eventKey="why" title="WHY">
                                                <SubheaderText id="example-collapse-text">
                                                    Literary Context
                                                </SubheaderText>
                                                <Breadcrumb>
                                                    <Breadcrumb.Item href="#">
                                                        {this.props.contents.data.what.testament}
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item href="#">
                                                        {this.props.contents.data.what.grouping}
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item href="#">
                                                        {this.props.contents.data.sentence.heading}
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item active>
                                                        {this.props.contents.data.what.osisRef}
                                                    </Breadcrumb.Item>
                                                </Breadcrumb>
                                                <PrescriptiveDescriptiveGuages
                                                    sentence={this.props.contents.data.sentence}
                                                    sources={null}
                                                />
                                                <SentimentGuage
                                                    sentence={this.props.contents.data.sentence}
                                                    sources={null}
                                                />
                                                <SmallGutter />
                                                <Collapsable
                                                    title="Footnotes, Sources and Credits"
                                                    lightweight
                                                    info
                                                >
                                                    <SourcePermissions sources={this.props.sources.why} />
                                                </Collapsable>
                                            </Tab>
                                        </Tabs>
                                    )
                                    : null}
                            </div>
                        </Collapse>
                    </Col>
                    <Col
                        className="pt-2 pb-1 mb-0 pr-1 pl-1"
                        style={{
                            borderRight: '1px dotted #DE979677', maxWidth: 50, minWidth: 50, cursor: 'pointer',
                        }}
                        xs={1}
                        // eslint-disable-next-line max-len
                        onClick={() => { this.setState((prevState) => ({ open: !prevState.open, opened: true })); }}
                    >
                        {(this.state.open)
                            ? <ExpandLessIcon style={{ opacity: 0.4 }} />
                            : <ExpandMoreIcon style={{ opacity: 0.4 }} />}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default BibleLineWithInfo;
