import OutlineBoxStories from '../components/Outlines/OutlineBox.stories';
import booksData from './books.json';
import osisConverters from '../utilities/osisConversions';
import { ExpandLessRounded, FreeBreakfastOutlined } from '@material-ui/icons';


const books = {
    shallowCopy: false,
    deepCopy: true,
    booksData,
    findSection: (osisRange, bookCollection = null, deepCopy = false) => { // Returns a shallow copy 
        if (!bookCollection)
        {
            const {
                osisBookStart,
                osisChapterStart,
                osisChapterEnd,
            } = osisConverters.deconstructOsisRange(osisRange);

            bookCollection = booksData.data[osisBookStart];
        }

        // console.log('findingSection: ', osisRange, bookCollection)

        let returnSection = null;

        bookCollection.sections.some((section)=>{
            if (osisConverters.osisRangeContainsStartOfRange(section.osisRange, osisRange))
            {
                // console.log('foundSection: ', section, JSON.parse(JSON.stringify(section)), deepCopy)

                if (deepCopy != false)
                {
                    returnSection = JSON.parse(JSON.stringify(section));
                    return true;
                }
                else
                {
                    returnSection =  section;
                    return true;
                }
            }
        });

        // console.log('Returning section: ', returnSection)
        return returnSection;
    },
    findSubsection: (osisRange, section = null, deepCopy = false) => {
        let currentSection = section || null;

        // console.log('findingSubsectionX: ', osisRange, section)

        if (!section)
        {
            const {
                osisBookStart,
                osisChapterStart,
                osisChapterEnd,
            } = osisConverters.deconstructOsisRange(osisRange);

            booksData.data[osisBookStart].sections.forEach((sect)=>{
                if (osisConverters.osisRangeContainsStartOfRange(sect.osisRange, osisRange))
                {
                    currentSection = sect;
                }
            });
        }

        // console.log('findingSubsection: ', osisRange, currentSection, currentSection.subsections)

        let returnSubsection = null;

        if (!currentSection.subsections)
        {
            return returnSubsection;
        }

        currentSection.subsections.some((subsection)=>{
            // console.log('chk: ', subsection.osisRange, osisRange)
            if (osisConverters.osisRangeContainsStartOfRange(subsection.osisRange, osisRange))
            {
                // console.log('foundSubsection: ', subsection)

                if (deepCopy != false)
                {
                    returnSubsection =  JSON.parse(JSON.stringify(subsection));
                    return true;
                }
                else
                {
                    returnSubsection = subsection;
                    return true;
                }
            }
        })

        // console.log('Returning Subsection: ', returnSubsection)

        return returnSubsection;
    },
    getOutlines: (osisRanges) => {
        const outlines = {}

        // Reconstruct osisRanges as an outlines object with outlines summary info
        // Assume only one book per range
        // sort osisRange to guarantee it is correctly ordered, can add a flag to skipSort for
        // performance reasons

        // TODO: for another day need to deal with the issues around including refs from outlines where there are no subsections

        osisRanges.forEach(element => {
            // console.log('element', element);
            if (element.ref) element.osisRange = element.ref;
            let osisRange = element.osisRange || element;
            
            const {
                osisBookStart,
                osisChapterStart,
                osisChapterEnd,
            } = osisConverters.deconstructOsisRange(osisRange);

            console.log('Book', osisBookStart, osisRange)

            if (!booksData.data[osisBookStart])
            {
                console.log('Missing book: ' + osisBookStart + "-" + osisRange);
            }

            let addedSection = false;
            let addedSubsection = false;
            const bookInfo = JSON.parse(JSON.stringify(booksData.data[osisBookStart]));

            // console.log("b1");

            // 1. Does the book exist in the collection already?
            if (outlines[osisBookStart])
            {
                // 1.1 Does the section exist in the collection already?
                // console.log("b1.1");

                const foundOutlineSection = books.findSection(osisRange, outlines[osisBookStart], books.shallowCopy)

                if (foundOutlineSection)
                {
                    // console.log("b1.1.1 Section Exists")

                    // 1.1.1 Does the subsection exist already in the outline?
                    const foundOutlineSubsection = books.findSubsection(osisRange, foundOutlineSection, books.shallowCopy)

                    if (foundOutlineSubsection)
                    {
                        // console.log("b1.1.1.1 Adding Subsection");
                        // 1.1.1.1 Add range to subsection
                        if (foundOutlineSubsection.contents)
                            foundOutlineSubsection.contents.push(element);
                        else
                            foundOutlineSubsection.contents = [element]
                    }
                    else
                    {
                        // console.log("b1.1.2 Subsection Did not Exist", foundOutlineSection.subsections);

                        const foundDataSubsectionShallowCopy = books.findSubsection(osisRange)

                        if (foundDataSubsectionShallowCopy)
                        {
                            // console.log("b1.1.2.1 Found Subsection", foundDataSubsectionShallowCopy.osisRange);
                            // 1.1.2.1 Add subsection with range in it
                            let foundDataSubsectionDeepCopy = JSON.parse(JSON.stringify(foundDataSubsectionShallowCopy));
                            foundDataSubsectionDeepCopy.contents = [element];
                            foundOutlineSection.subsections.push(foundDataSubsectionDeepCopy)
                        }
                        else
                        {
                            // console.error('1.1 no subsection found: ', osisRange, foundOutlineSection);
                            if (foundOutlineSection.contents)
                                foundOutlineSection.contents.push(element);
                            else
                                foundOutlineSection.contents = [element]
                        }
                    }
                }    
                else // 1.2 If section does not exist then add a new section
                {         
                    // console.log("b1.2 Section did not already exist");
                    // console.log("b1.2.1 Look for section within data");

                    const foundDataSectionDeepCopy = books.findSection(osisRange, booksData.data[osisBookStart], books.deepCopy);

                    if (foundDataSectionDeepCopy)
                    {
                        // console.log("b1.2.1 Found section in data", foundDataSectionDeepCopy.osisRange);

                        const foundDataSubsectionShallowCopy = books.findSubsection(osisRange, foundDataSectionDeepCopy, books.shallowCopy);

                        // console.log('ret: ',foundDataSubsectionShallowCopy);

                        if (foundDataSubsectionShallowCopy)
                        {
                            // console.log("b1.2.1.1");
                            // 1.1.2.1 Add subsection with range in it
                            foundDataSubsectionShallowCopy.contents = [element]
                        }
                        else
                        {
                            // console.error('1.2 no subsection found: ', osisRange, foundDataSectionDeepCopy);
                            foundDataSectionDeepCopy.contents = [element]
                        }

                        outlines[osisBookStart].sections.push(foundDataSectionDeepCopy)
                    }
                }
            }
            // 2. If the book does not exist in the collection
            else
            {
                // console.log("b2");
                // 2.1 Add Book
                const bookInfo =JSON.parse(JSON.stringify(booksData.data[osisBookStart]));
                bookInfo.sections = [];
                outlines[osisBookStart] = bookInfo;

                // console.log("b2.1.1 Added book new, finding section", osisBookStart, booksData.data[osisBookStart]);
                // 2.1.1 Add section to book

                const foundDataSectionDeepCopy = books.findSection(osisRange, booksData.data[osisBookStart], books.deepCopy)

                // console.log('b2.1.1 ret: ',foundDataSectionDeepCopy);

                //console.log('retx: ',foundDataSectionDeepCopy.subsections);

                if (foundDataSectionDeepCopy)
                {
                    const foundDataSubsectionDeepCopy = books.findSubsection(osisRange, foundDataSectionDeepCopy, books.deepCopy)
                    foundDataSectionDeepCopy.subsections = [];

                    // console.log('b2.1.2 ret: ',foundDataSubsectionDeepCopy);
                    
                    if (foundDataSubsectionDeepCopy)
                    {
                        // console.log("b2.1.2.1");
                        // 2.1.2.1 Add subsection with range in it
                        foundDataSectionDeepCopy.subsections = [foundDataSubsectionDeepCopy];
                        foundDataSectionDeepCopy.subsections[0].contents = [element];
                        
                    }
                    else
                    {
                        foundDataSectionDeepCopy.contents = [element];
                        // console.error('2.1.2.1 did not find subsection', osisRange, foundDataSectionDeepCopy, foundDataSubsectionDeepCopy);
                    }

                    bookInfo.sections.push(foundDataSectionDeepCopy);
                }
                else
                {
                    // console.error('2.1.1 did not find data section', osisRange, booksData.data[osisBookStart], foundDataSectionDeepCopy);
                }
            }
        })

        return outlines;
    }
}

export default books;