import React, { useState, useEffect } from 'react';
import BibleVerse from '../../Answers/BibleVerse';
import BibleVerseList from '../../Answers/BibleVerseList';
import { LocationListBasic } from '../../Lists/LocationList'
import { PersonListSimple } from '../../Lists/PersonList'
import { LargeGutter } from '../../Core/TextComponents';
import BibleReader from '../../BibleReader';

const ChatResponse = (props) => {
    const [ answer, setAnswer ] = useState(undefined);

    useEffect(() => {
        // Latch the received answer, otherwise all other responses will receive it
        if ((!answer)&&(props.answer))
        {
            setAnswer(props.answer)
        }
      }, [props.answer]); // Or [] if effect doesn't need props or state

    const selectAnswerWidget = () => {
        if (!answer) return;
        let widget = (answer.widget)?answer.widget:'text';
        // console.log('answering with widget', widget)

        switch (widget) {
            case 'debug':
                return (<h4>{JSON.stringify(answer)}</h4>)
            case 'wait':
                return <h5>{answer.timestamp}</h5>
            case 'text':
                return <h5>{answer.timestamp}</h5>
                // return (<p>{(answer.data)?answer.data.text:'no data'}</p>)
            case 'verse':
                return (<BibleVerse {...answer}></BibleVerse>)
            case 'verses':
                return (<BibleVerseList {...answer}></BibleVerseList>)
            case 'where':
                return (<LocationListBasic where={{known:answer.data.places.map(x=>x.where)}}></LocationListBasic>)
            case 'who':
                return (
                <>
                    <PersonListSimple who={answer.data.people.filter(x=>x.who).map(x=>x.who)}></PersonListSimple>
                </>)
                // return (<PersonListSimple who={{known:answer.data.people.map(x=>x.who),unknown:[]}}></PersonListSimple>)
            case 'biblereader':
                return (<BibleReader {...BibleReader.processAPIData(answer.data.verses)}></BibleReader>)
            default:
                break;
        }
    }

    return (
        <>
            {selectAnswerWidget()}
        </>
    )
}

export default ChatResponse;