import React from 'react';
import Chatbot from 'react-chatbot-kit';
import './ChatBotFullScreen.css';

import config from './configs/chatbotConfig';
import MessageParser from './chatbot/MessageParser';
import ActionProvider from './chatbot/ActionProvider';



function ChatBot(props) {

    return (
        <>
            <Chatbot
                pageContext={props.pageContext}
                config={props.config||config()}
                messageParser={MessageParser}
                actionProvider={props.actionProvider||ActionProvider}
                style={{
                    width: '100%',
                    overflowY: 'scroll'
                }}
            />
        </>

    );
}

export default ChatBot;
