import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import './index.css';
import './fonts.css';
import { hydrate, render } from "react-dom";


if (!window.reading_history)
    window.reading_history = [];
  
//import b2cauth from 'react-azure-adb2c';

// TODO: Add this back in when ready to doo Azure B2C again, and don't store the token in local storage,
//       leave it in the HTTP Cookie
//b2cauth.initialize({
//    instance: 'https://login.microsoftonline.com/tfp/',
//    tenant: 'theshapeofsound.onmicrosoft.com',
//    signInPolicy: 'B2C_1_signupsignin',
//    applicationId: 'c34c1fb4-648e-44f2-a091-cdd38a6c7fd7',
//    cacheLocation: 'sessionStorage',
//    scopes: ['https://theshapeofsound.onmicrosoft.com/api/user_impersonation'],
//    redirectUri: 'http://localhost:3000',
//    postLogoutRedirectUri: window.location.origin,
//  });
  
  //ReactDOM.render(
  //    <App />,
  //    document.getElementById('root')
  //);

// var clientID = "c34c1fb4-648e-44f2-a091-cdd38a6c7fd7";
// var b2cDomainHost = "theshapeofsound.b2clogin.com";
// var tenantIdGuid = "f5b3029f-07b3-4eec-a186-07dacc0a88c0";
// var policyName = "B2C_1_signupsignin";
// //var identityMetadata = "https://" + b2cDomainHost + "/" + tenantIdGuid + "/" + policyName + "/v2.0/.well-known/openid-configuration/";

// // import authentication from 'react-azure-adb2c';
// // authentication.initialize({
// //     instance: 'https://theshapeofsound.b2clogin.com/',
// //     tenant: 'theshapeofsound.b2clogin.com',
// //     signInPolicy: policyName,
// //     applicationId: clientID,
// //     cacheLocation: 'sessionStorage',
// //     // the scopes you want included in the access token
// //     scopes: ['https://theshapeofsound.onmicrosoft.com/api/user_impersonation'],
// //     // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
// //     redirectUri: 'http://localhost:3000',
// //     // optional, the URI to redirect to after logout
// //     postLogoutRedirectUri: 'http://myapp.com'
// // });

// import authentication from '@kdpw/msal-b2c-react';

// authentication.initialize({
//     // you can user your b2clogin.com domain here, setting is optional, will default to this
//     instance: 'https://theshapeofsound.b2clogin.com/', 
//     // your B2C tenant, you can also user tenants GUID here
//     tenant: tenantIdGuid,
//     // the policy to use to sign in, can also be a sign up or sign in policy
//     signInPolicy: policyName,
//     // the policy to use for password reset
//     resetPolicy: 'mypasswordresetpolicy',
//     // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
//     applicationId: clientID,
//     // where MSAL will store state - localStorage or sessionStorage
//     cacheLocation: 'sessionStorage',
//     // the scopes you want included in the access token
//     scopes: ['https://theshapeofsound.onmicrosoft.com/api/user_impersonation'],
//     // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
//     redirectUri: 'http://localhost:3000',
//     // optional, the URI to redirect to after logout
//     postLogoutRedirectUri: 'http://myapp.com',
//     // optional, default to true, set to false if you change instance
//     validateAuthority: false,
//     // optional, default to false, set to true if you only want to acquire token silently and avoid redirections to login page
//     silentLoginOnly: false
// });

//authentication.run(() => {
    //localStorage.token = authentication.getAccessToken();
    // FIXME: Add token to HTTP Cookie, not localstorage
    // localStorage.token = "There is no token";
    //ReactDOM.render(
      
    ReactDOM.render(<App />, document.getElementById('root'));

    //serviceWorker.unregister();

//});
