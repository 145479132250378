import React from 'react';
import { ThemeContext } from './Core/ThemeContext';
import {
    FootnoteReference, SourceFootnotes, BodyText,
} from './Core/TextComponents';
import Collapsable from './Core/Collapsable';

class BookFacts extends React.Component {
    static contextType = ThemeContext;

    render() {
        return (
            <div>
                {(this.props.book) ? (
                    <div>
                        <Collapsable startsOpen={window.web_version} title="Book Facts">
                            {(this.props.book.Author) ? (
                                <BodyText>
                                    {' '}
                                    <b>Author:</b>
                                    {' '}
                                    {this.props.book.Author}
                                    {' '}
                                    <FootnoteReference number={this.props.footnoteStart} />
                                </BodyText>
                            ) : null}
                            {(this.props.book.Written) ? (
                                <BodyText>
                                    {' '}
                                    <b>Written:</b>
                                    {' '}
                                    {this.props.book.Written}
                                    {' '}
                                    <FootnoteReference number={this.props.footnoteStart} />
                                </BodyText>
                            ) : null}
                            {(this.props.footnotes)
                                ? (
                                    <SourceFootnotes
                                        footnotes={this.props.footnotes}
                                        footnoteStart={this.props.footnoteStart}
                                    />
                                ) : null}
                        </Collapsable>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default BookFacts;
