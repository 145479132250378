import React, { Component } from 'react';
import './App.css';
import './bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from './components/Core/ThemeContext';
import Banner from './components/Core/Banner';
import NumberGrid from './components/Input/InputGrid';
import { SubheaderText, SmallGutter, HeaderText } from './components/Core/TextComponents';
import {DayCookie, StorageCookie} from './components/Storage/Cookies';

const queryParams = new URLSearchParams(window.location.search);
const caloriesParam = parseInt(queryParams.get('calories'));


class DailyCounter extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            contacts: [],
            search: 'John.12.1-John.12.11',
            previoussearch: 'John.12.1-John.12.11',
            total: parseInt( DayCookie.get('calories') || 0 ),
            unit: 'cal',
        };

        this.handleTextFieldKeyDown = this.handleTextFieldKeyDown.bind(this);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        if (caloriesParam)
            this.handleSelect(caloriesParam);

        this.context.loading(false);
    }

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    handleTextFieldKeyDown = (event) => {
        const location = `${window.origin}/exegete?verse=${this.state.search}`;

        switch (event.key) {
        case 'Enter':
            this.setState({ contacts: [] });
            console.log('Navigate', this.state.search);
            window.location = location;
            break;
        case 'Escape':
            // etc...
            break;
        default:
            break;
        }
    }

    handleChange = (event) => {
        this.setState({ search: event.target.value });
    };

    handleSelect = (number) => {
        const newValue = this.state.total + parseInt(number) || 0;
        console.log('Set calories: ' + this.state.total + number)
        DayCookie.set('calories', newValue, '/counter');
        this.setState({ total: parseInt(newValue) });
    };

    handleClear = (param) => {
        console.log(param)
        if (param == 'clear')
        {
            const newValue = 0;
            console.log('Set calories: ' + 0)
            DayCookie.set('calories', newValue, '/counter');
            this.setState({ total: parseInt(newValue) });
        }
        else if (param == 'kJ')
        {
            if (this.state.unit == 'cal')
            {
                const cal = this.state.total;
                const kj = cal * 4.184;
                this.setState({unit: 'kJ', total: kj});
            }
        }
        else if (param == 'cal')
        {
            if (this.state.unit == 'kJ')
            {
                const kj = this.state.total;
                const cal = kj / 4.184;
                this.setState({unit: 'cal', total: cal});
            }
        } 
    };

    render() {
        document.title = 'ai|Counter';
        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}
            >
                <Banner text="For experimental purposes only, does use cookies" />
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText>Daily Counter </HeaderText>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>Add to the number</SubheaderText>
                        <SmallGutter></SmallGutter>
                        <h1 style={{border:'solid 1px lightgrey', padding:'10px'}}><strong>{parseInt(this.state.total) + " " + this.state.unit}</strong></h1>
                    </div>
                    <NumberGrid onSelect={this.handleSelect} color={1}/>
                    <NumberGrid onSelect={this.handleClear} color={6} columns={3} numbers={['clear','kJ','cal']} />
                </div>
            </div>
        );
    }
}

export default DailyCounter;
