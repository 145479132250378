/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const withHelpDrawer = (WrappedComponent) => (props) => {
    const [open, setOpen] = useState(false);

    const onClick = () => {
        setOpen(!open);
        console.log(`Toggled: ${open}`);
    };

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: 0,
                        height: 0,
                        borderTop: '65px solid #f9f9f9',
                        borderLeft: '95px solid transparent',
                        zIndex: 1,
                    }}
                    onClick={() => { onClick(); }}
                />

                <HelpOutlineOutlinedIcon
                    icon={faQuestionCircle}
                    style={{
                        position: 'absolute',
                        right: 12,
                        top: 10,
                        opacity: '55%',
                        zIndex: 2,
                    }}
                    onClick={() => { onClick(); }}
                />
            </div>
            <Collapse in={open}>
                <div className="mt-0 mb-3 mr-3 ml-3">
                    <div className="pt-3" />
                    <WrappedComponent {...props} />
                </div>
            </Collapse>
        </div>
    );
};

export default withHelpDrawer;
