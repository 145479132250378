import React from 'react';
import './OutlineBox.css';
import { Box } from '@material-ui/core';
import PlanOutlineButtons from './OutlineButtons';

const PlanOutlineBox = (props) => {
    const { children, title, subsections } = props;
    const nested = subsections || [];
    const colorIndex = props.colorIndex || 0;
    const guessWidth = title.length * 8.5;
    // eslint-disable-next-line max-len
    const colorText = getComputedStyle(document.documentElement).getPropertyValue(`--color-raw-${props.colorIndex}`);

    return (
        <Box
            component="div"
            style={{ minWidth: guessWidth }}
            className={`color-${colorIndex} floating-label-container`}
        >

            {(props.stronger) && (!props.bold)
                ? (
                    <span
                        className="floating-label-up"
                        style={{ color: 'black', whiteSpace: 'nowrap' }}
                    >
                        {title}
                    </span>
                )
                : (
                    <span
                        className="floating-label-up"
                        style={{ color: '#00000099', whiteSpace: 'nowrap' }}
                    >
                        {title}
                    </span>
                )}
            {(props.bold)
                ? (
                    <span
                        className="floating-label-up"
                        style={{ color: 'black' }}
                    >
                        <strong>{title}</strong>
                    </span>
                ) : null}
            {nested.map((subsection, index) => (
                <PlanOutlineBox
                    title={subsection.title}
                    key={index}
                    colorIndex={colorIndex}
                >
                    <PlanOutlineButtons
                        colorText={colorText}
                        osisRange={subsection.osisRange}
                        colorIndex={colorIndex}
                    />
                </PlanOutlineBox>
            ))}
            {children}
        </Box>
    );
};

const PlanContainerBox = (props) => {
    const { children, subsections } = props;
    const nested = subsections || [];
    const colorIndex = props.colorIndex || 0;
    // eslint-disable-next-line max-len
    const colorText = getComputedStyle(document.documentElement).getPropertyValue(`--color-raw-${props.colorIndex}`);

    return (
        <div>
            {nested.map((subsection, index) => (
                <PlanOutlineBox
                    title={subsection.title}
                    key={index}
                    colorIndex={colorIndex}
                >
                    <PlanOutlineButtons
                        colorText={colorText}
                        osisRange={subsection.osisRange}
                        colorIndex={colorIndex}
                    />
                </PlanOutlineBox>
            ))}
            {children}
        </div>
    );
};

export {
    PlanOutlineBox,
    PlanContainerBox,
};
