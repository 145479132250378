import React from 'react';
import Guitar, {getRenderFingerSpn} from 'react-guitar';
import { standard } from 'react-guitar-tunings';
import coco from 'react-guitar-theme-coco'
import dark from 'react-guitar-theme-dark'
import chords from './chords.json'
import notes from './notes.json'

import { Theme } from 'react-guitar'

const custom = {
  description:
    "Jimi Hendrix's Fender Stratocaster with a black fretboard and silver details",
  color: '#112277',
  nut: { color: '#222831' },
  fret: {
    color: '#444',
    separator: { color: '#aaa', shadow: true, width: 'sm' },
    marker: (fret) =>
      [3, 5, 7, 9, 12, 15, 17, 19, 21].includes(fret) ? (
        <span
          style={{
            width: '1em',
            height: '1em',
            borderRadius: '100%',
            backgroundColor: '#eeeeee',
          }}
        />
      ) : null,
    counter: { color: '#f3f3f3' },
    width: 'sm'
  },
  string: { color: () => '#aaa5', width: 'sm' },
  finger: { color: 'white', text: { color: '#606c76' } },
}



const GuitarChord = (props) => {
  const myChord = props.chord; // || [0, 1, 0, 2, 3, 'x'];
  console.log({myChord})
  const fingering = chords[myChord][0];

  return (
    <div style={{fontSize:'0.5em', minWidth:542}}>
        <Guitar renderFinger={getRenderFingerSpn(standard)} theme={custom} strings={fingering} tuning={standard} style={{width:'200 !important',maxWidth:'100'}} />
    </div>
  );
};

const GuitarNotes = (props) => {
    const note = props.note; // || [0, 1, 0, 2, 3, 'x'];
    console.log({note})
    const fingering = notes[note].reverse();
  
    return (
      <div style={{fontSize:'0.3em', minWidth:542}}>
          <Guitar renderFinger={getRenderFingerSpn(standard)} theme={custom} strings={fingering} tuning={standard} style={{width:'200 !important',maxWidth:'100'}} />
      </div>
    );
  };

export {
    GuitarChord,
    GuitarNotes
}
