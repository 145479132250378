import React from 'react';
import { Table } from 'react-bootstrap';
import { ThemeContext } from '../Core/ThemeContext';
import {
    BodyText, DisabledText, SourceFigure,
} from '../Core/TextComponents';
import Collapsable from '../Core/Collapsable';

class HeadingsList extends React.Component {
    static contextType = ThemeContext;

    render() {
        return (
            (this.props.literarycontext)
                ? (
                    <div>
                        <Collapsable startsOpen={false} title="Show Related Headings">
                            <Table variant="" size="sm" responsive="sm" striped="true">
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: '33%', width: '33%' }}>
                                            Summary
                                        </th>
                                        <th>Verse</th>
                                        <th>Text</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.literarycontext.before.map((value, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <DisabledText>
                                                        {value.heading}
                                                    </DisabledText>
                                                </td>
                                                <td><DisabledText>{value.verse}</DisabledText></td>
                                                <td><DisabledText>{value.text}</DisabledText></td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        this.props.literarycontext.within.map((value, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <BodyText highlight="true">
                                                        <b>
                                                            {value.heading}
                                                        </b>
                                                    </BodyText>
                                                </td>
                                                <td><BodyText>{value.verse}</BodyText></td>
                                                <td><BodyText>{value.text}</BodyText></td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        this.props.literarycontext.after.map((value, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <DisabledText>
                                                        {value.heading}
                                                    </DisabledText>
                                                </td>
                                                <td><DisabledText>{value.verse}</DisabledText></td>
                                                <td><DisabledText>{value.text}</DisabledText></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            {(this.props.figureNumber) ? (
                                <SourceFigure
                                    sources={this.props.sources}
                                    figureNumber={this.props.figureNumber}
                                    figureName="List of headings"
                                />
                            ) : null}
                        </Collapsable>
                    </div>
                ) : null
        );
    }
}

export default HeadingsList;
