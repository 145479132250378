import React from 'react';
import { PlanOutlineBox, PlanContainerBox } from './PlanOutlineBox';
import PlanOutlineButtons from './PlanOutlineButtons';
import './PlanOutline.css';

const PlanOutline = (props) => {
    const outline = props.outline || [];
    const title = props.title || 'Outline';
    const colorIndex = props.colorIndex || 0;
    const BookOutlineBox = PlanOutlineBox;
    const SectionOutlineBox = PlanOutlineBox;
    const showBookName = props.showBookName || false;

    // eslint-disable-next-line max-len
    const colorText = getComputedStyle(document.documentElement).getPropertyValue(`--color-raw-${colorIndex}`);

    //console.log('Plan Outline: outline:',outline);

    return (
        <div style={{ display: 'inline-block' }}>
            <BookOutlineBox title={title} colorIndex={colorIndex} bold >
                <div>
                    {outline.map((section, index) => {
                        // console.log('OutlineSection',section);
                        const adjMargin = (index === 0) ? 0 : 40;
                        return (
                            <SectionOutlineBox
                                style={{ marginBottom: adjMargin, display: 'inline-block' }}
                                title={section.title}
                                key={index}
                                colorIndex={colorIndex}
                                subsections={section.subsections}
                                stronger
                                >
                                <PlanOutlineButtons
                                    index={index + 1}
                                    style={{ display: 'inline-block' }}
                                    colorText={colorText}
                                    osisRange={(section.subsections) ? null : section.osisRange}
                                    colorIndex={colorIndex}
                                    showBookName={showBookName}
                                    devotional={section.devotional}
                                    applicationQuestions={section.applicationQuestions}
                                    comprehensionQuestions={section.comprehensionQuestions}
                                    centeringPrayer={section.centeringPrayer}
                                    commentary={section.commentary}
                                    application={section.application}
                                    meditation={section.meditation}
                                    applicationPrayer={section.applicationPrayer}
                                    crossReferences={section.crossReferences}
                                />
                            </SectionOutlineBox>
                        );
                    })}
                </div>
            </BookOutlineBox>
        </div>
    );
};

export default PlanOutline;
