import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import './App.css';
import './bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from './components/Core/ThemeContext';
import Search from '@material-ui/icons/SearchOutlined';
import Button from '@material-ui/core/Button';
var bcv_parser = require("bible-passage-reference-parser/js/en_bcv_parser").bcv_parser;
var bcv = new bcv_parser;

const searchStyle = {
    background: '#ffffc9',
    padding: '30px',
    margin: '0px',
};

class ExegesisSearch extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            contacts: [],
            search: 'John.12.1-John.12.11',
            previoussearch: 'John.12.1-John.12.11',
            valid: true,
            hint: '',
        };

        this.handleTextFieldKeyDown = this.handleTextFieldKeyDown.bind(this);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.context.loading(false);
    }

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    handleTextFieldKeyDown = (event) => {
        const search = bcv.parse(this.state.search).osis();
        const location = `${window.origin}/exegete?verse=${search}`;

        switch (event.key) {
        case 'Enter':
            this.setState({ contacts: [] });
            console.log('Navigate', this.state.search);
            window.location = location;
            break;
        case 'Escape':
            // etc...
            break;
        default:
            break;
        }
    }

     isValidVerse = (verse) => {
        try {
          const parsed = bcv.parse(verse).osis();
          this.setState({hint: parsed});
          console.log('parsed: ', parsed);
          if (parsed.trim().length === 0)
            return false;
          return true;
        } catch (error) {
          return false;
        }
      };

    handleChange = (event) => {
        const valid_verse =  this.isValidVerse(event.target.value);
        this.setState({ search: event.target.value, valid: valid_verse });
    };

    render() {
        document.title = 'Exegesis|Search';
        console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
            }}
            >
                <div style={searchStyle}>
                    <h5
                        style={{
                            color: '#555',
                            paddingBottom: '5px',
                        }}
                    >
                        { /* eslint-disable-next-line max-len */ }
                        Enter a verse reference to get detailed analysis on that passage e.g. John.12.1-John.12.11
                    </h5>
                    <div style={{display:'flex'}}>
                        <TextField
                            id="outlined-search"
                            defaultValue={this.state.previoussearch}
                            onChange={this.handleChange}
                            fullWidth
                            type="search"
                            variant="outlined"
                            onKeyDown={this.handleTextFieldKeyDown}
                            style={{flexBasis:'100%'}}
                        />
                        <Button className="" aria-label="search" onClick={()=>this.handleTextFieldKeyDown({key:'Enter'})} disabled={!this.state.valid} style={{ marginTop: 0,flexBasis: 57, height: 57 }}>
                            <Search style={{ height: 57 }} />
                        </Button>
                    </div>
                    <div style={{
                        color: this.state.valid?'black':'red',
                        fontSize: '1.8vh'
                    }}>
                        {this.state.hint}
                    </div>
                </div>
            </div>
        );
    }
}

export default ExegesisSearch;
