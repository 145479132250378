import React from 'react';
//import Loader from 'react-loader-spinner';
//import { Default } from 'react-spinners-css';
import Loader from "cssonly-loaders-react";

// Modal Loader, requires parent object to have style position:relative
const ModalLoader = (props) => {
    const color = getComputedStyle(document.documentElement).getPropertyValue('--color-raw-7');

    return (
        <div className="color-nav" style={{background:'linear-gradient(165deg, #ececf1 -200%, #fdfdff 100%)', minHeight:'200vh', transition:'0.5s ease', position:'absolute', pointerEvents:'none', top:0, left:0, width:"100%", height:"200%", zIndex:4000, opacity:(props.loading?1:0)}}>
            <div style={{height:"40vh", transition:'0.5s ease'}}>
            </div>
            <div style={{
                //top: '0',
                //left: '0',
                transform: 'translate(48vw,44%)',
            }}>
                <Loader
                    type="block-spin"
                    color={'rgba('+color+', 0.9)'}
                    height={'4%'}
                    width={'4%'}
                    line={5}
                    size={60}
                    style={{
                        position: 'relative',
                        margin:'auto',
                        verticalAlign:'middle',
                        textAlign: 'center',
                    }}
                />
            </div>
            
        </div>
    );
}

// <Loader 
// color={color}
// style={{
//     top:0,
//     left:0,
//     margin:'auto', 
//     textAlign:'center', 
//     transform: 'translateX(50%)', 
//     verticalAlign:'middle'}}>
// </Loader>

// <Default 
// color={color}
// style={{
//     position:'relative',
//     top:0,
//     left:0,
//     margin:'auto', 
//     textAlign:'center', 
//     transform: 'translateX(50%)', 
//     verticalAlign:'middle'}}>
// </Default>

            // <Loader
            //     type="BallTriangle"
            //     color={color}
            //     height={'5%'}
            //     width={'5%'}
            //     style={{
            //         position: 'relative',
            //         top: '300',
            //         transform: 'translateY(50%)',
            //         margin:'auto',
            //         verticalAlign:'middle',
            //         textAlign: 'center',
            //     }}
            // />

export default ModalLoader;

