import React, { Component } from 'react';
import { ThemeContext } from './components/Core/ThemeContext';
import XMLParser from 'react-xml-parser';


class Sitemap extends Component {
    static contextType = ThemeContext;

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    constructor() {
        console.log('constructor');
        super();

        this.state = {
            pages: []
        };
    }

    componentDidMount() {
        console.log('componentDidMount');
        this.context.loading(false);

        fetch('../sitemap.xml').then((res)=>{
            return res.text()
        }).then((res)=>{
            //console.log('res',res);
            var xml = new XMLParser().parseFromString(res);
            const pages = xml.getElementsByTagName('loc').map(x=>x.value);
            console.log('xml',pages);
            this.setState({pages});
        })
    }

    componentWillUnmount() {
        console.log('Will Unmount');
    }

    render() {
        // return(<li key={index}>{index}:{value}</li>);
        document.title = 'ai|Sitemap';
        return (
            <ol style={{ padding: 25 }}>
                {
                    this.state.pages.map((page)=>{
                        return (<li><a rel="canonical" href={page}>{page}</a></li>)
                    })
                }
 
            </ol>
        );
    }
}

export default Sitemap;
