import React, { Component } from 'react';
import ReactWordCloud from 'react-wordcloud';
import { ThemeContext } from '../Core/ThemeContext';

class WordCloud extends Component {
    static contextType = ThemeContext;

    static randomColor() {
        return 'color-1';
    }

    constructor(props) {
        super(props);
        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler() {
        this.forceUpdate();
    }

    // function getWindowDimensions() {
    //     const { innerWidth: width, innerHeight: height } = window;
    //     return {
    //         width,
    //         height,
    //     };
    // }

    // function useWindowDimensions() {
    //     const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    //     useEffect(() => {
    //         function handleResize() {
    //             setWindowDimensions(getWindowDimensions());
    //         }

    //         window.addEventListener('resize', handleResize);
    //         return () => window.removeEventListener('resize', handleResize);
    //     }, []);

    //     return windowDimensions;
    // }

    render() {
        // const size = [800, 600];
        const theme = this.context;
        const options = {
            rotations: 0,
            rotationAngles: [0, 0],
            colors: [theme.color3, theme.color4, theme.color5, theme.color6, theme.color7, theme.color8],
            fontFamily: 'Montserrat',
            fontSizes: [8, 80],
            fontWeight: 'bold',
            scale: 'sqrt',
            spiral: 'rectangular',
            transitionDuration: 1000,
            padding: 2,
        };
        const MAXWORDS = 50;
        const { words } = this.props;
        const data = [];

        words.forEach((theWord, index) => {
            const word = theWord;
            if (index < MAXWORDS) {
                word.text = word.text.replace('"', '');
                word.text = word.text.replace('“', '');
                word.text = word.text.replace('”', '');
                word.text = word.text.replace('.', '').replace('.', '').replace('.', '').replace('.', '');
                word.text = word.text.replace('[', '');
                word.text = word.text.replace(']', '');
                word.text = word.text.replace(':', '');
                word.text = word.text.replace('(', '');
                word.text = word.text.replace(')', '');
                word.text = word.text.replace('!', '');
                word.text = word.text.replace(',', '');
                word.text = word.text.replace('-', '').replace('-', '');
                word.text = word.text.replace('—', '');
                word.text = word.text.replace('?', '');
                word.text = word.text.replace('\'', '');
                word.text = word.text.replace('`', '');
                word.text = word.text.replace('‘', '');
                word.text = word.text.replace('’', '');
                word.text = word.text.replace('vvv', '');
                word.text = word.text.replace('^', '').replace('^', '').replace('^', '');

                data.push(word);
            }
        });

        return (
            <ReactWordCloud
                style={{
                    backgroundColor: theme.background,
                }}
                words={data}
                options={options}
                onClick={this.onClickHandler}
            />
        );
    }
}

export default WordCloud;
