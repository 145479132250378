/* eslint-disable max-classes-per-file */
import React from 'react';
import { Chart } from 'react-google-charts';
import { Row, Col } from 'react-bootstrap';

import {
    RadialChart,
} from 'react-vis';

import {
    SourceCredits, SourceFigure, BodyText, SubheaderText, SmallGutter,
} from '../Core/TextComponents';
import { ThemeContext } from '../Core/ThemeContext';

class PastPresentFutureTenseChart extends React.Component {
    static contextType = ThemeContext;

    render() {
        const valsPast = [];
        const valsPresent = [];
        const valsFuture = [];
        const valsTotal = [];
        if (this.props.sentences) {
            this.props.sentences.sentences.forEach((sentence) => {
                let past = 0;
                let present = 0;
                let future = 0;
                const verse = sentence.sentiment[0];

                sentence.details.forEach((word) => {
                    if (word.strongsnumber !== -1) {
                        if (word.parsing.timing === 'Past') past += 1;
                        if (word.parsing.timing === 'Present') present += 1;
                        if (word.parsing.timing === 'Future') future += 1;
                    }
                });

                // eslint-disable-next-line max-len
                valsPast.push([`${verse.split('.')[1]}.${verse.split('.')[2]}`, past, `${sentence.sentiment[0]}: ${sentence.sentiment[2]}`, 'stroke-width: 20']);
                // eslint-disable-next-line max-len
                valsPresent.push([`${verse.split('.')[1]}.${verse.split('.')[2]}`, present, `${sentence.sentiment[0]}: ${sentence.sentiment[2]}`]);
                // eslint-disable-next-line max-len
                valsFuture.push([`${verse.split('.')[1]}.${verse.split('.')[2]}`, future, `${sentence.sentiment[0]}: ${sentence.sentiment[2]}`]);
                // eslint-disable-next-line max-len
                valsTotal.push([`${verse.split('.')[1]}.${verse.split('.')[2]}`, past, present, future, `${sentence.sentiment[0]}: ${sentence.sentiment[2]}`, 'stroke-width: 20']);
            });
        }

        return (
            <div style={{ minWidth: '100%' }}>
                <Chart
                    width="100%"
                    height="400px"
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        [
                            'verse',
                            'past',
                            'present',
                            'future',
                            { role: 'tooltip', type: 'string', p: { html: true } },
                            { role: 'style' },
                        ],
                    ].concat(valsTotal)}
                    options={{
                        isStacked: true,
                        hAxis: {
                            title: 'Verse',
                            // slantedText: true,
                            // slantedTextAngle: 90,
                            maxAlteration: 4,
                        },
                        trendlines: {
                            0: {
                                type: 'linear',
                                color: 'green',
                                lineWidth: 3,
                                opacity: 0.3,
                                showR2: true,
                                visibleInLegend: true,
                            },
                        },
                        vAxis: {
                            title: 'Occurences',
                            viewWindow: { min: 0, max: 7 },
                        },
                        bars: 'vertical',
                        bar: { groupWidth: '200%' },
                    // legend: "none"
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
                {(this.props.figureNumber) ? (
                    <SourceFigure
                        sources={this.props.sources}
                        figureNumber={this.props.figureNumber}
                        figureName="Verb tense chart indicating past, present and future tense of verbs"
                    />
                ) : null}
            </div>
        );
    }
}

class PastPresentFutureTestGuage extends React.Component {
    static contextType = ThemeContext;

    render() {
        const details = this.props.sentence.details.filter((element) => (element.strongsnumber !== -1));

        const verbs = details.filter((word) => (word.parsing.parsing.indexOf('V-') > -1));

        const past = verbs.filter((element) => (element.parsing.timing === 'Past'));

        const present = verbs.filter((element) => (element.parsing.timing === 'Present'));

        const future = verbs.filter((element) => (element.parsing.timing === 'Future'));

        const unclassified = verbs.length - past.length - present.length - future.length;
        // console.log("Sentence", verbs);

        const pastPct = (verbs.length === 0) ? 0 : (past.length / verbs.length) * 100;
        const presentPct = (verbs.length === 0) ? 0 : (present.length / verbs.length) * 100;
        const futurePct = (verbs.length === 0) ? 0 : (future.length / verbs.length) * 100;
        const unclassifiedPct = (verbs.length === 0) ? 100 : (unclassified / verbs.length) * 100;
        const tenses = [
            {
                tense: 'future',
                amount: futurePct,
            },
            {
                tense: 'present',
                amount: presentPct,
            },
            {
                tense: 'past',
                amount: pastPct,
            },
            {
                tense: 'undefined',
                amount: unclassifiedPct,
            },
        ];

        tenses.sort((a, b) => {
            if (a.amount > b.amount) {
                return -1;
            }
            if (b.amount > a.amount) {
                return 1;
            }
            return 0;
        });

        const verdict = tenses[0].tense;

        const chartdata = [
            {
                angle: 0,
                color: '#FFFFFF',
            },
            {
                angle: pastPct,
                label: (pastPct > 0) ? `${pastPct.toFixed(0)}%` : null,
                subLabel: (pastPct > 0) ? 'Past' : null,
                color: '#00C0A0',
            },
            {
                angle: presentPct,
                label: (presentPct > 0) ? `${presentPct.toFixed(0)}%` : null,
                subLabel: (presentPct > 0) ? 'Present' : null,
                color: '#00A0C0',
            },
            {
                angle: futurePct,
                label: (futurePct > 0) ? `${futurePct.toFixed(0)}%` : null,
                subLabel: (futurePct > 0) ? 'Future' : null,
                color: '#10D0C0',
            },
            {
                angle: unclassifiedPct,
                label: (unclassifiedPct > 0) ? `${unclassifiedPct.toFixed(0)}%` : null,
                subLabel: (unclassifiedPct > 0) ? 'Unclassified' : null,
                color: '#EEEEEE',
            },
        ];

        return (
            (this.props.sentence.sentiment) ? (
                <div>
                    <SubheaderText id="example-collapse-text">
                        Past, Present, Future Verb Analysis
                    </SubheaderText>
                    <Row>
                        <Col md={3}>
                            <div style={{ width: 150, height: 150, position: 'relative' }}>
                                <RadialChart
                                    colorType="literal"
                                    showLabels
                                    labelsRadiusMultiplier={1.3}
                                    data={chartdata}
                                    width={150}
                                    height={150}
                                    radius={50}
                                    innerRadius={25}
                                    padAngle={0.04}
                                />
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zindex: 10,
                                }}
                                >
                                    <div style={{ height: '100%' }} />
                                </div>
                            </div>

                        </Col>
                        <Col md={9}>
                            {(verdict === 'undefined')
                                ? (
                                    <BodyText>
                                        { /* eslint-disable-next-line max-len */ }
                                        ai-BIBLE cannot determine the tense of the sentence from it&apos;s verbs, this may be because there are no verbs in the verse.
                                    </BodyText>
                                )
                                : null}
                            {(verdict !== 'undefined')
                                ? (
                                    <BodyText>
                                        ai-BIBLE believes that most verbs in the original language are in the
                                        {' '}
                                        <b>{verdict}</b>
                                        {' '}
                                        tense in this verse.
                                    </BodyText>
                                )
                                : null}
                        </Col>
                    </Row>
                    <SmallGutter />
                    <SmallGutter />
                    {/* <Table variant="" size="sm" responsive="sm" striped="true">
                <thead>
                    <tr>
                        <th>Stat</th>
                        <th>Verse</th>
                        <th>Sentiment</th>
                        <th>Text</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><BodyText><b>Most Positive</b></BodyText></td>
                        <td><BodyText>{this.props.sentence.stats.mostPositive[0]}</BodyText></td>
                        <td><BodyText>{this.props.sentence.stats.mostPositive[1]}</BodyText></td>
                        <td><BodyText>{this.props.sentence.stats.mostPositive[2]}</BodyText></td>
                    </tr>
                    <tr>
                        <td><BodyText><b>Least Positive</b></BodyText></td>
                        <td><BodyText>{this.props.sentence.stats.leastPositive[0]}</BodyText></td>
                        <td><BodyText>{this.props.sentence.stats.leastPositive[1]}</BodyText></td>
                        <td><BodyText>{this.props.sentence.stats.leastPositive[2]}</BodyText></td>
                    </tr>
                    <tr>
                        <td><BodyText><b>Average</b></BodyText></td>
                        <td><BodyText>{this.props.sentence.stats.average[0]}</BodyText></td>
                        <td><BodyText>{this.props.sentence.stats.average[1]}</BodyText></td>
                        <td><BodyText>{this.props.sentence.stats.average[2]}</BodyText></td>
                    </tr>
                </tbody>
            </Table> */}

                    {(this.props.sources) ? <SourceCredits sources={this.props.sources} /> : null}
                </div>
            ) : null
        );
    }
}

const isPrescriptive = (sentence) => {
    let specialWordsCheck = 0;

    let prevStrongsNumber = -1;
    const details = sentence.details.filter((element) => {
        if (['g1510', 'g3004'].includes(prevStrongsNumber)) {
            if (element.strongsnumber === 'g4771') {
                // console.log("Special word: " + prev_strongs_number + " " + element.strongsnumber);
                specialWordsCheck += 1;
            }
        }

        if (element.strongsnumber === 'g4771') {
            // console.log("Special word: " + prev_strongs_number + " " + element.strongsnumber);
            specialWordsCheck += 1;
        }

        prevStrongsNumber = element.strongsnumber;
        return (element.strongsnumber !== -1);
    });

    const verbs = details.filter((word) => (word.parsing.parsing.indexOf('V-') > -1));

    // eslint-disable-next-line max-len
    const verbsAndNegatives = details.filter((word) => ((word.parsing.parsing.indexOf('V-') > -1) || word.parsing.mood === 'Negative')); // Includes adverbs

    // eslint-disable-next-line max-len
    const instructions = details.filter((word) => ((word.parsing.instruction != null) && (word.parsing.instruction !== '')));

    // eslint-disable-next-line max-len
    const commands = verbsAndNegatives.filter((word) => (word.parsing.mood === 'Imperative (command)' || word.parsing.mood === 'Negative')); // Includes adverbs

    // eslint-disable-next-line max-len
    const intents = verbs.filter((word) => (word.parsing.mood === 'Cohort (let us)' || word.parsing.mood === 'Subjunctive (hypothetical or demand)' || word.parsing.mood === 'Optative (wish or desire)'));
    // eslint-disable-next-line max-len
    const secondthirdpersonsplural = (verbs.concat(instructions)).filter((word) => ((word.parsing.person === '3P') || (word.parsing.person === '2P')));

    const commandValue = Math.min(((commands.length * 100) / Math.max(verbsAndNegatives.length, 1)), 100);
    const intentsValue = (intents.length / Math.max(verbs.length, 1)) * 100;
    // eslint-disable-next-line max-len
    const thirdpersonsValue = (secondthirdpersonsplural.length / Math.max((verbs.length + instructions.length), 1)) * 100;
    const instructionsValue = Math.min((instructions.length + specialWordsCheck) * 40, 100);

    // eslint-disable-next-line max-len
    const totalValue = Math.min((0.6 * commandValue) + (0.2 * intentsValue) + (0.3 * thirdpersonsValue) + (0.5 * instructionsValue), 100);

    let verdict = (totalValue >= 49.9) ? 'is prescriptive' : 'might be prescriptive';

    if (totalValue < 30) verdict = 'is descriptive';

    return {
        command_value: commandValue,
        intents_value: intentsValue,
        thirdpersons_value: thirdpersonsValue,
        instructions_value: instructionsValue,
        instruction_check: instructions.length + specialWordsCheck,
        total_value: totalValue,
        verdict,
        special_words_check: specialWordsCheck,
    };
};

class PrescriptiveDescriptiveChart extends React.Component {
    static contextType = ThemeContext;

    render() {
        const values = [];
        if (this.props.sentences) {
            this.props.sentences.sentences.forEach((sentence) => {
                // console.log(sentence);

                const prescriptive = isPrescriptive(sentence);
                const verse = sentence.sentiment[0];

                // eslint-disable-next-line max-len
                values.push([verse.split('.')[1] + "." + verse.split('.')[2], prescriptive.total_value, `${sentence.sentiment[0]}: ${sentence.sentiment[2]}`]);
            });
        }

        return (
            <div>
                <SubheaderText>
                    Prescriptive Descriptive Verb Analysis
                </SubheaderText>
                <Chart
                    width="100%"
                    height="400px"
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['verse', 'prescriptiveness', { role: 'tooltip', type: 'string', p: { html: true } }],
                    ].concat(values)}
                    options={{
                        hAxis: {
                            title: 'Verse',
                            // slantedText: true,
                            // slantedTextAngle: 90,
                            maxAlteration: 4,
                        },
                        vAxis: {
                            title: 'Prescriptiveness',
                            viewWindow: { min: 0, max: 100 },
                        },
                        legend: 'none',
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
                {(this.props.figureNumber) ? (
                    <SourceFigure
                        sources={this.props.sources}
                        figureNumber={this.props.figureNumber}
                        // eslint-disable-next-line max-len
                        figureName="An indicator as to whether ai-BIBLE believes the sentence to be prescriptive or descriptive, based on verb analysis"
                    />
                ) : null}
            </div>
        );
    }
}

class PrescriptiveDescriptiveGuages extends React.Component {
    static contextType = ThemeContext;

    render() {
        console.log('Guage', this.props.sentence);
        const result = isPrescriptive(this.props.sentence);

        let color = '#20C0A0';
        if (result.verdict === 'might be prescriptive') color = '#A0C080';
        if (result.verdict === 'is descriptive') color = '#C0C080';

        const chartdata = [
            {
                angle: 0,
                label: `${result.total_value.toPrecision(2)}%`,
                color: '#FFFFFF',
            },
            {
                angle: result.total_value,
                color,
            },
            {
                angle: 100 - result.total_value,
                color: '#EEEEEE',
            },
        ];

        return (
            (this.props.sentence.sentiment) ? (
                <div>
                    <SubheaderText id="example-collapse-text">
                        Prescriptive vs Descriptive:
                    </SubheaderText>
                    <Row>
                        <Col md={3}>
                            <div style={{ width: 150, height: 150, position: 'relative' }}>
                                <RadialChart
                                    colorType="literal"
                                    data={chartdata}
                                    width={150}
                                    height={150}
                                    radius={60}
                                    innerRadius={30}
                                    padAngle={0.04}
                                />
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zindex: 10,
                                }}
                                >
                                    <div style={{ height: '100%' }}>
                                        <p style={{
                                            margin: 'auto',
                                            textAlign: 'center',
                                            verticalAlign: 'middle',
                                            position: 'absolute',
                                            top: 62,
                                            left: 58,
                                        }}
                                        >
                                            {`${result.total_value.toPrecision(2)}%`}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col md={9}>
                            <BodyText>
                                ai-BIBLE believes the sentence
                                {' '}
                                <b>{result.verdict}</b>
                                {' '}
                                because it believes
                                {' '}
                                {result.command_value.toFixed(0)}
                                % of verbs and negative adverbs were imperative or negative,
                                {' '}
                                {result.intents_value.toFixed(0)}
                                % of verbs expressed desire, intent or cohort and
                                {' '}
                                {result.thirdpersons_value.toFixed(0)}
                                { /* eslint-disable-next-line max-len */ }
                                % of verbs and instructive terms were second or third person plural, and we found
                                {' '}
                                {(result.instruction_check)}
                                {' '}
                                terms associated with instruction.
                            </BodyText>
                        </Col>
                    </Row>
                    <SmallGutter />
                    <SmallGutter />
                    {(this.props.sources) ? <SourceCredits sources={this.props.sources} /> : null}
                </div>
            ) : null
        );
    }
}

export {
    PrescriptiveDescriptiveGuages,
    PastPresentFutureTestGuage,
    PastPresentFutureTenseChart,
    PrescriptiveDescriptiveChart,
};
