import React from 'react';
import './OutlineButtons.css';
import '../../colors.css';
import osisConverters from '../../utilities/osisConversions';
import { Capacitor } from '@capacitor/core';
const web_version = (Capacitor.getPlatform()==='web');

const VerseButton = (props) => {
    const range = [];
    let { content } = props;
    let osisRange = "";

    // console.log('content: ', content, typeof content)

    if (!content)
        content = "Undefined!"
    
    if (typeof content === "string")
        osisRange = content;
    else if (content.osisRange)
        osisRange = content.osisRange;
    else if (content.ref)
        osisRange = content.ref;

    osisRange = osisConverters.formatOsisRange(osisRange)

    const colorIndex = props.colorIndex || 0;
    const {
        osisBookStart,
        osisBookEnd,
        osisChapterStart,
        osisChapterEnd,
        osisVerseStart,
        osisVerseEnd,
    } = osisConverters.deconstructOsisRange(osisRange);

    let title= osisChapterStart + "." + osisVerseStart + "-" + osisChapterEnd + "." + osisVerseEnd;

    // console.log('title', title)
    if (!osisVerseStart)
    {
        if (osisChapterEnd === osisChapterStart)
            title = osisChapterStart;
        else
            title = osisChapterStart + "-" + osisChapterEnd;
    }

    if ((osisChapterEnd == osisChapterStart)&&(osisVerseEnd == osisVerseStart))
    {
        title = osisChapterStart + "." + osisVerseStart;
    }

    if (content.title)
        title = content.title;

    title = title + ""

    title = title.replace('.',':').replace('.',':');

    let href= window.getVerseURL(osisRange);

    if (content.href)
    {
        href = content.href;
    }
    
    return (
        <div className="outline-buttons">
            <a
                className={`color-${colorIndex} outline-button`}
                href={href}
                target={ (window.web_version)?"_blank":"_parent" } 
                onClick={() => { console.log('VerseButton: Click: ', osisRange, href); }}
            >
                <p className="outline-button-text">
                    {title}
                </p>
            </a>
        </div>
    );
};

export default VerseButton;
