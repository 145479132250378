import React, {useRef} from 'react';
import styled from 'styled-components';

const StyledScrollArea = styled.div`
    overflow-y: scroll;
    min-width: 100%;
`

const ScrollArea = ({children, ...rest}) => {
    const ref = useRef();
    const bounds = ref?.current?.getBoundingClientRect();
    let height = '100vh';
    if (bounds) {
        height = window.innerHeight - bounds.y - (rest.trimY?rest.trimY:0);
    }

    return (
        <StyledScrollArea style={{minHeight: height, maxHeight: height}} ref={ref}>
            { children }
        </StyledScrollArea>
    )
}

export default ScrollArea;