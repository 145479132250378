import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeContext } from '../Core/ThemeContext';
import { BodyText, FootnoteReference } from '../Core/TextComponents';
import '../../index.css';

const descriptionStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'wrap',
    width: 2000,
    paddingBottom: 0,
    marginBottom: 0,
    cursor: 'pointer',
};

function SizedCardMedia(props) {
    const isTabletOrMobileDevice = useMediaQuery('(max-width: 1224px)');
    const isSmallTabletOrMobileDevice = useMediaQuery('(max-width: 768px)');

    let size = 88;
    if (isSmallTabletOrMobileDevice) size = 88;
    else if (isTabletOrMobileDevice) size = 88;

    return (
        <CardMedia
            image={props.src}
            title={props.value}
            style={{
                cursor: 'pointer',
                width: 'auto',
                height: { size },
                minWidth: { size },
                minHeight: { size },
            }}
            className=""
            component="img"
            alt="N/A"
            height={size}
            width={size}
        />
    );
}

class VerseCard extends Component {
    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = { open: false };
        this.handleSelect = this.handleSelect.bind(this);
        this.cardSize = props.size;
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    handleSelect(tab) {
        console.log('VerseCrad: Selected: ', tab, this);
    }

    render() {
        return (
            <div>
                <Card
                    style={{
                        display: 'flex',
                        background: '#fff',
                        margin: 0,
                        borderRadius: 0,
                    }}
                    className="bookcard"
                    width={1}
                >
                    <SizedCardMedia
                        onClick={() => {
                            this.setState((prevState) => ({ open: !prevState.open }));
                        }}
                        src={this.props.src}
                        value={this.props.value}
                    />
                    <CardContent
                        style={descriptionStyle}
                        onClick={() => {
                            this.setState((prevState) => ({ open: !prevState.open }));
                        }}
                    >
                        <h5 variant="h6" component="h3">
                            {this.props.detailsheader}
                        </h5>
                        <p
                            style={{
                                color: '#667',
                            }}
                        >
                            {this.props.details}
                            {(this.props.detailsFootnote) ? (
                                <FootnoteReference
                                    number={this.props.detailsFootnote}
                                    nolink
                                />
                            ) : null}
                        </p>
                    </CardContent>
                    <CardActions align="end" className="ml-3 mr-3">
                        <a href={window.getVerseURL(this.props.link)}>READ</a>
                    </CardActions>
                </Card>
                <Collapse in={this.state.open}>
                    <div className="m-3">
                        <BodyText>{this.props.body}</BodyText>
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default withRouter(VerseCard);
