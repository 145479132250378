import React from 'react';
import { Table } from 'react-bootstrap';
import { ThemeContext } from '../Core/ThemeContext';
import { SourceFigure, BodyLinkInline, SubheaderText } from '../Core/TextComponents';

class CrossReferenceList extends React.Component {
    static contextType = ThemeContext;

    render() {
        const xrefs = [];
        let xrefsTmp = [];
        let xrefsRef = [];
        const xrefsgrouped = {};

        console.log('props', this.props)

        if (this.props.why) {
            if (Object.prototype.hasOwnProperty.call(this.props.why, 'cross_references')) {
                xrefsTmp = this.props.why.cross_references;

                if (Object.prototype.hasOwnProperty.call(xrefsTmp, 'refs')) xrefsRef = xrefsTmp.refs;
                else xrefsRef = xrefsTmp;

                // if just top10 are required then sort here
                if ((this.props.top10) && (xrefsRef.length > 10)) {
                    xrefsRef.sort((a, b) => {
                        if (a.votes > b.votes) return -1;
                        if (b.votes > a.votes) return 1;
                        return 0;
                    });

                    xrefsRef = xrefsRef.slice(0, 10);
                }

                // In here we want to group cross references by book
                // lets first work out the book and pit that in the table
                for (let i = 0; i < xrefsRef.length; i += 1) {
                    let reference = "";
                    if (xrefsRef[i].ref)
                        reference = xrefsRef[i].ref
                    else
                        reference = xrefsRef[i]
                    
                    //if (xrefsRef[i].ref)
                    {
                        const book = reference.split('.')[0];
                        const ref = {
                            ref: reference,
                            votes: xrefsRef[i].votes,
                            book,
                            display: reference.replace(`${book}.`, '').replace(`${book}.`, ''),
                        };
                        xrefs[i] = ref;

                        if (book) {
                            if (Object.prototype.hasOwnProperty.call(xrefsgrouped, book)) {
                                xrefsgrouped[book].push(ref);
                            } else {
                                xrefsgrouped[book] = [ref];
                            }
                        }
                    }
                    // else
                    // {
                    //     console.error('invalid xref: ' + xrefsRef[i]);
                    // }
                }
            }
        }

        console.log('xrefsgrouped', xrefsgrouped);

        return (
            (this.props.why) ? (
                <div>
                    <SubheaderText id="example-collapse-text">
                        Cross References
                    </SubheaderText>
                    <Table variant="" striped="true" response="xl">
                        <thead>
                            <tr>
                                <th>Book</th>
                                <th>Cross-References</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(xrefsgrouped).map((value, index) => (
                                    <tr key={index}>
                                        <td>{value}</td>
                                        <td style={{ padding: '3px' }}>
                                            <ol style={{ maxWidth: window.innerWidth * 0.9 }}>
                                                { xrefsgrouped[value].map((cref, indx) => (
                                                    <BodyLinkInline
                                                        key={indx}
                                                        href={`http://${window.location.host}/read/${cref.ref}`}
                                                    >
                                                        <b style={{ paddingLeft: '5px' }}>
                                                            {cref.display}
                                                        </b>
&nbsp;
                                                    </BodyLinkInline>
                                                ))}
                                            </ol>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot />
                    </Table>
                    {(this.props.figureNumber) ? (
                        <SourceFigure
                            sources={this.props.sources}
                            figureNumber={this.props.figureNumber}
                            // eslint-disable-next-line max-len
                            figureName="A table of cross references between the selected passage and other passages"
                        />
                    ) : null}
                </div>
            ) : null
        );
    }
}

export default CrossReferenceList;
