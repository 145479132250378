import React from 'react';
import Collapsable from '../Core/Collapsable';
import CrossReferenceOutline from '../Lists/CrossReferenceOutline';

function Why(props) {
    // eslint-disable-next-line no-console
    console.log('WHY', props);
    const { why, sources, figureNumber } = props;
    return (
        <>
            {
                (why)
                    ? (
                        <>
                            <Collapsable startsOpen={window.web_version} title="10 Highest Rated Cross References">
                                <CrossReferenceOutline
                                    why={why}
                                    sources={sources}
                                    figureNumber={figureNumber}
                                    top10
                                />
                            </Collapsable>
                            <Collapsable startsOpen={false} title="All Cross References">
                                <CrossReferenceOutline
                                    why={why}
                                    sources={sources}
                                    figureNumber={figureNumber + 1}
                                />
                            </Collapsable>
                        </>
                    ) : null
            }
        </>
    );
}

export {
    // eslint-disable-next-line
    Why,
};
