import React from 'react';
import { ThemeContext } from './Core/ThemeContext';
import { SourceFootnotes, BodyText, FootnoteReference } from './Core/TextComponents';
import Collapsable from './Core/Collapsable';

class BookSummary extends React.Component {
    static contextType = ThemeContext;

    render() {
        return (
            <div>
                {(this.props.book) ? (
                    <div>
                        {(this.props.book.Eastons)
                            ? (
                                <Collapsable startsOpen={window.web_version} title="Book Summary">
                                    <div>
                                        <BodyText>
                                            {' '}
                                            <b>Summary:</b>
                                            {' '}
                                        </BodyText>
                                        <BodyText>
                                            {' '}
                                            {this.props.book.Eastons}
                                            {' '}
                                            <FootnoteReference number={this.props.footnoteStart} />
                                        </BodyText>
                                    </div>
                                    {(this.props.footnotes)
                                        ? (
                                            <SourceFootnotes
                                                footnotes={this.props.footnotes}
                                                footnoteStart={this.props.footnoteStart}
                                            />
                                        ) : null}
                                </Collapsable>
                            )
                            : null}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default BookSummary;
