// OutlineBox.stories.js

import React from 'react';
import { OutlineBox } from './OutlineBox';

// This default export determines where you story goes in the story list
export default {
    title: 'Widgets/OutlineBox',
    component: OutlineBox,
};

// eslint-disable-next-line react/jsx-props-no-spreading
const Template = (args) => (
    <>
        <OutlineBox 
            colorIndex={args.colorIndex}
            subsections={args.subsections}
            title={args.title}
        />
    </>
);

export const Normal = Template.bind({});

Normal.args = {
    /* the args you need here will depend on your component */
    colorIndex: 1,
    title: "Separation Through Disobedience",
    subsections: [
        {
          "osisRange": "Gen.4.1-Gen.6.22",
          "title": "Corruption of Humanity",
          "text": "Humanity descends into murder and corruption outside of God's presence, and suffers judgement.",
          "footnote": []
        },
        {
          "osisRange": "Gen.7.1-Gen.10.32",
          "title": "Noah's Obedience",
          "text": "Noah is obedient and blameless and is saved and blessed.",
          "footnote": []
        },
        {
          "osisRange": "Gen.11.1-Gen.11.32",
          "title": "Tower of Babel",
          "text": "Humanity seeks to save themselves by building a name for themselves.",
          "footnote": []
        }
      ]
};
