import React from 'react';
import { Table } from 'react-bootstrap';
import { ThemeContext } from '../Core/ThemeContext';
import {
    SourceFootnotes, SourceFigure, BodyTextInline, SubheaderText, FootnoteReference,
} from '../Core/TextComponents';
import TranslationCard from '../Cards/TranslationCard';
import Collapsable from '../Core/Collapsable';
import WordCloud from '../Charts/WordCloud';

class ConcordanceList extends React.Component {
    // eslint-disable-next-line
    static contextType = ThemeContext;

    render() {
        let words = [];
        const { showgrammer } = this.props;
        const { showstrongs } = this.props;
        const { showcount } = this.props;
        const showCountSpacing = (this.props.showcount) ? -1 : 0 + 6;
        const dictionary = (this.props.showgrammer) ? -1 : 0 + showCountSpacing;
        const original = 2;
        // const translation = 2 + (this.props.showcount)?-1:0;
        const translation = 2;

        const showtranslation = !!(this.props.sentence);

        const translationDictionary = this.props.dictionary;

        if (this.props.verses && this.props.verses.sentences) {
            const uniqueWords = {};
            const ignoreWords = [
                'in',
                'through',
                'this',
                'the',
                'he',
                'to',
                'his',
                'from',
                'of',
                'said',
                'and',
                'but',
                'had',
                'so',
                'is',
                'it',
                'up',
                'on',
                'are',
                'i',
                'not',
                'your',
                'there',
                'them',
                'you',
                'get',
                'whether',
                'after',
                'that',
                'will',
                'a',
                'or',
                'they',
                'with',
                'for',
                'she',
                'then',
                'let',
                'us',
                'by',
                'her',
                'our',
                'throughout',
                'well'];

            this.props.verses.sentences.forEach((sentence) => {
                let { verse } = sentence;
                // eslint-disable-next-line no-control-regex
                verse = verse.replace(/[^\x00-\x7F]/g, ' '); // remove unicode characters
                // eslint-disable-next-line no-useless-escape
                verse = verse.replace(/[.,\/#!/?$%\^&\*;:{}=\-_`~()]/g, ''); // remove non alpha characters
                const verseWords = verse.split(' ');
                verseWords.forEach((word) => {
                    let trimmedWord = word.toLowerCase().trim().replace('-', '').replace("'", '')
                        .replace(',', '')
                        .replace('.', '');
                    trimmedWord = trimmedWord.replace(/[0-9]/g, '');

                    if (!ignoreWords.includes(trimmedWord)) {
                        if (!Object.prototype.hasOwnProperty.call(uniqueWords, trimmedWord)) {
                            uniqueWords[trimmedWord] = {
                                text: trimmedWord,
                                value: 1,
                            };
                        } else uniqueWords[trimmedWord].value += 1;
                    }
                });
            });

            words = Object.values(uniqueWords);
        } else if (this.props.sentence) {
            this.props.sentence.forEach((value) => {
                if ((value.count > 1) && (value.parsing.parsing !== 'N-proper-ms')) {
                    words.push({
                        text: value.text,
                        value: value.count,
                    });
                }
            });
        }

        function TranslationDictionaryRender() {
            return (translationDictionary)
                ? Object.keys(translationDictionary).map((key, index) => {
                    console.log('====> KEY', key, translationDictionary);
                    const value = translationDictionary[key];
                    value.strongsnumber = key;
                    console.log('====> VALUE', value);

                    if (showcount) {
                        if (value.count === 1) return (null);
                    }
                    if (value.original) {
                        return (
                            <TranslationCard
                                data={value}
                                key={index}
                                showstrongs={showstrongs}
                                showcount={showcount}
                                showgrammer={showgrammer}
                                showtranslation={showtranslation}
                            />
                        );
                    } return (null);
                }) : null;
        }

        function TableHeader(props) {
            return (
                <tr>
                    {
                        (showcount)
                            ? (
                                <th style={{ width: '6%' }}>
                                    <BodyTextInline>
                                        <b>Count</b>
                                    </BodyTextInline>
                                </th>
                            ) : null
                    }
                    {
                        (showstrongs)
                            ? (
                                <th style={{ width: '12%' }}>
                                    <BodyTextInline>
                                        <b>Strongs</b>
                                        <FootnoteReference number={props.footnoteStart + 1} />
                                    </BodyTextInline>
                                </th>
                            ) : null
                    }
                    <th md={original}>
                        <BodyTextInline>
                            <b>Original</b>
                            <FootnoteReference number={props.footnoteStart + 1} />
                        </BodyTextInline>
                    </th>
                    {
                        (showgrammer)
                            ? (
                                <th style={{ width: '12%' }}>
                                    <BodyTextInline>
                                        <b>Grammer</b>
                                        <FootnoteReference number={props.footnoteStart} />
                                    </BodyTextInline>
                                </th>
                            ) : null
                    }
                    {
                        (showgrammer)
                            ? (
                                <th md={translation}>
                                    <BodyTextInline>
                                        <b>Translation</b>
                                        <FootnoteReference number={props.footnoteStart} />
                                    </BodyTextInline>
                                </th>
                            ) : null
                    }
                    <th md={dictionary}>
                        <BodyTextInline>
                            <b>Dictionary</b>
                            <FootnoteReference number={props.footnoteStart + 2} />
                            <sup>,</sup>
                            <FootnoteReference number={props.footnoteStart + 3} />
                        </BodyTextInline>
                    </th>
                </tr>
            );
        }

        const figureNumber = this.props.figureNumber ? this.props.figureNumber : 0;

        return (
            <div>
                {(showcount) ? null : (
                    <SubheaderText id="example-collapse-text">
                        Concordance
                    </SubheaderText>
                )}
                <Collapsable
                    transparent={!showcount}
                    startsOpen={false}
                    title="Concordance of 10 Most Repeated Words"
                >
                    <Table variant="" size="sm" responsive="sm" striped="true">
                        <thead>
                            <TableHeader {...this.props} />
                        </thead>
                        <tbody>
                            {
                                (this.props.sentence)
                                    ? this.props.sentence.map((value, index) => {
                                        if (showcount) {
                                            if (value.count === 1) return (null);
                                        }
                                        if ((value.original) && (index < 11)) {
                                            return (
                                                <TranslationCard
                                                    data={value}
                                                    key={index}
                                                    showstrongs={showstrongs}
                                                    showcount={showcount}
                                                    showgrammer={showgrammer}
                                                    showtranslation={showtranslation}
                                                />
                                            );
                                        } return (null);
                                    })
                                    : null
                            }
                            <TranslationDictionaryRender />
                        </tbody>
                        <tfoot />
                    </Table>
                    {(this.props.figureNumber) ? (
                        <SourceFigure
                            sources={this.props.sources}
                            figureNumber={figureNumber + 1}
                            figureName="Concordance of 10 most commonly used words"
                        />
                    ) : null}
                    {(this.props.footnotes) ? (
                        <SourceFootnotes
                            footnotes={this.props.footnotes}
                            footnoteStart={this.props.footnoteStart}
                        />
                    ) : null}
                </Collapsable>
                { ((this.props.sentence) && (this.props.sentence.length > 10))
                    ? (
                        <Collapsable title="Concordance of All Repeated Words">
                            <Table variant="" size="sm" responsive="sm" striped="true">
                                <thead>
                                    <TableHeader {...this.props} />
                                </thead>
                                <tbody>
                                    {
                                        (this.props.sentence)
                                            ? this.props.sentence.map((value, index) => {
                                                if (showcount) {
                                                    if (value.count === 1) return (null);
                                                }
                                                if ((value.original) && ((index > 10)|| this.props.showAllEntries)) {
                                                    return (
                                                        <TranslationCard
                                                            data={value}
                                                            key={index}
                                                            showstrongs={showstrongs}
                                                            showcount={showcount}
                                                            showgrammer={showgrammer}
                                                            showtranslation={showtranslation}
                                                        />
                                                    );
                                                } return (null);
                                            })
                                            : null
                                    }
                                </tbody>
                                <tfoot />
                            </Table>
                            <div style={{ display: 'block' }}>
                                {(this.props.figureNumber) ? (
                                    <SourceFigure
                                        sources={this.props.sources}
                                        figureNumber={figureNumber + 1}
                                        figureName="Word cloud of commonly repeated words"
                                    />
                                ) : null}
                                {(this.props.footnotes) ? (
                                    <SourceFootnotes
                                        footnotes={this.props.footnotes}
                                        footnoteStart={this.props.footnoteStart}
                                    />
                                ) : null}
                            </div>
                        </Collapsable>
                    )
                    : null}
            </div>
        );
    }
}

class Concordance extends React.Component {
    // eslint-disable-next-line
    static contextType = ThemeContext;

    render() {
        let words = [];
        const { showgrammer } = this.props;
        const { showstrongs } = this.props;
        const showCountSpacing = (this.props.showcount) ? -1 : 0 + 6;
        const dictionary = (this.props.showgrammer) ? -1 : 0 + showCountSpacing;
        const original = 2;
        // const translation = 2 + (this.props.showcount)?-1:0;
        const translation = 2;

        const showtranslation = !!(this.props.sentence);

        const translationDictionary = this.props.dictionary;

        if (this.props.verses && this.props.verses.sentences) {
            const uniqueWords = {};
            const ignoreWords = [
                'in',
                'through',
                'this',
                'the',
                'he',
                'to',
                'his',
                'from',
                'of',
                'said',
                'and',
                'but',
                'had',
                'so',
                'is',
                'it',
                'up',
                'on',
                'are',
                'i',
                'not',
                'your',
                'there',
                'them',
                'you',
                'get',
                'whether',
                'after',
                'that',
                'will',
                'a',
                'or',
                'they',
                'with',
                'for',
                'she',
                'then',
                'let',
                'us',
                'by',
                'her',
                'our',
                'throughout',
                'well'];

            this.props.verses.sentences.forEach((sentence) => {
                let { verse } = sentence;
                // eslint-disable-next-line no-control-regex
                verse = verse.replace(/[^\x00-\x7F]/g, ' '); // remove unicode characters
                // eslint-disable-next-line no-useless-escape
                verse = verse.replace(/[.,\/#!/?$%\^&\*;:{}=\-_`~()]/g, ''); // remove non alpha characters
                const verseWords = verse.split(' ');
                verseWords.forEach((word) => {
                    let trimmedWord = word.toLowerCase().trim().replace('-', '').replace("'", '')
                        .replace(',', '')
                        .replace('.', '');
                    trimmedWord = trimmedWord.replace(/[0-9]/g, '');

                    if (!ignoreWords.includes(trimmedWord)) {
                        if (!Object.prototype.hasOwnProperty.call(uniqueWords, trimmedWord)) {
                            uniqueWords[trimmedWord] = {
                                text: trimmedWord,
                                value: 1,
                            };
                        } else uniqueWords[trimmedWord].value += 1;
                    }
                });
            });

            words = Object.values(uniqueWords);
        } else if (this.props.sentence) {
            this.props.sentence.forEach((value) => {
                if ((value.count > 1) && (value.parsing.parsing !== 'N-proper-ms')) {
                    words.push({
                        text: value.text,
                        value: value.count,
                    });
                }
            });
        }

        function TranslationDictionaryRender() {
            return (translationDictionary)
                ? Object.keys(translationDictionary).map((key, index) => {
                    console.log('====> KEY', key, translationDictionary);
                    const value = translationDictionary[key];
                    value.strongsnumber = key;
                    console.log('====> VALUE', value);

                    if (value.original) {
                        return (
                            <TranslationCard
                                data={value}
                                key={index}
                                showstrongs={showstrongs}
                                showcount={false}
                                showgrammer={showgrammer}
                                showtranslation={showtranslation}
                            />
                        );
                    } return (null);
                }) : null;
        }

        function TableHeader(props) {
            return (
                <tr>
                    {
                        (showstrongs)
                            ? (
                                <th style={{ width: '12%' }}>
                                    <BodyTextInline>
                                        <b>Strongs</b>
                                        <FootnoteReference number={props.footnoteStart + 1} />
                                    </BodyTextInline>
                                </th>
                            ) : null
                    }
                    <th md={original}>
                        <BodyTextInline>
                            <b>Original</b>
                            <FootnoteReference number={props.footnoteStart + 1} />
                        </BodyTextInline>
                    </th>
                    {
                        (showgrammer)
                            ? (
                                <th style={{ width: '12%' }}>
                                    <BodyTextInline>
                                        <b>Grammer</b>
                                        <FootnoteReference number={props.footnoteStart} />
                                    </BodyTextInline>
                                </th>
                            ) : null
                    }
                    {
                        (showgrammer)
                            ? (
                                <th md={translation}>
                                    <BodyTextInline>
                                        <b>Translation</b>
                                        <FootnoteReference number={props.footnoteStart} />
                                    </BodyTextInline>
                                </th>
                            ) : null
                    }
                    <th md={dictionary}>
                        <BodyTextInline>
                            <b>Dictionary</b>
                            <FootnoteReference number={props.footnoteStart + 2} />
                            <sup>,</sup>
                            <FootnoteReference number={props.footnoteStart + 3} />
                        </BodyTextInline>
                    </th>
                </tr>
            );
        }

        const figureNumber = this.props.figureNumber ? this.props.figureNumber : 0;

        return (
            <div>
                <SubheaderText id="example-collapse-text">
                    Concordance
                </SubheaderText>
                <Collapsable
                    transparent
                    startsOpen={false}
                    title="Concordance of 10 Most Repeated Words"
                >
                    <Table variant="" size="sm" responsive="sm" striped="true">
                        <thead>
                            <TableHeader {...this.props} />
                        </thead>
                        <tbody>
                            {
                                (this.props.sentence)
                                    ? this.props.sentence.map((value, index) => {
                                        if ((value.original) && ((index < 11) || this.props.showAllEntries)) {
                                            return (
                                                <TranslationCard
                                                    data={value}
                                                    key={index}
                                                    showstrongs={showstrongs}
                                                    showcount={false}
                                                    showgrammer={showgrammer}
                                                    showtranslation={showtranslation}
                                                />
                                            );
                                        } return (null);
                                    })
                                    : null
                            }
                            <TranslationDictionaryRender />
                        </tbody>
                        <tfoot />
                    </Table>
                    {(this.props.figureNumber) ? (
                        <SourceFigure
                            sources={this.props.sources}
                            figureNumber={figureNumber + 1}
                            figureName="Concordance"
                        />
                    ) : null}
                    {(this.props.footnotes) ? (
                        <SourceFootnotes
                            footnotes={this.props.footnotes}
                            footnoteStart={this.props.footnoteStart}
                        />
                    ) : null}
                </Collapsable>
            </div>
        );
    }
}

export { Concordance, ConcordanceList };
