import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { withRouter } from 'react-router-dom';
import SEO from "./components/SEO";
import books from './data/books';
import Devotionals from './plans/Devotionals';
import { Container, HeaderText } from './components/Core/TextComponents';
import TitleLine from './components/Core/TitleLine';
import { ThemeContext } from './components/Core/ThemeContext';
import ScrollArea from './components/Core/ScrollArea';
import BibleReader from './components/BibleReader';
var bcv_parser = require("bible-passage-reference-parser/js/en_bcv_parser").bcv_parser;
var bcv = new bcv_parser;

const querystring = require('querystring');
import { withAlert } from 'react-alert';

import {
     Tabs, Tab,
} from 'react-bootstrap-legacy';

import { Capacitor } from '@capacitor/core';
import * as Swetrix from 'swetrix'
import { SubheaderText, CommentText, CommentHeader, CommentNotes, SmallGutter, LargeGutter } from './components/Core/TextComponents';
import PlanOutline from './components/Outlines/PlanOutline';
import VerseOutline from './components/Outlines/VerseOutline';

const web_version = (Capacitor.getPlatform()==='web');

class BibleReading extends Component {
    static contextType = ThemeContext;

    static xDown = null;

    static yDown = null;

    bodyScrollLockTargetRef = React.createRef();
    bodyScrollLockTargetElement = null;

    sourcePermissionsRef = React.createRef();

    static getTouches(evt) {
        return evt.touches // browser API
               || evt.originalEvent.touches; // jQuery
    }

    static handleTouchStart(evt) {
        console.log('ts');
        const firstTouch = BibleReader.getTouches(evt)[0];
        BibleReader.xDown = firstTouch.clientX;
        BibleReader.yDown = firstTouch.clientY;
    }

    static handleTouchMove(evt) {
        if (!BibleReader.xDown || !BibleReader.yDown) {
            return;
        }

        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;

        const xDiff = BibleReader.xDown - xUp;
        const yDiff = BibleReader.yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) { /* most significant */
            if (xDiff > 50) {
                /* left swipe */
                this.incrementPage('');
            } else if (xDiff < -50) {
                /* right swipe */
                this.decrementPage('');
            }
        } else if (yDiff > 0) {
            /* up swipe */
        } else {
            /* down swipe */
        }
        /* reset values */
        BibleReader.xDown = null;
        BibleReader.yDown = null;
    }

    static incrementPage() {
        const elements = window.location.href.split('.');
        const page = parseInt(elements[elements.length - 1]) + 1;
        let url = '';

        for (let i = 0; i < elements.length - 1; i += 1) {
            url += `${elements[i]}.`;
        }
        url += page;

        window.location.href = url;
    }

    static expandVerse() {
        const elements = window.location.href.split('.');
        
        let url = `${elements[0]}.${elements[1]}`;

        if (window.location.href.includes('ai-bible.com'))
        {
            url = `${elements[0]}.${elements[1]}.${elements[2]}.${elements[3]}`;
        }

        window.location.href = url;
    }

    static decrementPage() {
        const elements = window.location.href.split('.');
        const page = parseInt(elements[elements.length - 1]) - 1;
        let url = '';

        for (let i = 0; i < elements.length - 1; i += 1) {
            url += `${elements[i]}.`;
        }
        url += page;

        window.location.href = url;
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

   

    constructor() {
        super();

        console.log('PageBibleReading Constructed')
        // window.scrollTo(0,0)

        window.addEventListener('keydown', (ev) => {
            if (ev.key === 'ArrowRight') BibleReader.incrementPage(ev);
            if (ev.key === 'ArrowLeft') BibleReader.decrementPage(ev);
        });

        window.addEventListener('touchstart', (evt) => { BibleReader.handleTouchStart(evt); }, false);
        window.addEventListener('touchmove', (evt) => { BibleReader.handleTouchMove(evt); }, false);

        this.state = {
            title: '',
            book: '',
            text: [],
            loading: false,
            sources: {},
            colorIndex: 0,
            network: true
        };

        // Network.addListener('networkStatusChange', status => {
        //     console.log('network status change: ', status.connected)
        //     this.setState({network: status.connected})
        // });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        //enableBodyScroll(this.bodyScrollLockTargetElement);
        document.body.style.overflow = "";
    }

    handleResize = () => {
        console.log('window ' + window.innerWidth);
        this.forceUpdate();
    };

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    // deepcode ignore ReactIncorrectReturnValue: <please specify a reason of ignoring this>
    async componentDidMount() {
        console.log('REACT_APP_AWS_API', process.env.REACT_APP_AWS_API, ' web_version ', window.web_version);
        window.show_footer = false;

        this.bodyScrollLockTargetElement = this.bodyScrollLockTargetRef.current;
        //disableBodyScroll(this.bodyScrollLockTargetElement);

        console.log('bstyle', document.body.style.overflow)
        document.body.style.overflow = "hidden";

        window.addEventListener('resize', this.handleResize);
        console.log(`ComponentDidMount: ${new Date().getSeconds()} ${window.innerWidth} ${window.scrollY}`);
        console.log('QueryString: ', window.location.search);


        //const dynamicParam = this.props.match.params.dynamicParam;

        console.log('Params: ', this.props.match.params);

        const values = this.getVerse();

        console.log('Values: ', values)
        const { token } = localStorage;
        

        let endpoint = `/v1/verses/details/search?search=${values.verse}`;

        if (values.noRef)
        {
            console.log('noRef found')
            window.location.href = '/';
            
            return;
        }

        const verse_ref = values.verse.match(/[^-.]+/g);
        let chapter = -1;
        let verseStart = -1;
        let verseEnd = -1;

        console.log('window history', window.history);
        console.log('verseref: ', verse_ref);
        console.log('routeHistory', this.props.history)

        const history = [];

        // if (this.props.history.length >= 2)
        //     for (let i = 0; i < this.props.history.length; i++)
        //         history.push(this.props.history.entries[i]);

        console.log('rhistory', history)
        
        if (verse_ref.length == 1)
        {
            Swetrix.track("Book__" + verse_ref[0].trim())
            window.location.href = '/book?ref=' + verse_ref[0];
        }
        if ((verse_ref.length == 2)||(verse_ref.length > 100)) // chapter or verse range
        {
            if (verse_ref.length == 2)
                Swetrix.track("Chapter__" + values.verse.trim())

            console.log('Values', values)
            console.log('VerseRef', verse_ref)

            
            endpoint = values.pathPrefix + values.verse + ".json";
            chapter = verse_ref[1];
            verseStart = -1;

            this.context.loading(false);

            if (chapter == 0)
            {
                console.log('chapter', chapter, verse_ref)
                window.location.href = '/book?ref=' + verse_ref[0];
            }
        }
        else if (verse_ref.length > 2) // exact chapter + verse or range, get whole chapter and filter later
        {
            if (verse_ref.length > 3)
            {
                Swetrix.track("Verse__Range__" + values.verse)
                console.log('Values', values)
                console.log('VerseRef', verse_ref)

                if (verse_ref[1] == verse_ref[4])
                {
                    verseEnd = verse_ref[5];
                }
                else
                    verseEnd = 0; // special character to say just add the rest of the chapter
            }
            else
                Swetrix.track("Verse__" + verse_ref[0].trim() + "." + verse_ref[1].trim())

            endpoint = values.pathPrefix + verse_ref[0] + "." + verse_ref[1] + ".json";
            chapter = verse_ref[1];
            verseStart = verse_ref[2];


            console.log('Endpoin', endpoint)

            // In this case the endpoint looks up the whole chapter and filtering occurs later

            this.context.loading(false);

            if (verseStart == 0)
            {
                console.log('chapter', chapter, verseStart, verse_ref)
                window.location.href = '/book?ref=' + verse_ref[0];
            }
        }
        else if (process.env.REACT_APP_AWS_API || !web_version) {
            // if (!web_version)
            // {
            //     const network = await Network.getStatus();
            //     console.log("network status: ", network)
            //     if (!network.connected)
            //     {
            //         console.log('no internet connection');
            //         this.props.alert.show('no internet connection');
            //         return;
            //     }
            // }
            this.context.loading(true);

            endpoint = `${window.API_BASE}${endpoint}`;
        }

        console.log('MyHistory', window.reading_history);
        window.reading_history.push(endpoint);
        console.log('MyHistory', window.reading_history);
        
        console.log('verse', verse_ref, values)

        console.log(`Request: ${endpoint}`, process.env.REACT_APP_AWS_API);

        fetch(endpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log('res',res);

                if (res.status > 200)
                {
                    console.log('Status issue: ' + values.verse)
                }

                return res.text()
            })
            .then((resp) => {
                if (!resp.startsWith('{'))
                    this.handleRefError(values);

                const response = JSON.parse(resp);
                console.log(`response time: ${new Date().getSeconds()}`);
                console.log('response', response);
                console.log('status', response.status);

                // const text = [];
                // let data = response.verses;
                // const sources = response.sourceinfo;
                // const subgroup = response.book.Subgroup;

                // if (verse_ref.length === 3)
                // {
                //     data = [response.verses[verse_ref[2] - 1]];
                //     console.log('data', data)
                //     if ((!data) || (data.length === 0) || !data[0])
                //         this.handleRefError(values);
                // }

                // let title = '';
                // let book = '';

                // if (data.length > 0)
                // {
                //     for (let i = 0; i < data.length; i += 1) {
                //         const verseinfo = data[i];

                //         text.push({
                //             row: (i + 1),
                //             text: verseinfo.verse,
                //             data: verseinfo,
                //         });

                //         if (title === '') { title = `${data[i].what.name} ${data[i].what.chapter}`; }
                //         if (book === '') { book = `${data[i].what.osisRef.split('.')[0]}`; }
                //     }
                // }
                // else
                // {
                //     book = response.book.BookCode;
                //     title = response.book.Name;
                // }

                // this.setState({
                //     loading: false,
                //     text,
                //     title,
                //     sources,
                //     book,
                //     colorIndex: bookConverters.convertSubGroupToIndex(subgroup),
                // });

                const stateUpdate = BibleReader.processAPIData(response, chapter, verseStart, verseEnd);
                this.setState(stateUpdate);

                this.context.loading(false);
            }).catch((e)=>{
                console.log('exception', e);
                const bookName = bcv.parse(values.verse).osis();
                console.log('bookName',bookName);
                this.context.loading(false);
                this.props.alert.show('could not connect to server')
            });

            console.log('final');
    }

    getVerse()
    {
        if (window.location.pathname.includes('/reader'))
            return { ...querystring.parse(window.location.search.substr(1)), pathPrefix: './data/' }
        else
            return { verse: window.location.pathname.replace('/read/',''), pathPrefix: '../data/' }
    }

    getDevotional()
    {
        const searchParams = new URLSearchParams(window.location.search);
        console.log({dev:searchParams.get('dev')})
        return searchParams.get('dev');
    }

    getApplicationQuestions()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('aq');
    }

    getMeditation()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('m');
    }

    getComprehensionQuestions()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('cq');
    }

    getCenteringPrayer()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('cp');
    }

    getCommentary()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('c');
    }

    getApplicationAdvice()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('a');
    }

    getApplicationPrayer()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('ap');
    }

    getCrossReferences()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('cr');
    }

    async copyContent(content)
    {
        console.log('content', content);
        console.log('text', this.state.text);
        console.log('sources', this.state.sources);
        let verses = "";
        this.state.text.forEach(v => {
            verses += v.text;
        })

        const devotionalText = 
        `# Devotional: ${content.title}
**${this.state.title}**
>${verses}


**Comprehension**
*${content.commentary}*
${content.comprehensionQuestions[0]}
\`\`\`


\`\`\`
**Application**
*${content.application}*
${content.applicationQuestions[0]}
\`\`\`


\`\`\`
**Meditation**
*Take 3 minutes to consider the mediation below and note your thoughts:*
${content.meditation}
\`\`\`


\`\`\`
*BibleSource: ${this.state.sources.verse[0].permissions}*
   
*Devotionals (c) ai-bible.com 2024*
        `

        try {
            await navigator.clipboard.writeText(devotionalText);
            alert('copied to clipboard');
        } catch (error) {
            alert('could not copy to clipboard');
        }
    }

    handleRefError(values) {
        if (values.verse.split('.').length > 0)
        {
            const bookHint = values.verse.split('.')[0];

            console.log('bookHint', bookHint)

            const osisAttempt = bookHint + '.1';
            var osisRef = bcv.parse(osisAttempt).osis();

            if (osisRef)
            {
                const book = osisRef.split('.')[0];
                console.log('book', book)
                //window.location.href =`http://${window.location.host}/book?ref=${book}`;
            }
            else  window.location.href =`http://${window.location.host}/?noRef=${values.verse}`;
        }
        else
            window.location.href =`http://${window.location.host}/?noRef=${values.verse}`;
    }
    
    trimY = this.sourcePermissionsRef?.current?.getBoundingClientRect().y;

    render() {
        //this.trimY = this.sourcePermissionsRef?.current?.getBoundingClientRect().y;
        //console.log('Trimy', this.trimY);
        const searchedVerse = this.getVerse().verse
        let _meditation = decodeURIComponent(this.getMeditation());
        let _commentary = decodeURIComponent(this.getCommentary());
        let _application = decodeURIComponent(this.getApplicationAdvice());
        let _devotional = JSON.parse(decodeURIComponent(this.getDevotional()));
        let _applicationQuestions = JSON.parse(decodeURIComponent(this.getApplicationQuestions()));
        let _comprehensionQuestions = JSON.parse(decodeURIComponent(this.getComprehensionQuestions()));
        let _centeringPrayer = decodeURIComponent(this.getCenteringPrayer());
        let _applicationPrayer = decodeURIComponent(this.getApplicationPrayer());
        let _crossReferences = decodeURIComponent(this.getCrossReferences());
        let _title = "";
        let _content = {};

        if (_devotional == 'null') _devotional = null;
        if (_commentary == 'null') _commentary = null;
        if (_applicationQuestions == 'null') _applicationQuestions = null; 
        if (_crossReferences == 'null') _crossReferences = null; 

        if (_devotional)
        {
            // let title, osisRange
            let {
                osisRange,
                title,
                commentary,
                application,
                applicationQuestions,
                comprehensionQuestions,
                centeringPrayer,
                meditation,
                applicationPrayer,
                crossReferences
            } = Devotionals.getDevotional(_devotional);

            console.log('chk', commentary, commentary)

            _title = title;
            _commentary = commentary;
            _application = application;
            _applicationQuestions = applicationQuestions; 
            _meditation = meditation;
            _comprehensionQuestions = comprehensionQuestions;
            _centeringPrayer = centeringPrayer;
            _applicationPrayer = applicationPrayer;
            _crossReferences = crossReferences;

            _content = {
                title,
                commentary,
                application,
                applicationQuestions,
                meditation,
                comprehensionQuestions,
                centeringPrayer,
                applicationPrayer,
                crossReferences
            }
        }

        console.log('chk', _application, _crossReferences)

        console.log('dev', _devotional);

        let crossReferencesOutline = {};

        if (_crossReferences) {
            const cr_decoded = ((typeof(_crossReferences)==(typeof("")))?JSON.parse(_crossReferences):_crossReferences)
            const cr = cr_decoded.map(x=>{ return {osisRange:x, title:""}});
            console.log(cr, 'x', _crossReferences);
            crossReferencesOutline = books.getOutlines(cr); //.map(x => {return {subsections:[x]}});

            console.log('crossReferencesOutline', crossReferencesOutline)
        }

        console.log('search params', [...new URLSearchParams(window.location.search).keys()])

        console.log('crossReferences', _crossReferences);
        console.log('applicationPrayer', _applicationPrayer)

        console.log('cq: ', _commentary, _applicationQuestions, _meditation, _application, _centeringPrayer, _crossReferences, window.location)

        const canonical = `https://www.ai-bible.com/read/${searchedVerse}`;
        console.log('canonical', canonical)
        

        document.title = this.state.title || searchedVerse || 'ai-BIBLE';
        const aggregatedSources = this.state.sources;
        //console.log(`Sources: ${new Date().getSeconds()}`, aggregatedSources);
        const isNotFullChapter = (searchedVerse.split('.').length > 2) || searchedVerse.split('-').length > 1;

        console.log('text', this.state.text);

        let description = '';


        if (this.state.text) // set description filed from text
        {
            if (this.state.text.length > 0)
                description = this.state.text[0].text;

            if (this.state.text.length > 0)
            {
                for (let i = 1; i < this.state.text.length; i++)
                {
                    if (description.length < 156)
                    {
                        if (!description.endsWith('.')) description += ".";
                        description += " " + this.state.text[i].text;
                        description = description.substr(0, 157);
                    }
                    else
                    {
                        description = description.substr(0, 157);
                        break;
                    }
                }
            }
        }

        const values = this.getVerse();
        let filter = [];

        if (values.verse)
        {
            // based on verse range need to filter to juspt the required verses
            // set isNotFullChapter
        }

        //console.log('isNotFullChapter', isNotFullChapter, searchedVerse, searchedVerse.split('.').length)

        return (
            <>
                <SEO 
                    canonical = {canonical}
                    title = { this.state.title || searchedVerse || 'ai-BIBLE' }
                    description = { description || ''}
                />
                <SwipeableViews ref={this.bodyScrollLockTargetRef}>
                        
                        <div>
                            <div style={{
                                margin: 0,
                                // maxWidth: window.innerWidth,
                            }}
                            >
                                <LargeGutter></LargeGutter>
                                {(_commentary!=null)||(_applicationQuestions!=null)?<>
                                    <h1 style={{fontFamily:'Courier', fontSize: '1em', color:'#0009', paddingLeft:20, paddingTop:4, paddingBottom:4, backgroundColor:'#8882'}}>ai-bible devotional{(_title!="")?`: ${_title}`:""}</h1>
                                </>:null}
                                <TitleLine
                                    title={this.state.title}
                                    book={this.state.book}
                                    visible={!this.state.loading}
                                    colorIndex={this.state.colorIndex}
                                    onclickBack={BibleReading.decrementPage}
                                    onclickFull={BibleReading.expandVerse}
                                    onclickForward={BibleReading.incrementPage}
                                    onclickCopy={()=>{this.copyContent(_content)}}
                                    isNotFullChapter={isNotFullChapter}
                                    copyContent={_devotional != null}
                                    hideBot
                                />

                                <ScrollArea trimY={0}>
                                    <BibleReader text={this.state.text} sources={this.state.sources} colorIndex={this.state.colorIndex}></BibleReader>
                                    {(_commentary!=null)||(_applicationQuestions!=null)?
                                    <>
                                        <div>
                                            <>
                                                
                                                <h1 style={{fontFamily:'Courier', fontSize: '1em', color:'#0009', paddingLeft:20, paddingTop:4, paddingBottom:4, backgroundColor:'#8882'}}>questions</h1>
                
                                            </>    
                                        </div>

                                        <div style={{paddingLeft:16}}>
                                            <CommentHeader>Comprehension</CommentHeader>
                                            <CommentNotes>Consider the passage and comments to help answer the question below:</CommentNotes>
                                            <CommentText style={{color:'grey'}}>comment: {_commentary}</CommentText>
                                            <CommentText style={{color:'grey'}}>question: {_comprehensionQuestions[0]}</CommentText>

                                            <LargeGutter></LargeGutter>
                                            <CommentNotes>Does your answer change when considering other bible verses:</CommentNotes>
                                            
                                            {
                                                <VerseOutline title="Cross References" data={crossReferencesOutline} colorIndex={this.state.colorIndex}>

                                                </VerseOutline>
                                                // JSON.parse(crossReferences).map(x=>{return (
                                                //     <>
                                                        
                                                //     </>
                                                // )})
                                            }
                                        </div>

                                        <LargeGutter></LargeGutter>
                                        <LargeGutter></LargeGutter>

                                        {_applicationPrayer!=null?
                                        <div style={{paddingLeft:16}}>
                                            <CommentHeader>Application</CommentHeader>
                                            {/* <CommentNotes>Use this moment to pray over what you have read:  </CommentNotes> */}
                                            <CommentNotes style={{color:'grey'}}>How can we apply what we are learning here into our lives today?</CommentNotes>
                                            <CommentText style={{color:'grey'}}>{_application} {_applicationQuestions[0]}</CommentText>
                                        </div>:null}

                                        <LargeGutter></LargeGutter>
                                        <LargeGutter></LargeGutter>

                                        {_meditation!=null?
                                        <div style={{paddingLeft:16}}>
                                            <CommentHeader>Meditation:</CommentHeader>
                                            <CommentNotes>Still your mind and read the passage as if it were written as a letter to you. Pray and meditate in communion with God, perhaps considering the suggestion below:</CommentNotes>
                                            <CommentText style={{color:'grey'}}>{_meditation}</CommentText>
                                        </div>:null}
                                    </>:null}


                                    {/* <Tabs
                                            className={`customTabs customTabs-color-${this.props.colorIndex}`}
                                            style={{
                                                paddingTop: 0,
                                            }}
                                            activeKey={this.state.key}
                                            onSelect={this.handleSelect}
                                            id="uncontrolled-tab-example"
                                        >
                                            <Tab className="customTab1 p-3" eventKey="comp" title="Comprehension">
                                                {commentary!=null?
                                                    <div style={{paddingLeft:16}}>
                                                        <CommentHeader>1. Read the Passage:</CommentHeader>
                                                        <LargeGutter></LargeGutter>
                                                        <LargeGutter></LargeGutter>
                                                        <CommentHeader>2. Read Commentary:</CommentHeader>
                                                        <CommentText style={{color:'grey'}}>{commentary}</CommentText>
                                                    </div>:null}

                                                {crossReferences!=null?
                                                    <div style={{paddingLeft:16}}>
                                                        <SmallGutter></SmallGutter>
                                                        <LargeGutter></LargeGutter>
                                                        <CommentHeader>3. Check Cross References:</CommentHeader>
                                                        {
                                                            <VerseOutline title="Cross References" data={crossReferencesOutline} colorIndex={this.state.colorIndex}>

                                                            </VerseOutline>
                                                            // JSON.parse(crossReferences).map(x=>{return (
                                                            //     <>
                                                                    
                                                            //     </>
                                                            // )})
                                                        }
                                                    </div>:null
                                                }

                                                {((comprehensionQuestions!=null)&&(comprehensionQuestions.length > 0))?
                                                    <>
                                                        <LargeGutter></LargeGutter>
                                                        <LargeGutter></LargeGutter>
                                                        <div style={{paddingLeft: '16px'}}>
                                                        <CommentHeader>4. Comprehension Questions:</CommentHeader>
                                                        {comprehensionQuestions.map((question, index) => {
                                                            return (
                                                                <li>
                                                                    <CommentText key={index} style={{color: 'grey', fontSize: 16}}>
                                                                        {question}
                                                                    </CommentText>
                                                                </li>
                                                            );
                                                        })}
                                                    </div>
                                                    </>:null}
                                            </Tab>

                                            <Tab className="customTab1 p-3" eventKey="app" title="Application">
                                                
                                                {application!=null?
                                                <div style={{paddingLeft:16}}>
                                                    <CommentHeader>1. Read the Passage</CommentHeader>
                                                    <LargeGutter></LargeGutter>
                                                    <LargeGutter></LargeGutter>
                                                    <CommentHeader>2. Consider:</CommentHeader>
                                                    <CommentText style={{color:'grey'}}>{application}</CommentText>
                                                </div>:null}

                                                {applicationPrayer!=null?
                                                    <div style={{paddingLeft:16}}>
                                                        <LargeGutter></LargeGutter>
                                                        <LargeGutter></LargeGutter>
                                                        <CommentHeader>3. Application Prayer:</CommentHeader>
                                                        <CommentNotes>Use this moment to pray over what you have read, a suggested prayer is given below to help.  </CommentNotes>
                                                        <CommentText style={{color:'grey'}}>{applicationPrayer}</CommentText>
                                                    </div>:null}

                                                {((applicationQuestions!=null)&&(applicationQuestions.length > 0))?
                                                <>
                                                    <LargeGutter></LargeGutter>
                                                    <LargeGutter></LargeGutter>
                                                    <div style={{paddingLeft: '16px'}}>
                                                    <CommentHeader>4. Application Questions:</CommentHeader>
                                                    {applicationQuestions.map((question, index) => {
                                                        return (
                                                            <li>
                                                                <CommentText key={index} style={{color: 'grey', fontSize: 16}}>
                                                                {question}
                                                                </CommentText>
                                                            </li>
                                                        );
                                                    })}
                                                </div>
                                                </>:null}
                                            </Tab>

                                            <Tab className="customTab1 p-3" eventKey="med" title="Meditation">
                                            {centeringPrayer!=null?
                                                    <div style={{paddingLeft:16}}>
                                                        <LargeGutter></LargeGutter>
                                                        <LargeGutter></LargeGutter>
                                                        <CommentHeader>1. Centering Prayer:</CommentHeader>
                                                        <CommentNotes>Repeat the centering prayer below in your thoughts to quieten and still your mind ready to read the word.</CommentNotes>
                                                            <CommentText style={{color:'grey'}}>{centeringPrayer}</CommentText>
                                                    </div>:null}

                                                    {centeringPrayer!=null?
                                                    <div style={{paddingLeft:16}}>
                                                        <LargeGutter></LargeGutter>
                                                        <LargeGutter></LargeGutter>
                                                        <CommentHeader>2. Read and Listen the Scripture:</CommentHeader>
                                                        <CommentNotes>Read the scripture, it may be outloud, as if it were a letter written to you. Listen to the message of the passage, allowing the Holy Spirit to bring the passage to life.</CommentNotes>
                                                    </div>:null}

                                                {meditation!=null?
                                                    <div style={{paddingLeft:16}}>
                                                        <LargeGutter></LargeGutter>
                                                        <LargeGutter></LargeGutter>
                                                        <CommentHeader>3. Meditation:</CommentHeader>
                                                        <CommentText style={{color:'grey'}}>{meditation}</CommentText>
                                                    </div>:null}

                                                    {centeringPrayer!=null?
                                                    <div style={{paddingLeft:16}}>
                                                        <LargeGutter></LargeGutter>
                                                        <LargeGutter></LargeGutter>
                                                        <CommentHeader>4. Pray and Contemplate:</CommentHeader>
                                                        <CommentNotes> Use this time to commune with God, it may be silent or in words. </CommentNotes>
                                                    </div>:null}

                                                
                                            </Tab>
                                        </Tabs> */}
                                </ScrollArea>
                            </div>
                        </div>
                </SwipeableViews>
            </>
        );
    }
}

export default withAlert()(withRouter(BibleReading));
