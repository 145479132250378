import React, { Component } from 'react';
import CrossReferenceList from './components/Lists/CrossReferenceList';
import { LocationSingle } from './components/Lists/LocationList';
import { PersonSingle, PersonListSimple } from './components/Lists/PersonList';
import {
    BodyText, LargeGutter, SourceFootnotes, FootnoteReference,
} from './components/Core/TextComponents';
import { ThemeContext } from './components/Core/ThemeContext';
import { withAlert } from 'react-alert';
import SEO from './components/SEO'

const querystring = require('querystring');

class PeopleResult extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            data: {
                who: [],
            },
            sources: [],
            ref: '',
        };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    getPerson()
    {
        if (window.location.pathname.includes('/people'))
            return { ...querystring.parse(window.location.search.substr(1)), pathPrefix: './data/' }
        else
            return { rec: window.location.pathname.replace('/person/',''), pathPrefix: '../data/' }
    }

    componentDidMount() {
        console.log(`PagePeopleResult: ComponentDidMount: ${new Date().getSeconds()}`);
        //const values = querystring.parse(window.location.search.substr(1));
        const values = this.getPerson();
        console.log('values', values)
        const { token } = localStorage;
        this.context.loading(true);

        this.setState({ ref: values.rec });

        let endpoint = `/v1/people/known?rec=${values.rec}`;

        if (process.env.REACT_APP_AWS_API)
        {
            endpoint = window.API_BASE + endpoint;
        }

        // use local json version
        endpoint = `${values.pathPrefix}people/${values.rec}.json`

        console.log('endpoint', endpoint)

        if (endpoint !== '') {
            fetch(endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((response) => {
                    // console.log("Response: " + new Date().getSeconds());
                    console.log("response: " + JSON.stringify(response));
                    const data = { who: response.who.person };
                    const { sources } = response;

                    this.setState({
                        data, sources,
                    });
                    this.context.loading(false);
                }).catch((e)=>{
                    console.log('exception: ', e);
                    this.props.alert.show('could not connect to server');
                });
        }
    }

    componentDidUpdate() {
        console.log(`PagePeopleResult: ComponentDidUpdate: ${new Date().getSeconds()}`);

        const values = this.getPerson();

        if (this.state.ref !== values.rec) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ ref: values.rec });
            this.componentDidMount();
        }
    }

    render() {
        const why = {
            cross_references: (this.state.data.who.verses || []),
        };
        const { name } = this.state.data.who;

        document.title = name || 'ai-BIBLE';

        const searchedRec = this.getPerson().rec;
        const canonical = `https://www.ai-bible.com/person/${searchedRec}`;
        const description = '';

        return (
            <div style={{ padding: '10px' }}>
                <SEO 
                    canonical = {canonical}
                    title = { name || 'ai-BIBLE' }
                    description = { this.state?.data?.who?.dictText?.substr(0,158) || ''}
                />
                <div style={{
                    margin: 15,
                }}
                >
                    <PersonSingle
                        who={this.state.data.who}
                        title="Person of Interest"
                        sources={null}
                        footnoteRef={1}
                    />
                    <BodyText />
                    {(this.state.data.who.birthYear) ? (
                        <BodyText>
                            {' '}
                            Birth Year:
                            {this.state.data.who.birthYear}
                            {' '}
                            <FootnoteReference number={3} />
                        </BodyText>
                    ) : null}
                    {(this.state.data.who.deathYear) ? (
                        <BodyText>
                            {' '}
                            Death Year:
                            {this.state.data.who.deathYear}
                            {' '}
                            <FootnoteReference number={3} />
                        </BodyText>
                    ) : null}
                    <BodyText />
                    {(this.state.data.who.dictText) ? (
                        <BodyText>
                            {' '}
                            Dictionary Summary:
                            {this.state.data.who.dictText}
                            {' '}
                            <FootnoteReference number={2} />
                        </BodyText>
                    ) : null}
                    <BodyText />
                    <LocationSingle
                        where={this.state.data.who.birthPlace}
                        title="Place of Birth"
                        sources={null}
                        footnoteRef={1}
                    />
                    <LocationSingle
                        where={this.state.data.who.deathPlace}
                        title="Place of Death"
                        sources={null}
                        footnoteRef={1}
                    />
                    <PersonListSimple
                        who={this.state.data.who.father}
                        title="Father"
                        sources={null}
                        footnoteRef={1}
                    />
                    <PersonListSimple
                        who={this.state.data.who.mother}
                        title="Mother"
                        sources={null}
                        footnoteRef={1}
                    />
                    <PersonListSimple
                        who={this.state.data.who.partners}
                        title="Partners"
                        sources={null}
                        footnoteRef={1}
                    />
                    <PersonListSimple
                        who={this.state.data.who.siblings}
                        title="Siblings"
                        sources={null}
                        footnoteRef={1}
                    />
                    <PersonListSimple
                        who={this.state.data.who.children}
                        title="Children"
                        sources={null}
                        footnoteRef={1}
                    />
                    <BodyText />
                    <CrossReferenceList
                        why={why}
                        sources={(this.state.sources.length > 0) ? [this.state.sources[1]] : null}
                        figureNumber={1}
                    />
                    <LargeGutter />
                    <SourceFootnotes footnotes={[...this.state.sources]} footnoteStart={1} />
                </div>
            </div>
        );
    }
}

export default withAlert()(PeopleResult);
