import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreTwoTone';
import ExpandLessIcon from '@material-ui/icons/ExpandLessTwoTone';
import InfoIcon from '@material-ui/icons/Info';
import { HeaderText } from './TextComponents';
import { IconButton } from '@material-ui/core';
import { EmailOutlined } from '@material-ui/icons';
import { Button } from '@material-ui/core';

const AccordionDetailsStyle = {
    backgroundColor: '#FFF',
    display: 'block',
};

function ExpandMoreIconWhite(props) {
    return (
        <ExpandMoreIcon htmlColor={'#FFF'}></ExpandMoreIcon>
    )
}

function ColorFolder(props) {
    const [open, setOpen] = React.useState(false);
    const [ready, setReady] = React.useState(false); // attempt to delay rendering contents a little so other stuff has chance to draw
    const {
        title, children, startsOpen, transparent, immediateMounting, lightweight, info,
    } = props;

    React.useEffect(() => {
        // Update the document title using the browser API
        if (startsOpen) setOpen(true);
        setReady(true)
    }, [startsOpen]);

    const handleChange = (panel) => (event, newExpanded) => {
        console.log('handleChange', panel, newExpanded, event);
        setOpen(newExpanded);
    };

    const copyContent = (content) => {
        console.log('copyContent', content)
    }

    return (
        <>
            {(!transparent)
                ? (
                    <>
                        <Accordion
                            expanded={open}
                            onChange={handleChange('panel1')}
                            className={`background-grad-${props.colorIndex}`}

                            style={{
                                color: 'white !important',
                                width: '93%'
                            }}
                            TransitionProps={
                                { unmountOnExit: immediateMounting, mountOnEnter: immediateMounting, timeout: 100 }
                            }
                        >
                            <AccordionSummary
                                style={{
                                    color: 'white !important'
                                }}
                                // className={(lightweight)
                                //     ? 'color-text-link'
                                //     : 'color-text-link'}
                                expandIcon={(info) ? <InfoIcon color={'secondary'} style={{color:'white', cursor:'pointer'}}/> : <ExpandMoreIconWhite/>}
                            >
                                {/* <IconButton onClick={
                                        (e)=>{
                                            e.preventDefault();
                                            console.log('hello',e);
                                            handleChange('panel1')
                                        }}
                                    >
                                    <EmailOutlined htmlColor={'#FFF'} style={{marginRight:7, marginLeft:-7}}></EmailOutlined>
                                </IconButton> */}

                                <HeaderText className="color-header-text" style={{color:'black !important',paddingLeft: (props.hideBot)?12:0}}>
                                    {title}
                                </HeaderText>
                                {(props.action && props.actionLabel)?
                                <Button variant="outlined" style={{
                                        color: "#FFF",
                                        marginLeft: 'auto',
                                        borderColor: "#FFF",
                                        borderRadius: '18px',
                                        padding: '0px 10px',
                                        marginTop: '0px',
                                        marginBottom: '0px',
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        textTransform: 'lowercase',
                                        fontFamily: 'Montserrat, sans-serif'
                                    }}
                                    onClick={
                                        (e)=>{
                                            e.preventDefault();
                                            e.stopPropagation();
                                            console.log('hello',e);
                                            props.action(e);
                                        }}><>Send Daily</>
                                </Button>:null}
                            </AccordionSummary>
                            {(ready)?
                                <AccordionDetails
                                    style={AccordionDetailsStyle}
                                    className={(lightweight) ? 'background-color-white' : 'background-grad-lightgrey'}
                                >
                                    {children}
                                </AccordionDetails>:
                                null
                            }
                        </Accordion>
                    </>
                )
                : <>{ children }</>}
        </>
    );
}

export default ColorFolder;
