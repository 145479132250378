import React, { Component } from 'react';
import './App.css';
import './bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from './components/Core/ThemeContext';
import Banner from './components/Core/Banner';
import NumberGrid from './components/Input/InputGrid';
import { SubheaderText, SmallGutter, HeaderText, LargeGutter } from './components/Core/TextComponents';
import { DayCookie, StorageCookie } from './components/Storage/Cookies';
//import { Slider } from '@material-ui/core';
import Slider from './components/Input/Slider'
import DailyLocalStorage from './components/Storage/DailyLocalStorage';

const queryParams = new URLSearchParams(window.location.search);
const weightParam = parseFloat(queryParams.get('weight'));

const storage = new DailyLocalStorage('weightlog');

class WeightLog extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        const range = StorageCookie.get('range', [40,120]);
        
        const data = storage.getToday() || { 'weightToday': -1.0 };
        const weightToday = data.weightToday;

        this.state = {
            unit: 'kg',
            weightToday,
            range
        };

        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        console.log(weightParam);

        if (weightParam)
        {
            console.log('wp', weightParam);
            this.handleSliderChange(null, parseFloat(weightParam));
        }
        else if ((!this.state.weightToday)||(this.state.weightToday < 0))
        {
            console.log('wp2', weightParam, storage.getYesterday());
            if (storage.getYesterday())
            {
                this.handleSliderChange(null, parseFloat(storage.getYesterday().weightToday));
            }
        }

        this.context.loading(false);
    }

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    handleSliderChange = (event, newValue) => {
        //console.log('handleSliderChange', newValue)
        this.setState({ weightToday: newValue});
        storage.setToday({ 'weightToday': newValue });
    }

   handleRangeChange = (event, newValue) => {
        //console.log('handleRangeChange', newValue)
        this.setState({ range: newValue});
        StorageCookie.set('range', newValue, '/weight')
    };

    marks = (min, max) => { return [
        {
          value: min,
          label: min + ' kg',
        },
        {
          value: max,
          label: max + ' kg',
        },
      ];
    }
      

    render() {
        document.title = 'ai|Weight';
        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}
            >
                <Banner text="For experimental purposes only, uses cookies" />
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText>Weight Log </HeaderText>
                        <LargeGutter></LargeGutter>
                        
                        <h1 style={{border:'solid 1px lightgrey', padding:'10px'}}><strong>{this.state.weightToday + " " + this.state.unit}</strong></h1>
                        <LargeGutter></LargeGutter>
                        <SubheaderText>Set your weight</SubheaderText>
                        <LargeGutter></LargeGutter>
                        <Slider
                            getAriaLabel={() => 'Temperature range'}
                            min={this.state.range[0]}
                            max={this.state.range[1]}
                            marks={this.marks(...this.state.range)}
                            step={0.1}
                            value={this.state.weightToday}
                            onChange={this.handleSliderChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={()=>`${this.state.weightToday} kg`}
                        />
                        <LargeGutter></LargeGutter>
                        <SubheaderText>
                                Weight Range: <span>{`${this.state.range[0]} - ${this.state.range[1]}`}</span> kg
                        </SubheaderText>
                        <LargeGutter></LargeGutter>
                        <Slider
                            getAriaLabel={() => 'Temperature range'}
                            min={40}
                            max={120}
                            marks={this.marks(40,120)}
                            value={this.state.range}
                            onChange={this.handleRangeChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={()=>`${this.state.range} kg`}
                        />
                    </div> 


                    {/* <input type="number" value={this.state.weightToday} onChange={this.handleSliderChange}></input>    */}
                    {/* <NumberGrid onSelect={this.handleSelect} color={1} columns={5} numbers={[-10,-5,-1,-0.5,-0.1,0.1,0.5,1,5,10]}/>
                    <NumberGrid onSelect={this.handleClear} color={6} columns={3} numbers={['Clear','x','y']} /> */}
                </div>
            </div>
        );
    }
}

export default WeightLog;
