const bookConverters = {
    getOpacityValue: (index, subgroup) => {
        let adjIndex = index;

        switch (subgroup) {
        default:
            adjIndex = index;
            break;
        case 'Torah':
            adjIndex = index;
            break;
        case 'Historical':
            adjIndex = index - 5;
            break;
        case 'Wisdom':
            adjIndex = index - 5 - 12;
            break;
        case 'Major Prophets':
            adjIndex = index - 5 - 12 - 5;
            break;
        case 'Minor Prophets':
            adjIndex = index - 5 - 12 - 5 - 5;
            break;
        case 'The Gospels & Acts':
            adjIndex = index - 5 - 12 - 5 - 5 - 12;
            break;
        case 'Pauline Epistles':
            adjIndex = index - 5 - 12 - 5 - 5 - 12 - 5;
            break;
        case 'General Epistles':
            adjIndex = index - 5 - 12 - 5 - 5 - 12 - 5 - 13;
            break;
        }

        // eslint-disable-next-line max-len
        const adjust = [0.04, 0.25, 0.23, 0.22, 0.21, 0.18, 0.15, 0.15, 0.15, 0.14, 0.13, 0.12, 0.12, 0.11, 0.11, 0.11, 0.11];

        return 1 - (adjIndex * adjust[adjIndex] * 0.3);
    },
    convertSubGroupToIndex: (subgroup) => {
        // console.log("Subgroup", subgroup)
        switch (subgroup) {
        default:
            return 0;
        case 'The Law':
        case 'Torah':
            return 1;
        case 'Historical':
            return 2;
        case 'Wisdom':
            return 3;
        case 'Major Prophets':
            return 4;
        case 'Minor Prophets':
            return 5;
        case 'Gospel & Acts':
        case 'The Gospels & Acts':
            return 6;
        case 'Pauline Epistles':
            return 7;
        case 'General Epistles':
            return 8;
        }
    },
    isSubgroupFiltered: (selection, filter) => {
        if (selection === 'All') return false;
        if (selection === filter) return false;

        let al = selection.toLowerCase();
        const bl = filter.toLowerCase();

        if (al === bl) return false;

        if (al === 'gospel & acts') al = 'the gospels & acts';
        if (al === 'the law') al = 'torah';

        if (al === bl) return false;

        return true;
    },
};

export default bookConverters;
