import React from 'react';
import { ThemeContext } from '../Core/ThemeContext';
import { SourceFootnotes, FootnoteReference, BodyText } from '../Core/TextComponents';

class TimingList extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static contextType = ThemeContext;

    render() {
        const {
            footnoteNums, when, footnotes, footnoteStart,
        } = this.props;
        const {
            Written, BooksWritten, PeopleBorn, Events, Concerns,
        } = when;

        const footnote1 = footnoteNums[0];
        const footnote2 = footnoteNums[1];

        return (

            <div>
                {(when) ? (
                    <div>
                        {(Written) ? (
                            <BodyText>
                                {' '}
                                <b>Written:</b>
                                {' '}
                                {Written}
                                {' '}
                                <FootnoteReference number={footnote1} />
                            </BodyText>
                        ) : null}
                        {(Concerns) ? (
                            <BodyText>
                                {' '}
                                <b>Concerns Events:</b>
                                {' '}
                                {Concerns}
                                {' '}
                                <FootnoteReference number={footnote1} />
                            </BodyText>
                        ) : null}
                        {(PeopleBorn)
                            ? (
                                <div>
                                    <BodyText>
                                        {' '}
                                        <b>People born in this timeframe:</b>
                                        {' '}
                                    </BodyText>
                                    <ol style={{ margin: 0, padding: 0 }}>
                                        {
                                            PeopleBorn.map((value, index) => (
                                                <BodyText key={index}>
                                                    *
                                                    <b>{value.name}</b>
                                                    {' '}
                                                    -
                                                    {value.gender[0]}
                                                    {' '}
                                                    -
                                                    {value.description}
                                                    {' '}
                                                    <FootnoteReference number={footnote2} />
                                                </BodyText>
                                            ))
                                        }
                                    </ol>
                                </div>
                            ) : null}
                        {(Events)
                            ? (
                                <div>
                                    <BodyText><b> Events in this timeframe: </b></BodyText>
                                    <ol style={{ margin: 0, padding: 0 }}>
                                        {
                                            Events.map((value, index) => (
                                                <BodyText key={index}>
                                                    *
                                                    {value.description}
                                                    {' '}
                                                    <FootnoteReference number={footnote2} />
                                                </BodyText>
                                            ))
                                        }
                                    </ol>
                                </div>
                            ) : null}
                        {(BooksWritten)
                            ? (
                                <div>
                                    <BodyText><b> Books written in this timeframe: </b></BodyText>
                                    <ol style={{ margin: 0, padding: 0 }}>
                                        {
                                            BooksWritten.map((value, index) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <BodyText key={index}>
                                                    *
                                                    {value.description}
                                                    {' '}
                                                    <FootnoteReference number={footnote2} />
                                                </BodyText>
                                            ))
                                        }
                                    </ol>
                                </div>
                            ) : null}

                        {(footnotes) ? (
                            <BodyText>
                                <SourceFootnotes footnotes={footnotes} footnoteStart={footnoteStart} />
                            </BodyText>
                        ) : null}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default TimingList;
