import React from 'react';
import { OutlineBox, ContainerBox } from './OutlineBox';
import OutlineButtons from './OutlineButtons';
import './Outline.css';

const Outline = (props) => {
    const outline = props.outline || [];
    const title = props.title || 'Outline';
    const colorIndex = props.colorIndex || 0;
    const BookOutlineBox = ContainerBox;
    const SectionOutlineBox = OutlineBox;
    const showBookName = props.showBookName || false;

    // eslint-disable-next-line max-len
    const colorText = getComputedStyle(document.documentElement).getPropertyValue(`--color-raw-${colorIndex}`);

    return (
        <div>
            <BookOutlineBox title={title} colorIndex={colorIndex} bold>
                <div>
                    {outline.map((section, index) => {
                        console.log(section);
                        const adjMargin = (index === 0) ? 0 : 40;
                        return (
                            <SectionOutlineBox
                                style={{ marginBottom: adjMargin }}
                                title={section.title}
                                key={index}
                                colorIndex={colorIndex}
                                subsections={section.subsections}
                                stronger
                            >
                                <OutlineButtons
                                    colorText={colorText}
                                    osisRange={(section.subsections) ? null : section.osisRange}
                                    colorIndex={colorIndex}
                                    showBookName={showBookName}
                                />
                            </SectionOutlineBox>
                        );
                    })}
                </div>
            </BookOutlineBox>
        </div>
    );
};

export default Outline;
