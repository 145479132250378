import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';

const AccordionDetailsStyle = {
    backgroundColor: '#FFF',
    display: 'block',
};

function Collapsable(props) {
    const [open, setOpen] = React.useState(false);
    const [ready, setReady] = React.useState(false); // attempt to delay rendering contents a little so other stuff has chance to draw
    const {
        title, children, startsOpen, transparent, immediateMounting, lightweight, info,
    } = props;

    React.useEffect(() => {
        // Update the document title using the browser API
        if (startsOpen) setOpen(true);
        setReady(true)
    }, [startsOpen]);

    const handleChange = (panel) => (event, newExpanded) => {
        console.log('handleChange', panel, newExpanded, event);
        setOpen(newExpanded);
    };

    return (
        <>
            {(!transparent)
                ? (
                    <>
                        <Accordion
                            expanded={open}
                            onChange={handleChange('panel1')}
                            className={(lightweight)
                                ? 'background-color-white'
                                : 'background-grad-lightgrey mb-2 color-text-link'}
                            TransitionProps={
                                { unmountOnExit: immediateMounting, mountOnEnter: immediateMounting, timeout: 100 }
                            }
                        >
                            <AccordionSummary
                                className={(lightweight)
                                    ? 'color-text-link'
                                    : 'color-text-link'}
                                expandIcon={(info) ? <InfoIcon /> : <ExpandMoreIcon />}
                            >
                                {(lightweight)
                                    ? <p className="color-text-link p-0 m-0" style={{ fontSize: '0.8em' }}>{title}</p>
                                    : <strong className="color-text-link">{title}</strong>}
                            </AccordionSummary>
                            {(ready)?
                                <AccordionDetails
                                    style={AccordionDetailsStyle}
                                    className={(lightweight) ? 'background-color-white' : 'background-grad-lightgrey'}
                                >
                                    {children}
                                </AccordionDetails>:
                                null
                            }
                        </Accordion>
                    </>
                )
                : <>{ children }</>}
        </>
    );
}

export default Collapsable;
