import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ThemeContext } from './ThemeContext';
import { Container, HeaderText } from './TextComponents';
import '../../index.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AppsIcon from '@material-ui/icons/Apps';
import CloseAppsIcon from '@material-ui/icons/UnfoldLess';
import ExpandIcon from '@material-ui/icons/OpenInNew';
import ChatBot from '../Chat/ChatBot';
import { createChatBotMessage, createCustomMessage } from 'react-chatbot-kit';
import config from '../Chat/configs/chatbotConfig'
import Tooltip from '@material-ui/core/Tooltip';
import { FileCopy } from '@material-ui/icons';
import { Icon, IconButton } from '@material-ui/core';

class TitleLine extends Component {
    static contextType = ThemeContext;
    chatBotRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    initialChatbotMessage = () => {
        return [createChatBotMessage('hello',{widget:'debug'})];
    }

    componentDidMount() {
        console.log('TitleLine Props', this.props);
    }
    //
    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.handleResize);
    // }
    //
    // handleResize = () => {
    //     this.forceUpdate();
    // };

    render() {
        // let mytheme = this.context;

        if (this.props.visible) {
            return (
                <ThemeContext.Consumer>
                    {(theme) => ( // eslint-disable-line no-unused-vars
                        <>
                            <Container
                                className={`background-grad-${this.props.colorIndex}`}
                                style={{ paddingTop: 0, paddingBottom: 2, maxWidth: window.innerWidth }}
                            >
                                <Row style={{ maxWidth: window.innerWidth }}>
                                    <Col style={{ 
                                        borderRight: '1px dotted #DE979677', 
                                        minWidth: 48, 
                                        maxWidth: 55, 
                                        paddingLeft: 26,
                                        paddingBottom: 0,
                                        paddingTop: 17,
                                        marginTop: 5,
                                        paddingRight: 2,
                                        cursor: "pointer"
                                    }} 
                                    onClick={() => this.props.onclickBack()}
                                    xs={1}>
                                        <Tooltip title="Prev Chapter/Verse" placement="top-end">
                                            <ArrowBackIosIcon style={{color:this.props.hideArrows?'#00000000':this.context.background}}></ArrowBackIosIcon>
                                        </Tooltip>
                                    </Col>
                                        { (this.props.hideBot)?
                                        null:
                                        <Col style={{ 
                                            minWidth: 48, 
                                            maxWidth: 55, 
                                            paddingLeft: 12,
                                            paddingBottom: 0,
                                            paddingTop: 17,
                                            marginTop: 5,
                                            paddingRight: 2,
                                            cursor: "pointer"
                                        }} 
                                        onClick={() => {this.setState({ open: !this.state.open}); this.render(); console.log('ck: ' + this.state.open)}}
                                        xs={1}>
                                            <Tooltip title="Open/Close AI" placement="top-end">
                                                    {
                                                        (this.state.open)?
                                                        <CloseAppsIcon style={{color:this.context.background}}></CloseAppsIcon>:
                                                        <AppsIcon style={{color:this.context.background}}></AppsIcon>
                                                    }
                                            </Tooltip>
                                        </Col>
                                        }
                                    { (this.props.isNotFullChapter)?
                                        <Col style={{ 
                                            minWidth: 48, 
                                            maxWidth: 55, 
                                            paddingLeft: 5,
                                            paddingBottom: 0,
                                            paddingTop: 7,
                                            marginTop: 5,
                                            paddingRight: 0,
                                            cursor: "pointer"
                                        }} 
                                        onClick={() => this.props.onclickFull()}
                                        xs={1}>
                                            <>
                                                {
                                                    <Tooltip title="Show Complete Chapter" placement="top-end">
                                                        <IconButton>
                                                            <ExpandIcon style={{color:this.context.background}}></ExpandIcon>
                                                        </IconButton>
                                                        
                                                    </Tooltip>
                                                }
                                            </>
                                        </Col>:null
                                    }
                                    { (this.props.copyContent)?
                                        <Col style={{ 
                                            minWidth: 48, 
                                            maxWidth: 55, 
                                            paddingLeft: 5,
                                            paddingBottom: 0,
                                            paddingTop: 7,
                                            marginTop: 5,
                                            paddingRight: 0,
                                            marginRight: -7,
                                            marginLeft: -12,
                                            cursor: "pointer"
                                        }} 
                                        onClick={() => this.props.onclickCopy()}
                                        xs={1}>
                                            <>
                                                {
                                                    <Tooltip title="Copy Content" placement="top-end">
                                                        <IconButton>
                                                            <FileCopy htmlColor='#FFF' style={{color:this.context.background}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </>
                                        </Col>:null
                                    }
                                    <Col style={{
                                        display: 'float',
                                        paddingLeft: (this.props.hideBot)?12:0 ,
                                        paddingBottom:11,
                                        paddingTop: 9,
                                        marginTop: 5,
                                        paddingRight: 0,
                                        maxWidth: window.innerWidth - 50,
                                    }}
                                    >
                                        <HeaderText className="color-header-text" style={{color:'black !important',paddingLeft: (this.props.hideBot)?12:0}}>
                                            {' '}
                                            {this.props.title}
                                            {' '}
                                        </HeaderText>
                                    </Col>
                                    { (this.props.hideArrows)?null:                                    
                                    <Col style={{ 
                                        minWidth: 48, 
                                        maxWidth: 55, 
                                        paddingLeft: 22,
                                        paddingBottom: 0,
                                        paddingTop: 17,
                                        marginTop: 5,
                                        paddingRight: 2,
                                        cursor: "pointer"
                                    }} 
                                    onClick={() => this.props.onclickForward()}
                                    xs={1}>
                                        <Tooltip title="Next Chapter/Verse" placement="top-end">
                                            <ArrowForwardIosIcon style={{color:this.props.hideArrows?'#00000000':this.context.background}}></ArrowForwardIosIcon>
                                        </Tooltip>
                                    </Col>}
                                </Row>
                            </Container>
                            {(this.state.open)?<ChatBot config={config([createCustomMessage(this.props.book,'outline'), createChatBotMessage('What would you like to know about?')])}></ChatBot>:null}
                        </>
                    )}
                </ThemeContext.Consumer>
            );
        }
        return (<div />);
    }
}

export default TitleLine;
