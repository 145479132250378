import React from 'react';
const searchStyle = {
    background: '#ffffc9',
    padding: '25px',
    margin: '0px',
};

const Banner = (props) => {
    return (
        <div style={{
            background: '#fcfcfc',
        }}
        >
            <div style={searchStyle}>
                <h6
                    style={{
                        color: '#555',
                        paddingBottom: '0px',
                    }}
                >
                    { props.text }
                </h6>
            </div>
        </div>
    )
}

export default Banner;