import React, { Component } from 'react';
import './App.css';
import './bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from './components/Core/ThemeContext';
import Banner from './components/Core/Banner';
import { SubheaderText, SmallGutter, HeaderText } from './components/Core/TextComponents';

const queryParams = new URLSearchParams(window.location.search);
//onst caloriesParam = parseInt(queryParams.get('calories'));

class Empty extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            contacts: [],
            search: 'John.12.1-John.12.11',
            previoussearch: 'John.12.1-John.12.11',
            unit: 'cal',
        };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        this.context.loading(false);
    }

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    render() {
        document.title = 'ai|Emoty';
        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}
            >
                <Banner text="Empty Page Only" />
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText>Empty </HeaderText>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>this is an empty page</SubheaderText>
                        <SmallGutter></SmallGutter>
                    </div>    
                </div>
            </div>
        );
    }
}

export default Empty;
