import React, { useState } from 'react';
import { Slider, Typography, withStyles } from '@material-ui/core';

const AirbnbSlider = withStyles({
  root: {
    color: '#3A8589',
    height: 5,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 5,
  },
  rail: {
    height: 5,
    opacity: 0.7,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

const CustomRangeSlider = () => {
  const [value, setValue] = useState([20, 80]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ width: 300, margin: '0 auto' }}>
      <Typography id="range-slider" gutterBottom>
        Custom Airbnb Style Slider
      </Typography>
      <AirbnbSlider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        min={0}
        max={100}
      />
      <Typography>
        Selected range: {value[0]} - {value[1]}
      </Typography>
    </div>
  );
};

export default AirbnbSlider;