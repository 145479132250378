/* eslint-disable max-classes-per-file */
import React from 'react';
import { Chart } from 'react-google-charts';
import { Table, Row, Col } from 'react-bootstrap';

import {
    RadialChart,
} from 'react-vis';
import {
    SourceCredits, BodyText, SubheaderText, SmallGutter, SourceFigure,
} from '../Core/TextComponents';
import { ThemeContext } from '../Core/ThemeContext';

class SentimentChart extends React.Component {
    static contextType = ThemeContext;

    render() {
        console.log('Sentiment', this.props.sentences);

        const sentiments = [];

        if (this.props.sentences) {
            this.props.sentences.sentences.forEach((sentence) => {
                const element = sentence.sentiment;
                sentiments.push([element[0].split('.')[2], element[1], `${element[0]}: ${element[2]}`]);
            });
        }

        return (
            (this.props.sentences) ? (
                <div>
                    <SubheaderText id="example-collapse-text">
                        Sentiment:
                    </SubheaderText>
                    <Chart
                        width="100%"
                        height="300px"
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['verse', 'sentiment', { role: 'tooltip', type: 'string', p: { html: true } }],
                        ].concat(sentiments)}
                        options={{
                            hAxis: {
                                title: 'Verse',
                            },
                            vAxis: {
                                title: 'Positivity',
                                viewWindow: { min: -1, max: 1 },
                            },
                            legend: 'none',
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                    <SmallGutter />
                    <SmallGutter />
                    <Table variant="" size="sm" responsive="sm" striped="true">
                        <thead>
                            <tr>
                                <th>Stat</th>
                                <th>Verse</th>
                                <th>Sentiment</th>
                                <th>Text</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><BodyText><b>Most Positive</b></BodyText></td>
                                <td><BodyText>{this.props.details.stats.mostPositive[0]}</BodyText></td>
                                <td><BodyText>{this.props.details.stats.mostPositive[1]}</BodyText></td>
                                <td><BodyText>{this.props.details.stats.mostPositive[2]}</BodyText></td>
                            </tr>
                            <tr>
                                <td><BodyText><b>Least Positive</b></BodyText></td>
                                <td><BodyText>{this.props.details.stats.leastPositive[0]}</BodyText></td>
                                <td><BodyText>{this.props.details.stats.leastPositive[1]}</BodyText></td>
                                <td><BodyText>{this.props.details.stats.leastPositive[2]}</BodyText></td>
                            </tr>
                            <tr>
                                <td><BodyText><b>Average</b></BodyText></td>
                                <td><BodyText>{this.props.details.stats.average[0]}</BodyText></td>
                                <td><BodyText>{this.props.details.stats.average[1]}</BodyText></td>
                                <td><BodyText>{this.props.details.stats.average[2]}</BodyText></td>
                            </tr>
                        </tbody>
                    </Table>
                    {(this.props.figureNumber) ? (
                        <SourceFigure
                            sources={this.props.sources}
                            figureNumber={this.props.figureNumber}
                            // eslint-disable-next-line max-len
                            figureName="Sentiment chart indicating emotional positiveness vs negativeness of the words within each verse."
                        />
                    ) : null}
                    {/* {(this.props.sources)?<SourceCredits sources={this.props.sources}/>:null} */}
                </div>
            ) : null
        );
    }
}

class SentimentGuage extends React.Component {
    static contextType = ThemeContext;

    render() {
        // console.log("Sentiment", this.props.sentence);

        // var verbs = [];
        let sentiment = 50;
        if (this.props.sentence.sentiment) {
            sentiment += (this.props.sentence.sentiment[1] * 50);
        }

        let sentimentText = 'neutral';

        if (sentiment >= 57) sentimentText = 'positive';

        if (sentiment < 45) sentimentText = 'negative';

        // const positivity = (1 + this.props.sentence.sentiment[1]) / 2;
        let color = '#CCCCFF';

        const magnitude = Math.abs(this.props.sentence.sentiment[1]);

        if (sentiment < 45) color = '#FFAAAA';
        if (sentiment < 30) color = '#FF4444';

        if (sentiment >= 57) color = '#AAFFAA';
        if (sentiment > 70) color = '#44FF44';

        const chartdata = [
            {
                angle: 0,
                color: '#FFFFFF',
            },
            {
                angle: magnitude,
                color,
            },
            {
                angle: 1 - magnitude,
                color: '#EEEEEE',
            },
        ];

        return (
            (this.props.sentence.sentiment) ? (
                <div>
                    <SubheaderText id="example-collapse-text">
                        Sentiment:
                    </SubheaderText>
                    <Row>
                        <Col md={3}>
                            <div style={{ width: 150, height: 150, position: 'relative' }}>
                                <RadialChart
                                    colorType="literal"
                                    data={chartdata}
                                    width={150}
                                    height={150}
                                    radius={60}
                                    innerRadius={30}
                                    padAngle={0.04}
                                />
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zindex: 10,
                                }}
                                >
                                    <div style={{ height: '100%' }}>
                                        <p style={{
                                            margin: 'auto',
                                            textAlign: 'center',
                                            verticalAlign: 'middle',
                                            position: 'absolute',
                                            top: 62,
                                            left: 58,
                                        }}
                                        >
                                            {`${sentiment.toPrecision(2)}%`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={9}>
                            <BodyText>
                                ai-BIBLE believes the emotional tone of the text to be
                                {' '}
                                <b>{sentimentText}</b>
                                {' '}
                                {/* eslint-disable-next-line max-len */ }
                                based on the average positive / negative score for each word in the sentence(s).
                            </BodyText>
                        </Col>
                    </Row>

                    <SmallGutter />
                    <SmallGutter />

                    {(this.props.sources) ? <SourceCredits sources={this.props.sources} /> : null}
                </div>
            ) : null
        );
    }
}

export {
    SentimentChart,
    SentimentGuage,
};
