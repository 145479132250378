import React, { Component } from 'react';
import './App.css';
import './bootstrap-4/css/bootstrap.min.css';
import BookCard from './components/Cards/BookCard';
import {
    LargeGutter, SmallGutter, SourceFootnotes, SourcePermissions, SourceBibliography, SourceCredits,
} from './components/Core/TextComponents';
import { ThemeContext } from './components/Core/ThemeContext';
import booksData from './data/books.json';
import BibleBooksColorStrip from './components/BibleBooksColorStrip';
import bookConverters from './utilities/bookConversions';
import PageHome from './PageHome';
import Collapsable from './components/Core/Collapsable';
import Banner from './components/Core/Banner';

class BrowseAllBooks extends Component {
    static contextType = ThemeContext;

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    static buildSummary(bookInfo) {
        if (bookInfo.summary === 'TBC') return bookInfo.Summary;
        if (!bookInfo.summary) return bookInfo.Summary;
        if (bookInfo.summary.length === 0) return bookInfo.Summary;

        return bookInfo.summary;
    }

    constructor() {
        super();

        this.state = {
            books: [],
            sources: [],
            selectedSubgroup: 'All',
        };

        this.displaySearch = this.displaySearch.bind(this);
        this.setSelectedSubgroup = this.setSelectedSubgroup.bind(this);
        this.handleSelectSubGroup = this.handleSelectSubGroup.bind(this);
    }

    componentDidMount() {
        this.context.loading(true);
        // const { token } = localStorage;
        // const current = this;
        window.show_footer = true;

        this.displaySearch(booksData);
        document.body.style.overflowX = "hidden";
    }

    handleSelectSubGroup(item, booksItem) {
        console.log('handleSelectSubGroup: ', item, booksItem, this);
        this.setSelectedSubgroup(item);
    }

    setSelectedSubgroup(subgroup) {
        this.setState({ selectedSubgroup: subgroup });
    }

    displaySearch(searchData) {
        let data = searchData;
        console.log('PageBrowseAllBooks: displaySearch:', data);
        const output = {
            books: [],
            sources: [],
        };

        if (typeof data === 'string' || data instanceof String) {
            try {
                data = JSON.parse(data);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.err(e);
                return;
            }
        }

        if (data.version < 3) {
            output.sources = data.sources;
            Object
                .keys(data.data)
                .forEach((key) => {
                    const entry = {
                        value: data.data[key].Name,
                        bookname: data.data[key].Name,
                        bookcode: `${key}.1`,
                        img: `/images/${data.data[key].BookCode}.jpg`,
                        summary: BrowseAllBooks.buildSummary(data.data[key]),
                        subgroup: data.data[key].Subgroup,
                        group: data.data[key].Group,
                        outline: data.data[key].sections,
                        // eslint-disable-next-line max-len
                        footnote: (BrowseAllBooks.buildSummary(data.data[key]) === data.data[key].Summary) ? 1 : null,
                    };

                    // console.log('PageBrowseAllBooks: Output: ', entry.bookcode, data, data.data[key]);

                    output
                        .books
                        .push(entry);
                });
        }

        this.setState({ books: output.books, sources: output.sources });
        this.context.loading(false);
    }

    render() {
        document.title = 'ai|Home';
        return (
            <div style={{maxWidth:'100vw', width:"100vw"}}>
                <div>
                    {/* <Banner text="Latest: A new chat feature has been added, it's very early days, but give it a go and let me know how it went." /> */}
                    <BibleBooksColorStrip
                        className=""
                        style={{ position: 'fixed', top: 0 }}
                        onSelect={this.handleSelectSubGroup}
                    />
                    {(this.state.selectedSubgroup === 'All')
                        ? <PageHome /> : null}
                    <ol
                        className=""
                        style={{
                            padding: 1,
                        }}
                    >
                        {this
                            .state
                            .books
                            .map((value, index) => (
                                // eslint-disable-next-line max-len
                                (!bookConverters.isSubgroupFiltered(this.state.selectedSubgroup, value.subgroup))
                                    ? (
                                        <BookCard
                                            size="93"
                                            value=""
                                            index={index}
                                            src={value.img}
                                            key={index}
                                            detailsheader={value.bookname}
                                            details={value.summary}
                                            detailsFootnote={value.footnote}
                                            link={value.bookcode}
                                            group={value.group}
                                            subgroup={value.subgroup}
                                            outline={value.outline}
                                        />
                                    ) : null
                            ))}
                    </ol>

                    <Collapsable
                        title="Footnotes, Sources and Credits"
                        immediateMounting
                        lightweight
                        info
                    >
                        <LargeGutter />
                        <SourceFootnotes
                            // eslint-disable-next-line max-len
                            footnotes={this.state.sources}
                            footnoteStart={0}
                        />
                        <LargeGutter />
                        <SourceBibliography
                            // eslint-disable-next-line max-len
                            bibliography={this.state.sources}
                        />
                        <LargeGutter />
                        <SourcePermissions
                            // eslint-disable-next-line max-len
                            sources={this.state.sources}
                        />
                    </Collapsable>
                    <LargeGutter />
                    <a href="/sitemap" style={{marginLeft:'48%', marginRight:'100%'}}>Sitemap</a>
                </div>
            </div >
        );
    }
}

export default BrowseAllBooks;
