import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone';
import { ThemeContext } from '../Core/ThemeContext';
import { FootnoteReference } from '../Core/TextComponents';
import '../../index.css';
import './BookCard.css';
import bookConverters from '../../utilities/bookConversions';
import Outline from '../Outlines/Outline';

import ColorTab from '../Core/ColorTab';

class BookCard extends Component {
    // eslint-disable-next-line
    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = { open: false, fillOutline: false };
        this.handleSelect = this.handleSelect.bind(this);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    handleSelect(tab) {
        // eslint-disable-next-line no-console
        console.log(`BookCard: Selected: ${tab} ${this}`);
    }

    handleOpenClose() {
        this.setState((prevState) => ({ open: !prevState.open, fillOutline: true }));
    }

    render() {
        const { open, fillOutline } = this.state;
        const { scriptfont } = this.context;
        const {
            outline,
            index,
            detailsheader,
            details,
            detailsFootnote,
            subgroup,
        } = this.props;
        const colorIndex = bookConverters.convertSubGroupToIndex(subgroup);

        return (
            <div style={{ margin: 0, padding: 0, borderBottom: '1px solid #a9a9a919' }}>
                <div
                    role="button"
                    tabIndex={0}
                    className="silly"
                    style={{
                        display: 'flex',
                        width: '100%',
                        minWidth: '100%',
                        margin: 0,
                        padding: 0,
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                    }}
                    onClick={() => { this.handleOpenClose(); }}
                    onKeyPress={this.handleKeyPress}
                >
                    <div className="card-size" style={{ float: 'left', height: '100%' }}>
                        <ColorTab
                            index={index}
                            subgroup={subgroup}
                            colorIndex={colorIndex}
                            width={20}
                        />
                    </div>
                    <div
                        className="centre-card-size"
                        style={{
                            display: 'inline-block', paddingLeft: 20, paddingRight: 20, width: '100%',
                        }}
                    >
                        <h4
                            className="mb-1 responsive-bookcard-header"
                            variant="h6"
                            style={{ fontFamily: scriptfont }}
                            component="h3"
                        >
                            {detailsheader}
                        </h4>
                        <p
                            className="bookcard-description"
                            style={{
                                color: '#667',
                                fontSize: '0.8em',
                                overflow: 'auto',
                                whiteSpace: 'break-spaces',
                                wordWrap: 'break-word',
                                width: '100%',
                                margin: 0,
                            }}
                        >
                            {details}
                            {(detailsFootnote)
                                ? (
                                    <FootnoteReference
                                        number={detailsFootnote}
                                        nolink
                                    />
                                ) : null}
                        </p>
                    </div>
                    <div
                        className="color-7 centre-card-size"
                        style={{
                            cursor: 'pointer', maxWidth: 48, width: 48, float: 'right',
                        }}
                    >
                        {open ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
                    </div>
                </div>

                <Collapse in={open}>
                    <div style={{ position: 'relative' }}>
                        <Outline
                            outline={fillOutline ? outline : null}
                            title={detailsheader}
                            colorIndex={colorIndex}
                        />
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default BookCard;
