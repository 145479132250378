import React, { Component } from 'react';
import Iframe from 'react-iframe';
import CrossReferenceList from './components/Lists/CrossReferenceList';
import { LocationSingle } from './components/Lists/LocationList';
import {
    BodyText, SubheaderText, SourceFootnotes, FootnoteReference, LargeGutter,
} from './components/Core/TextComponents';
import { ThemeContext } from './components/Core/ThemeContext';

const querystring = require('querystring');
import { withAlert } from 'react-alert';

import SEO from "./components/SEO";

class PlaceResult extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            data: {
                where: [],
            },
            sources: [],
            ref: '',
        };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    getPlace()
    {
        if (window.location.pathname.includes('/place'))
            return { ...querystring.parse(window.location.search.substr(1)), pathPrefix: './data/' }
        else
            return { rec: window.location.pathname.replace('/location/',''), pathPrefix: '../data/' }
    }

    componentDidMount() {
        console.log(`PagePlacesResult: ComponentDidMount: ${new Date().getSeconds()}`);
        const values = this.getPlace();
        const { token } = localStorage;
        
        this.context.loading(true);

        this.setState({ ref: values.rec });

        let endpoint = `/v1/places/known?rec=${values.rec}`;

        if (process.env.REACT_APP_AWS_API)
        {
            endpoint = window.API_BASE + endpoint;
        }

        // use local json version
        endpoint = `${values.pathPrefix}places/${values.rec}.json`

        if (endpoint !== '') {
            fetch(endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((response) => {
                    const data = { where: response.where.place };
                    const { sources } = response;

                    this.setState({
                        data, sources,
                    });

                    this.context.loading(false);
                }).catch((e)=>{
                    console.log('exception: ', e);
                    this.props.alert.show('could not connect to server');
                });
        }
    }

    componentDidUpdate() {
        console.log(`PagePlacesResult: ComponentDidUpdate: ${new Date().getSeconds()}`);

        const values = this.getPlace();

        console.log('compare', this.state.ref, values.rec);
        if (this.state.ref !== values.rec) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ ref: values.rec });
            this.comgponentDidMount();
        }
    }

    render() {
        const { name } = this.state.data.where;
        document.title = name || 'ai-BIBLE';

        const searchedRec = this.getPlace().rec;
        const canonical = `https://www.ai-bible.com/location/${searchedRec}`;

        // const noteCounter = (sources) => {
        //     let count = 0;

        //     if (sources == null) return notesCounted;
        //     if (sources === []) return notesCounted;

        //     for (let i = 0; i < sources.length; i += 1) {
        //         const source = sources[i];
        //         if (source) {
        //             if (source === 1) {
        //                 count += 1;
        //             } else {
        //                 for (let j = 0; j < source.length; j += 1) {
        //                     const note = source[j];
        //                     if (note) {
        //                         if (Object.prototype.hasOwnProperty.call(note, 'footnote')) count += 1;
        //                     }
        //                 }
        //             }
        //         }
        //     }

        //     notesCounted += count;
        //     return notesCounted;
        // };

        const description = (this.state.data.where.dictText)?this.state?.data?.where?.dictText:'';

        const why = {
            cross_references: (this.state.data.where.verses || []),
        };

        return (
            <div style={{ padding: '10px' }}>
                <SEO 
                    canonical = {canonical}
                    title = { name || 'ai-BIBLE' }
                    description = { description.substr(0,158) || ''}
                />
                <div style={{
                    margin: 15,
                }}
                >
                    <LocationSingle
                        where={this.state.data.where}
                        title={this.state.data.where.name}
                        footnoteRef={1}
                    />
                    <BodyText />
                    <SubheaderText>Map</SubheaderText>
                    <Iframe
                        width="425"
                        height="350"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src="https://www.openstreetmap.org/export/embed.html?bbox=35.00312805175782%2C31.65747280265317%2C35.49407958984376%2C31.915450349851064&amp;layer=cyclemap&amp;marker=31.786551553613997%2C35.24860382080078"
                    />
                    <br />
                    <small><a href="https://www.openstreetmap.org/?mlat=31.7866&amp;mlon=35.2486#map=12/31.7866/35.2486&amp;layers=C">View Larger Map</a></small>
                    <br />
                    <LargeGutter />
                    {(this.state.data.where.dictText)
                        ? (
                            <BodyText>
                                {' '}
                                <b>Dictionary Summary:</b>
                                {' '}
                                {this.state.data.where.dictText}
                                {' '}
                                <FootnoteReference number={2} />
                            </BodyText>
                        )
                        : null}
                    <LargeGutter />
                    <CrossReferenceList
                        why={why}
                        sources={(this.state.sources.length > 0) ? [this.state.sources[1]] : null}
                        figureNumber={1}
                    />
                    <LargeGutter />
                    <SourceFootnotes footnotes={[...this.state.sources]} footnoteStart={1} />
                </div>
            </div>
        );
    }
}

export default withAlert()(PlaceResult);
