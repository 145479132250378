import React from 'react';
import ColorStrip from './Core/ColorStrip';

const BibleBooksColorStrip = (props) => {
    const books = [
        'The Law',
        'Historical',
        'Wisdom',
        'Major Prophets',
        'Minor Prophets',
        'Gospel & Acts',
        'Pauline Epistles',
        'General Epistles',
    ];
    const { onSelect } = props;

    const handleSelect = (item) => {
        if (onSelect) {
            onSelect(item, books.indexOf(item));
        } else {
            console.error('expected onSelect handler');
        }

        console.log('BibleBooksColorStrip: Selected: ', item);
    };

    return (
        <div>
            <ColorStrip onSelect={handleSelect} items={books} />
        </div>
    );
};

export default BibleBooksColorStrip;
