import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const DayCookie = {
    set: (key, value, path = '/hello') => {
        console.log(`Setting cookie: ${key}=${value}; path=${path}`);
        Cookies.set(key, value, { path, samesite:'Strict', expires: DayCookie.getEndOfDayExpiration() / (1000 * 60 * 60 * 24) });
    },

    get: (key) => {
        return Cookies.get(key);
    },

    getEndOfDayExpiration: () => {
        const now = new Date();
        const midnight = new Date();
        midnight.setHours(23, 59, 59, 999); // Set to the end of the current day
        return midnight.getTime() - now.getTime();
    }
}

const StorageCookie = {
    set: (key, value, path = '/hello') => {
        console.log(`Storage cookie set: ${key}=${value}; path=${path}`);
        Cookies.set(key, JSON.stringify(value), { path, samesite:'Strict'});
    },

    get: (key, defaultValue = "") => {
        console.log('Storage Cookie get', key, defaultValue)
        const value = Cookies.get(key) || 'null';
        return JSON.parse(value) || defaultValue
    },
}

export { 
    DayCookie, 
    StorageCookie 
};