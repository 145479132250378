import React from 'react';

const BibleVerse = (props) => {
    console.log('BibleVerse', props);
    return (
        <p>
            {props.data.text}
        </p>
    );
};

export default BibleVerse;
