class MessageParser {
    constructor(actionProvider, state) {
        this.actionProvider = actionProvider;
        this.state = {
            ...state,
            network: true
        };

        // console.log('adding listeners...');
        // Network.addListener('networkStatusChange', status => {
        //     console.log('network status changed: ', status.connected);
        //     this.setState({network: status.connected})
        // });
    }

    async parse(message) {
        console.log('Received Question: ', message);
        
        // if (!window.web_version && !network)
        // {
        //     this.props.alert('No internet connection');
        //     return;
        // }

        this.actionProvider.handleQuestion(message);
    }
}

export default MessageParser;