import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ThemeContext } from './components/Core/ThemeContext';

class ContactUs extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            email: '',
            body: '',
            subject: '',
            app: 'ai-BIBLE'
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        this.context.loading(false);
    }

    componentWillUnmount() {
        console.log('Will Unmount');
    }

    onSubmit(e, d) {
        e.preventDefault();
        console.log({'state': this.state})
        console.log(window.API_BASE);

        const endpoint = window.API_BASE + "/v1/chat/email";
        const payload = {...this.state}
        console.log({payload});

        fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                console.log('res',res);

                if (res.status > 200)
                {
                    throw 'please check email address';
                }

                return res.text()
            })
            .then((resp) => {
                if (!resp.startsWith('Email'))
                    throw 'Invalid Response'

                console.log(`response time: ${new Date().getSeconds()}`);
                console.log('response', resp);


                this.setState({email:'',name:'', message:''});

                alert('Email sent!');

            }).catch((e)=>{
                console.log({e})
                console.log(e.toString())
                alert('Error: ' + e.toString())
            });

            console.log('final');

    }

    render() {
        
        document.title = 'ai|Contact';
        // return(<li key={index}>{index}:{value}</li>);
        return (
            <div style={{ padding: 25 }}>
                <br />
                <h4>Contact</h4>
                <br />
                <p>
                    ai BIBLE is intended to be a bible that both utilises artificial intelligence and facilitates application and interpretation. It is currently using natural language processing and machine learning. The project aims to be free for providing materials that are free / public domain and is currently unfunded. If you have any questions then get in touch on the form below:
                </p>
                <form id="ajax-contact">
                    <div className="field input_field">
                        <label htmlFor="name" className="input_label">Name:</label>
                        <input type="text" className="input_text" id="name" name="name" required value={this.state.subject} onChange={(e)=>{this.setState({'subject':e.target.value})}}/>
                    </div>

                    <div className="field input_field">
                        <label htmlFor="email" className="input_label">Email:</label>
                        <input type="email" id="email" name="email" className="input_text" required value={this.state.email} onChange={(e)=>{this.setState({'email':e.target.value})}}/>
                    </div>

                    <div className="field">
                        <label htmlFor="message" className="input_label" style={{ textAlign: 'left', verticalAlign: 'top' }}>Message:</label>
                        <textarea id="message" name="message" className="input_textarea" required value={this.state.body} onChange={(e)=>{this.setState({'body':e.target.value})}}/>
                    </div>

                    <ReCAPTCHA
                        sitekey="6LfrH9MUAAAAAFe13mga-k3s6sF7tzi6krOreESP"
                    />

                    <div className="field input_field">
                        <label className="input_label" />
                        <button type="button" onClick={(e, d)=>{this.onSubmit(e, d)}} className="input_button">Send</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default ContactUs;
