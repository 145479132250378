import React from 'react';

export const themes = {
    light: {
        foreground: '#000000C0',
        background: '#fff',
        softlink: '#81ABAF99',
        softtext: '#000000A0',
        disabledtext: '#bbb',
        softwell: '#81ABAF33',
        highlight: '#3a7',
        lighttext: '#777',
        scriptfont: 'Dancing Script, Georgia',
        color1: 'rgba(242, 196, 56, 1.0)',
        color2: 'rgba(242, 154, 46, 1.0)',
        color3: 'rgba(242, 89, 54, 1.0)',
        color4: 'rgba(242, 29, 47, 1.0)',
        color5: 'rgba(170, 0, 28, 1.0)',
        color6: 'rgba(95, 205, 217, 1.0)',
        color7: 'rgba(60, 112, 145, 1.0)',
        color8: 'rgba(32, 44, 89, 1.0)',
        colorpink: 'rgba(221, 114, 235, 1.0)',
        colorblue: 'rgba(72, 157, 255, 1.0)',
        colorgrey: 'rgba(222, 222, 222, 1.0)',
        colorlightgrey: 'rgba(238, 240, 243, 1.0)',
        loading: (loading) => { console.log('Theme: default loading', loading); },
    },
    dark: {
        foreground: '#ffffff',
        background: '#222222',
        softlink: '#81ABAFFF',
        softtext: '#ccc',
        softwell: '#81ABAFFF',
        disabledtext: '#888',
        highlight: '#3a7',
        lighttext: '#aaa',
        scriptfont: 'Parisienne',
        loading: (loading) => { console.log('Theme: default loading', loading); },
    },
};

export const ThemeContext = React.createContext(
    themes.light, // default value
);
