/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import ResponseHandler from '../chatbot/ResponseHandler';
import Outline from '../../Outlines/Outline';
import books from '../../../data/books';
import bookConverters from '../../../utilities/bookConversions';

const config = (initialMessages = [{...createChatBotMessage('Hello, ask me about something in the bible!'), timestamp: new Date().getTime()}]) => { return {
    botName: 'ai-BIBLE',
    initialMessages: initialMessages,
    widgets: [
        {
            widgetName: 'response',
            widgetFunc: (props) => <ResponseHandler {...props} />,
            mapStateToProps: ['question', 'answer'],
        },
    ],
    customMessages: {
        outline: (props) => <Outline outline={[...books.booksData.data[props.state.messages[0].message].sections]} colorIndex={bookConverters.convertSubGroupToIndex(books.booksData.data[props.state.messages[0].message].Subgroup)}></Outline>,
    },
    state: {
        question: "",
        answer: undefined,
    },
}};

export default config;
