
import React, { useState } from 'react';
import { Button, ButtonGroup, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

// props { buttons: ['a','b','c'], selected: '', onSelect: (value)=> {}}
const RadioButtonGroup = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(props.selected);

  const handleClick = (value) => {
    setSelected(value);
    props.onSelect(value);
  };

  return (
    <ButtonGroup>
      {props.buttons.map((key) => (
        <Button
          key={key}
          onClick={() => handleClick(key)}
          style={{ textTransform: 'none' }}
          className={`${classes.button} ${selected === key ? classes.selectedButton : ''}`}
        >
          {key.charAt(0).toUpperCase() + key.slice(1)}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default RadioButtonGroup;