import React from 'react';
import bookConverters from '../../utilities/bookConversions';

const ColorTab = (props) => {
    const {
        index, colorIndex, subgroup, height, width,
    } = props;

    // console.log('ColorTab: ', subgroup, props);

    const style = {
        pointerEvents: 'none',
        display: 'inline-block',
        opacity: bookConverters.getOpacityValue(index, subgroup),
    };

    if (height) {
        style.maxHeight = height;
        style.minHeight = height;
    }

    if (width) {
        style.maxWidth = width;
        style.minWidth = width;
    }

    return (
        <img
            className={`background-grad-${colorIndex} card-size`}
            alt=""
            style={style}
        />
    );
};

export default ColorTab;
