import { Helmet } from 'react-helmet';
import React, { Component } from 'react';
import { ThemeContext } from './components/Core/ThemeContext';
import Banner from './components/Core/Banner';
import ColorFolder from './components/Core/ColorFolder';
import PlanOutline from './components/Outlines/PlanOutline';
import { BodyText, SubheaderText, CommentHeader, CommentText, LargeGutter, SmallGutter } from './components/Core/TextComponents';
import * as importedPlans from './plans/plans.json';
import Devotionals from './plans/Devotionals';
import { Dialog, DialogActions, TextField, DialogTitle, DialogContent, DialogContentText, Button } from '@material-ui/core';
const { bcv_parser } = require('bible-passage-reference-parser/js/en_bcv_parser');
const bcv = new bcv_parser();
bcv.set_options({ consecutive_combination_strategy: 'separate', sequence_combination_strategy: 'separate' });

class Verify extends Component {
    static contextType = ThemeContext;

    getCode()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('code');
    }

    getEmail()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('email');
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    constructor() {
        super();

        this.state = {
            verified: 'verification in progress...'
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.context.loading(false);
        
        if (this.state.verified == 'verification in progress...')
            this.verify();
    }

    async verify() {
        // 1. get data and set endpoint
        const email = this.getEmail();
        const subscriptionCode = this.getCode();

        if (!email || !subscriptionCode)
            this.setState({verified: 'Verification failed, invalid link!'})

        const { token } = localStorage;
        this.context.loading(true);
        let endpoint = `/v1/devotionals/verify`;
        if (process.env.REACT_APP_AWS_API)
        {
            endpoint = window.API_BASE + endpoint;
        }
        else
        {
            endpoint = "http://localhost:5001" + endpoint;
        }

        // 2. send email + devotional id to server
        console.log('endpoint', endpoint);
        console.log('email', email);

        if (endpoint !== '') {
            await fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    subscriptionCode
                }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
                .then((res) => res.json())
                .then((response) => {
                    console.log({response});

                    if (response.error)
                        throw response.error;

                    console.log("response: " + response.subscriptionCode);
                    this.setState({verified: 'Your email address has been verified, a starting email should arrive within 5 minutes.'})
                }).catch((e)=>{
                    console.log('exception: ', e);
                    this.setState({verified: 'Verification failed: ' + e})
                });
        }

        this.setState({open: false});
    }

    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    render() {
        return (
            <>
                <Banner text="Devotionals under construction"></Banner>
                
                <LargeGutter></LargeGutter>
                <SubheaderText>&nbsp;&nbsp;{this.state.verified}</SubheaderText>
            </>
        );
    }
}

export default Verify;