import React from 'react';
import './OutlineButtons.css';
import '../../colors.css';
import osisConverters from '../../utilities/osisConversions';
import { Capacitor } from '@capacitor/core';

// const deconstructOsisRange = (osisRange) => {
//     const [osisRefStart, osisRefEnd] = osisRange.split('-');
//     const [osisBookStart, osisChapterStart, osisVerseStart] = osisRefStart.split('.');
//     const [osisBookEnd, osisChapterEnd, osisVerseEnd] = osisRefEnd.split('.');

//     return {
//         osisRefStart,
//         osisRefEnd,
//         osisBookStart,
//         osisChapterStart,
//         osisVerseStart,
//         osisBookEnd,
//         osisChapterEnd,
//         osisVerseEnd,
//     };
// };

const OutlineButtons = (props) => {
    const range = [];
    const { osisRange } = props;
    const colorIndex = props.colorIndex || 0;
    let book = 'Gen';
    let chapter = 0;

    if (osisRange) {
        const {
            osisBookStart,
            osisChapterStart,
            osisChapterEnd,
        } = osisConverters.deconstructOsisRange(osisRange);

        // eslint-disable-next-line radix
        for (let i = parseInt(osisChapterStart); i <= parseInt(osisChapterEnd); i += 1) {
            range.push(i);
        }

        chapter = osisChapterStart;
        book = osisBookStart;
    }
    
    return (
        <div className="outline-buttons">
            {
                range.map((button, index) => ( 
                    <a
                        className={`color-${colorIndex} outline-button`}
                        key={index}
                        href={window.getVerseURL(book +'.' + button)}
                        target={ (window.web_version)?"_blank":"_self" } 
                        onClick={() => { console.log('OutlineButton: Click: ', index, ` /read/${book}.${button}`); }}
                    >
                        <p className="outline-button-text">
                            {(props.showBookName)?`${book} ${chapter}:${button}`:button}
                        </p>
                    </a>
                ))
            }
        </div>
    );
};

export default OutlineButtons;
