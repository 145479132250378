import * as importedPlans from './plans.json';
const { bcv_parser } = require('bible-passage-reference-parser/js/en_bcv_parser');
const bcv = new bcv_parser();

const Devotionals = {
    createDevotionalIndex: (plan, stage, item) => {
        return [ plan, stage, item];
    },
    getDevotional: (devotional) => {
        const [ plan, stage, item ] = devotional;
        const formattedPlans = Devotionals.getFormattedPlans();
        console.log(plan, stage, item, formattedPlans[plan].stages[stage].passages[item])
        return formattedPlans[plan].stages[stage].passages[item];
    },
    getFormattedPlans:() => {
        const keys = Object.keys(importedPlans).filter(x => x !== 'default');

        const formattedPlans = keys.map(plan => {
            const currentPlan = importedPlans[plan];
            const formattedStages = currentPlan.stages.map((stage, stageIndex) => {
                const formattedVerses = stage.passages.map((section, sectionIndex) => {
                    let osisRange;
                    try {
                        osisRange = section.verses.includes('-') ? bcv.parse(section.verses).osis() : `${bcv.parse(section.verses).osis()}-${bcv.parse(section.verses).osis()}`;
                        
                        if (osisRange.includes('James'))
                            osisRange = osisRange.replace('James','Jas').replace('James','Jas');
                        if (osisRange.includes('Psalm'))
                            osisRange = osisRange.replace('Psalm','Ps').replace('Psalm','Ps');
                        
                    } catch (error) {
                        osisRange = '';
                    }
                    try {
                        section.cross_references = section.cross_references.map(
                            (xref)=>{
                                return xref.replace('James','Jas').replace('James','Jas').replace('Psalm','Ps').replace('Psalm','Ps');
                            }
                        )
                    } catch (error) {
                        section.cross_references = '';
                    }

                    return {
                        osisRange,
                        title: section.title,
                        commentary: section.commentary,
                        application: section.application,
                        applicationQuestions: section.application_questions,
                        comprehensionQuestions: section.comprehension_questions,
                        centeringPrayer: section.centering_prayer,
                        meditation: section.meditation,
                        applicationPrayer: section.application_prayer,
                        crossReferences: section.cross_references,
                        devotional: Devotionals.createDevotionalIndex(plan, stageIndex, sectionIndex)
                    };
                });

                return {
                    title: stage.title,
                    passages: formattedVerses
                };
            });

            return {
                plan,
                stages: formattedStages,
                summary: currentPlan.summary,
                questions: currentPlan.overarchingquestions || currentPlan.overarching_questions
            };
        });

        const formattedPlansObject = formattedPlans.reduce((acc, curr) => {
            acc[curr.plan] = {
                stages: curr.stages,
                summary: curr.summary,
                questions: curr.questions
            };
            return acc;
        }, {});

        return formattedPlansObject;
    }
}


export default Devotionals;