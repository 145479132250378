import { Helmet } from 'react-helmet';
import React, { Component } from 'react';
import { ThemeContext } from './components/Core/ThemeContext';
import Banner from './components/Core/Banner';
import ColorFolder from './components/Core/ColorFolder';
import PlanOutline from './components/Outlines/PlanOutline';
import { BodyText, SubheaderText, CommentHeader, CommentText, LargeGutter, SmallGutter } from './components/Core/TextComponents';
import * as importedPlans from './plans/plans.json';
import Devotionals from './plans/Devotionals';
import { Dialog, DialogActions, TextField, DialogTitle, DialogContent, DialogContentText, Button } from '@material-ui/core';
const { bcv_parser } = require('bible-passage-reference-parser/js/en_bcv_parser');
const bcv = new bcv_parser();
bcv.set_options({ consecutive_combination_strategy: 'separate', sequence_combination_strategy: 'separate' });

class Plans extends Component {
    static contextType = ThemeContext;

    getDevotional()
    {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('dev');
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    constructor() {
        super();

        this.state = {
            plans: {},
            open: false,
            email: '',
            selectedDevotional: '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.context.loading(false);

        const keys = Object.keys(importedPlans).filter(x => x !== 'default');

        // const formattedPlans = keys.map(plan => {
        //     const currentPlan = importedPlans[plan];
        //     const formattedStages = currentPlan.stages.map(stage => {
        //         const formattedVerses = stage.passages.map(section => {
        //             let osisRange;
        //             try {
        //                 osisRange = section.verses.includes('-') ? bcv.parse(section.verses).osis() : `${bcv.parse(section.verses).osis()}-${bcv.parse(section.verses).osis()}`;
        //             } catch (error) {
        //                 osisRange = '';
        //             }
        //             return {
        //                 osisRange,
        //                 title: section.title,
        //                 commentary: section.commentary,
        //                 application: section.application,
        //                 applicationQuestions: section.application_questions,
        //                 comprehensionQuestions: section.comprehension_questions,
        //                 centeringPrayer: section.centering_prayer,
        //                 meditation: section.meditation,
        //                 applicationPrayer: section.application_prayer,
        //                 crossReferences: section.cross_references
        //             };
        //         });

        //         return {
        //             title: stage.title,
        //             passages: formattedVerses
        //         };
        //     });

        //     return {
        //         plan,
        //         stages: formattedStages,
        //         summary: currentPlan.summary,
        //         questions: currentPlan.overarchingquestions || currentPlan.overarching_questions
        //     };
        // });

        const formattedPlansObject = Devotionals.getFormattedPlans();

        this.setState((prevState) => ({
            plans: {
                ...prevState.plans,
                ...formattedPlansObject
            }
        }));
    }

    async subscribe() {
        // 1. get data and set endpoint
        const email = (this.state.email);
        const devotional = (this.state.selectedDevotional);

        const { token } = localStorage;
        this.context.loading(true);
        let endpoint = `/v1/devotionals/subscribe`;
        if (process.env.REACT_APP_AWS_API)
        {
            endpoint = window.API_BASE + endpoint;
        }
        else
        {
            endpoint = "http://localhost:5001" + endpoint;
        }

        // 2. send email + devotional id to server
        console.log('endpoint', endpoint);
        console.log('email', email);
        console.log('devotional', devotional);

        if (endpoint !== '') {
            await fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    devotional
                }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            })
                .then((res) => res.json())
                .then((response) => {
                    // console.log("Response: " + new Date().getSeconds());
                    console.log("response: " + response.subscriptionCode);
                    alert('A email verification message has been sent to your email address, please check your spam folder if it does not arrive within 5 minutes.');
                }).catch((e)=>{
                    console.log('exception: ', e);
                    alert('error contacting to server', e);
                });
        }

        this.setState({open: false});
    }

    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    render() {
        let filter = this.props.filter || decodeURIComponent(this.getDevotional());
        if (filter == 'null') filter = null;

        const thePlans = this.state.plans;
        const keys = Object.keys(thePlans).filter(x => x !== 'default');

        // keys.forEach(x => {
        //     console.log('dev=', encodeURIComponent(x))
        // })

        // console.log('filter [' + filter + ']')

        return (
            <>
                <Banner text="Experimental new feature being explored: Bible plans generated by AI, reviewed by humans."></Banner>
                <Dialog open={this.state.open} onClose={()=>{console.log('closed')}} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        To subscribe to this devotional, please enter your email address here. We will send a verification to your email for you to accept.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={this.state.email}
                        onChange={(e)=>{this.handleEmailChange(e)}}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{this.setState({open: false})}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={async (e)=>{
                        await this.subscribe(e, this.state.selectedDevotional);
                    }} color="primary">
                        Subscribe
                    </Button>
                    </DialogActions>
                </Dialog>              
 
                <LargeGutter></LargeGutter>
                <ol>
                    {keys.map((x, index) => {
                        return (((filter == x)||(!filter))?
                        <React.Fragment key={index}>
                            <ColorFolder title={x} colorIndex={8} startsOpen={filter} actionLabel={'send daily'} action={(e)=>{
                                this.setState({open: true});
                                this.setState({selectedDevotional: x})
                            }}>
                                <CommentHeader>Summary:&nbsp;</CommentHeader>
                                <CommentText>{thePlans[x].summary}</CommentText>

                                <LargeGutter></LargeGutter>

                                <CommentHeader>Stages </CommentHeader>
                                {thePlans[x].stages.map((stage, index) => (
                                    <PlanOutline key={index} stage={stage} outline={stage.passages} title={stage.title} showBookName colorIndex={2} />
                                ))}

                                <LargeGutter></LargeGutter>

                                {thePlans[x].questions && (
                                    <>
                                        <CommentHeader>
                                            <strong style={{ fontWeight: 'bold' }}>Questions</strong>
                                        </CommentHeader>
                                        <ol>
                                            {thePlans[x].questions.map((question, index) => (
                                                <li key={index}>
                                                    <CommentText>{question}</CommentText>
                                                </li>
                                            ))}
                                        </ol>
                                    </>
                                )}
                            </ColorFolder>
                            <SmallGutter></SmallGutter>
                        </React.Fragment>:null
                    )})}
                </ol>
            </>
        );
    }
}

export default Plans;