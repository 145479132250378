import React from 'react';
import Collapsable from '../Core/Collapsable';
import { SentimentChart } from '../Charts/SentimentCharts';
import { PrescriptiveDescriptiveChart } from '../Charts/VerbAnalysisCharts';

function How(props) {
    const { data, sources, figureNumber } = props;
    const { byverse, details } = data;
    const { details: sourceDetails } = sources;
    return (
        <div>
            <Collapsable startsOpen={window.web_version} title="Prescriptive vs Descriptive Analysis">
                <PrescriptiveDescriptiveChart
                    sentences={byverse}
                    sources={sourceDetails}
                    figureNumber={figureNumber}
                />
            </Collapsable>
            <Collapsable startsOpen={window.web_version} title="Positivity vs Negativity Analysis">
                <SentimentChart
                    sentences={byverse}
                    details={details}
                    sources={sourceDetails}
                    figureNumber={figureNumber + 1}
                />
            </Collapsable>
        </div>
    );
}

export {
    How,
};
