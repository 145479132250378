import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withRouter } from 'react-router-dom';
import { Collapse, Tab, Tabs } from 'react-bootstrap';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeContext } from '../Core/ThemeContext';
import { BodyText, FootnoteReference } from '../Core/TextComponents';
import '../../index.css';

const descriptionStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'wrap',
    width: 2000,
    paddingBottom: 0,
    marginBottom: 0,
    cursor: 'pointer',
};

function SizedCardMedia(props) {
    const isTabletOrMobileDevice = useMediaQuery('(max-width: 1224px)');
    const isSmallTabletOrMobileDevice = useMediaQuery('(max-width: 768px)');

    let size = 92;
    if (isSmallTabletOrMobileDevice) size = 112;
    else if (isTabletOrMobileDevice) size = 102;

    return (
        <CardMedia
            image={props.src}
            title={props.value}
            style={{
                cursor: 'pointer',
                width: 'auto',
                height: { size },
                minWidth: { size },
                minHeight: { size },
            }}
            className=""
            component="img"
            alt="N/A"
            height={size}
            width={size}
        />
    );
}

class CardHolder extends Component {
    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = { open: false, key: 'what' };
        this.handleSelect = this.handleSelect.bind(this);
        this.cardSize = props.size;
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    handleSelect(tab) {
        console.log('CardHolder: Selected: ', tab, this);
        if (tab !== '') this.setState({ key: tab });
    }

    render() {
        return (
            <div>
                <Card
                    style={{
                        display: 'flex',
                        background: '#fff',
                        margin: 0,
                        borderRadius: 0,
                    }}
                    className="bookcard"
                    width={1}
                >
                    <SizedCardMedia
                        onClick={() => {
                            this.setState((prevState) => ({ open: !prevState.open }));
                        }}
                        src={this.props.src}
                        value={this.props.value}
                    />
                    <CardContent
                        style={descriptionStyle}
                        onClick={() => {
                            this.setState((prevState) => ({ open: !prevState.open }));
                        }}
                    >
                        <h4
                            className="mb-0"
                            variant="h6"
                            style={{ fontFamily: this.context.scriptfont, fontSize: '2.2em' }}
                            component="h3"
                        >
                            {this.props.detailsheader}
                        </h4>
                        <p
                            style={{
                                color: '#667',
                                fontSize: '0.75em',
                            }}
                        >
                            {this.props.details}
                            {(this.props.detailsFootnote)
                                ? (
                                    <FootnoteReference
                                        number={this.props.detailsFootnote}
                                        nolink
                                    />
                                ) : null}
                        </p>
                    </CardContent>
                    <CardActions align="end" className="mr-3">
                        <a href={window.getVerseURL(this.props.link)}>READ</a>
                    </CardActions>
                </Card>
                <Collapse in={this.state.open}>
                    <div>
                        <Tabs
                            className="customTabs"
                            activeKey={this.state.key}
                            onSelect={this.handleSelect}
                            style={{
                                paddingTop: 10,
                            }}
                        >
                            <Tab className="customTab1" eventKey="what" title="What">
                                <BodyText>
                                    General information about the passage:
                                </BodyText>
                                <BodyText> 1. Immediate Literary context </BodyText>
                                <BodyText> 2. Book - Genre </BodyText>
                                <BodyText> 3. Book - Grouping </BodyText>
                                <BodyText> 4. Book - Chapter summary (easton?) </BodyText>
                                <BodyText> 5. Objects </BodyText>
                                <BodyText> 6. Events </BodyText>
                            </Tab>
                            <Tab className="customTab2" eventKey="who" title="Who">
                                <BodyText id="example-collapse-text">
                                    People and people groups involves
                                </BodyText>
                                <BodyText> 1. List of people involved - meaning of names </BodyText>
                                <BodyText> 2. Link to easton dictionary </BodyText>
                                <BodyText>
                                    3. Trace people to other passages in the bible
                                </BodyText>
                                <BodyText> 4. People Groups involved with link to easton</BodyText>
                                <BodyText>
                                    5. Trace People Groups to other passages in the bible
                                </BodyText>
                            </Tab>
                            <Tab className="customTab3" eventKey="where" title="Where">
                                <BodyText id="example-collapse-text">
                                    Geographical context
                                </BodyText>
                                <BodyText> 1. Expandable Map </BodyText>
                                <BodyText> 2. List of places with meaning of names </BodyText>
                                <BodyText> 2. Link to easton for place name </BodyText>
                                <BodyText>
                                    3. Trace occurances of places back to other verses
                                </BodyText>
                            </Tab>
                            <Tab className="customTab4" eventKey="when" title="When">
                                <BodyText id="example-collapse-text">
                                    Time period information
                                </BodyText>
                                <BodyText> 1. Date of book, some kind of commentary info </BodyText>
                                <BodyText> 2. Period with people born in that period </BodyText>
                                <BodyText> 3. Link to other things in the same period </BodyText>
                            </Tab>
                            <Tab className="customTab5" eventKey="how" title="How">
                                <BodyText id="example-collapse-text">
                                    In what manner was this done?
                                </BodyText>
                                <BodyText> 1. Sentiment</BodyText>
                                <BodyText> 2. Sentiment across chapter </BodyText>
                            </Tab>
                            <Tab className="customTab6" eventKey="why" title="Why">
                                <BodyText id="example-collapse-text">
                                    Detailed information on the passage
                                </BodyText>
                                <BodyText id="example-collapse-text">
                                    1. Commentary (attempt to home in for verse / chapter)
                                </BodyText>
                            </Tab>
                            <Tab className="customTab1" eventKey="structure" title="Form">
                                <BodyText id="example-collapse-text">
                                    Detailed text breakdown for the passage
                                </BodyText>
                                <BodyText id="example-collapse-text">
                                    1. Uses of people / places vs objects...
                                </BodyText>
                            </Tab>
                            <Tab className="customTab1" eventKey="sources" title="Source">
                                <BodyText id="example-collapse-text">
                                    Document Source information
                                </BodyText>
                                <BodyText>
                                    1. Disclaimer about free sources remaining free and paid sources later
                                </BodyText>
                                <BodyText> 2. Commentary version </BodyText>
                                <BodyText> 3. Bible dictionary version </BodyText>
                            </Tab>
                        </Tabs>
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default withRouter(CardHolder);
