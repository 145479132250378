import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Collapsable from './components/Core/Collapsable';
import { BodyText, SubheaderText } from './components/Core/TextComponents';
import { ThemeContext } from './components/Core/ThemeContext';
import Outline from './components/Outlines/Outline';

const outline = {
    /* the args you need here will depend on your component */
    colorIndex: 1,
    title: "Separation Through Disobedience",
    subsections: [
        {
          "osisRange": "Gen.4.1-Gen.6.22",
          "title": "Corruption of Humanity",
          "text": "Humanity descends into murder and corruption outside of God's presence, and suffers judgement.",
          "footnote": []
        },
        {
          "osisRange": "Gen.7.1-Gen.10.32",
          "title": "Noah's Obedience",
          "text": "Noah is obedient and blameless and is saved and blessed.",
          "footnote": []
        },
        {
          "osisRange": "Gen.11.1-Gen.11.32",
          "title": "Tower of Babel",
          "text": "Humanity seeks to save themselves by building a name for themselves.",
          "footnote": []
        }
      ]
};

class ContactUs extends Component {
    static contextType = ThemeContext;

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.context.loading(false);
    }

    componentWillUnmount() {
        console.log('Will Unmount');
    }

    render() {
        document.title = 'ai|Help';
        // return(<li key={index}>{index}:{value}</li>);
        return (
            <div style={{ padding: 25 }}>
                <br />
                <SubheaderText>Help</SubheaderText>
                <br />
                <BodyText> ai-BIBLE is a line-by-line bible appliction built to help faciliate application and interpretation of the bible. It uses artificial intelligence in a number of ways to help present the bible. ai is used to help pull together different data sources to make them presentable, ai is also used to process questions in natural language to help the chatbot respond to questions about the bible.</BodyText>
                <br></br>
                <BodyText>
                    ai-BIBLE uses mostly data from public domain sources or sources where licencing has been explictly granted. Much of the information in ai-BIBLE comes from either the Berean bible, Openbible info or Robert Rouse's theographic bible information. Some information has been added by hand, such as the summary of the bible for the cross references in context so as to ensure accuracy, in these cases there are source dropdowns so that you can see consulted sources.
                </BodyText>
                <br />
                <BodyText>
                    ai-BIBLE chat falls back on open AI's Chat-GPT for responses when it does not have a specific response for the user. The responses from Chat-GPT are reviewed periodically for correctness.
                </BodyText>
                <br></br>
                <BodyText>
                    There is a lot of information in ai-BIBLE and to keep the experience simple and uncluttered, that information is often hidden to start with, try clicking on the box below to bring up an outline. Outlines or groups of cross refereneces are presented in a unique context aware style that you will see when opening the box below.
                </BodyText>
                <br></br>
                <Collapsable title="Click on me for an outline">
                    <BodyText>
                        An outline is show below, note how the text on the boxes help explain what the passage is about, and each number can be clicked on to bring up the passage in the bible.
                    </BodyText>
                    <br></br>
                    <BodyText>
                        {outline.title}
                    </BodyText>
                    <Outline outline={outline.subsections} title='Cross references in context' >
                        
                    </Outline>
                </Collapsable>
                <br></br>
                <BodyText>
                    You can click on the same line in the box above to close it again.
                </BodyText>
                <br></br>
                <strong>
                    <SubheaderText style={{fontWeight:'bold'}}>
                        ChatBot
                    </SubheaderText>
                </strong>
                <br></br>
                <BodyText>
                    The chatbot will attempt to answer your questions, if it does not have an answer then it will fall back to chatGPT to get an answer. The answers are stored and reviewed so that they are then re-used by the bot with any corrections or ammendments.
                </BodyText>
                <br></br>
                {(window.web_version)?
                <>
                    <br></br>
                    <BodyText>
                        If you have any questions then you can get in touch on the form below:
                    </BodyText>
                    <br></br>
                    
                    <form id="ajax-contact" method="post" action="https://www.theshapeofsound.com/mailer.php">
                        <div className="field input_field">
                            <label htmlFor="name" className="input_label">Name:</label>
                            <input type="text" className="input_text" id="name" name="name" required />
                        </div>

                        <div className="field input_field">
                            <label htmlFor="email" className="input_label">Email:</label>
                            <input type="email" id="email" name="email" className="input_text" required />
                        </div>

                        <div className="field">
                            <label htmlFor="message" className="input_label" style={{ textAlign: 'left', verticalAlign: 'top' }}>Message:</label>
                            <textarea id="message" name="message" className="input_textarea" required />
                        </div>

                        <ReCAPTCHA
                            sitekey="6LfrH9MUAAAAAFe13mga-k3s6sF7tzi6krOreESP"
                        />

                        <div className="field input_field">
                            <label className="input_label" />
                            <button type="submit" className="input_button">Send</button>
                        </div>
                    </form>
                </>:null}
            </div>
        );
    }
}

export default ContactUs;
