import React, { Component } from 'react';
import './App.css';
import './bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from './components/Core/ThemeContext';
import ChatBot from './components/Chat/ChatBot';
import Banner from './components/Core/Banner';

const searchStyle = {
    background: '#ffffc9',
    padding: '30px',
    margin: '0px',
};

class Chat extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            contacts: [],
            search: 'John.12.1-John.12.11',
            previoussearch: 'John.12.1-John.12.11',
        };

        this.handleTextFieldKeyDown = this.handleTextFieldKeyDown.bind(this);
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.context.loading(false);
    }

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    handleTextFieldKeyDown = (event) => {
        const location = `${window.origin}/exegete?verse=${this.state.search}`;

        switch (event.key) {
        case 'Enter':
            this.setState({ contacts: [] });
            console.log('Navigate', this.state.search);
            window.location = location;
            break;
        case 'Escape':
            // etc...
            break;
        default:
            break;
        }
    }

    handleChange = (event) => {
        this.setState({ search: event.target.value });
    };

    render() {
        document.title = 'ai|Chat';
        console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}
            >
                <Banner text="Usage: for personal research purposes only. Please do not submit identifying information into the chat as responses are monitored. The current plan is to integrate widgets like wordclouds that can be accessed from custom prompts." />
                <ChatBot />
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: -15,
                }}
                />
            </div>
        );
    }
}

export default Chat;
