import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { ThemeContext } from '../Core/ThemeContext';
import '../../index.css';
import { FootnoteReference } from '../Core/TextComponents';

const descriptionStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'wrap',
    width: '100%',
    height: 82,
    paddingBottom: 0,
    marginBottom: 0,
};

class LocationCard extends Component {
    // eslint-disable-next-line
    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = { open: false };
        this.handleSelect = this.handleSelect.bind(this);
        this.cardSize = props.size;
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    handleSelect(tab) {
        console.log('LocationCard: Selected: ', tab, this);
    }

    render() {
        const location = this.props.place;

        // console.log('LocationCard: Place: ', location, this.props.place.feature);

        if (!location) return (null);

        // eslint-disable-next-line max-len
        const header = `${(this.props.place.feature) ? `${this.props.place.name} - ${this.props.place.feature}` : this.props.place.name} ${(this.props.place.score)?'- ' + this.props.place.score:''}`;
        const details = (this.props.place.meaning || this.props.place.description);

        // console.log('header', header)
        // console.log('headerx', `${this.props.place.name} - ${this.props.place.feature}`)

        let color = '1';

        const colors = {
            Region: '2',
            Island: '2',
            Settlement: '4',
            City: '4',
            River: 'blue',
            Oasis: 'blue',
            Wateropen: 'blue',
            Water: 'blue',
            Mountain: '3',
            Landmark: '5',
            Church: '5',
            Location: '7',
        };

        if (location.feature) {
            if (Object.prototype.hasOwnProperty.call(colors, 'location.feature')) {
                color = colors[location.feature];
            }
        }

        return (
            <div>
                <Card
                    style={{
                        display: 'flex',
                        background: '#fff',
                        margin: 0,
                        borderRadius: 0,
                    }}
                    className="bookcard"
                    width={1}
                >

                    <div className="card-size" style={{ display: 'inline-block', float: 'left' }}>
                        <img
                            alt=""
                            className={`background-grad-${color} card-size`}
                            style={{
                                pointerEvents: 'none',
                                width: 20,
                                height: '100%',
                                minWidth: 20,
                                display: 'inline-block',
                                opacity: 20,
                            }}
                        />
                    </div>

                    <CardContent
                        style={descriptionStyle}
                        onClick={() => {
                            this.setState((prevState) => ({ open: !prevState.open }));
                        }}
                    >
                        <h4 variant="h6" component="h3">
                            {header}
                        </h4>
                        <p
                            style={{
                                color: '#667',
                                overflow: 'hidden',
                                textOverflow: 'wrap',
                                display: 'inline',
                            }}
                        >
                            meaning:&nbsp;
                            {details}
                        </p>
                        {(this.props.footnoteRef)
                            ? (
                                <FootnoteReference
                                    number={this.props.footnoteRef}
                                />
                            ) : null}
                    </CardContent>
                    <CardActions align="end">
                        <a
                            href={`/places?rec=${this.props.link}`}
                        >
                            {(location.feature) ? 'SEARCH' : 'EXPLORE'}
                        </a>
                    </CardActions>
                </Card>
            </div>
        );
    }
}

export default LocationCard;
