import React from 'react';
import './OutlineBox.css';
import { Box } from '@material-ui/core';
import VerseButton from './VerseButton';

const VerseBox = (props) => {
    const { children, title, subsections } = props;
    const nested = subsections || [];
    const colorIndex = props.colorIndex || 0;
    const guessWidth = title.length * 8.5;
    // eslint-disable-next-line max-len
    const colorText = getComputedStyle(document.documentElement).getPropertyValue(`--color-raw-${props.colorIndex}`);

    // console.log('nested',nested, nested.length, props.subsections);

    return (
        <Box
            component="div"
            style={{ minWidth: guessWidth }}
            className={`color-${colorIndex} floating-label-container`}
            key={'box_1'}
        >

            {(props.stronger) && (!props.bold)
                ? (
                    <span
                        className="floating-label-up"
                        style={{ color: 'black', whiteSpace: 'nowrap' }}
                        key={'span_1'}
                    >
                        {title}
                    </span>
                )
                : (
                    <span
                        className="floating-label-up"
                        style={{ color: '#00000099', whiteSpace: 'nowrap' }}
                        key={'span_2'}
                    >
                        {title}
                    </span>
                )}
            {(props.bold)
                ? (
                    <span
                        className="floating-label-up"
                        style={{ color: 'black' }}
                        key={'span_3'}
                    >
                        <strong>{title}</strong>
                    </span>
                ) : null}
            {nested.map((subsection, index) => (
                <>
                    { 
                        (subsection.contents) ?
                        <VerseBox
                            title={subsection.title}
                            key={index + "_0_" + Math.floor(Math.random() * 1000)}
                            colorIndex={colorIndex}
                        >
                            { 
                                subsection.contents.map((content, index2)=>(
                                    <VerseButton
                                        colorText={colorText}
                                        content={content}
                                        colorIndex={colorIndex}
                                        key={index2 + "_0"}
                                        />
                                ))
                            }
                        </VerseBox>: null
                    }
                </>
            ))}
            <>
                {children}
            </>
        </Box>
    );
};

const VerseContainerBox = (props) => {
    const { children, subsections } = props;
    const nested = subsections || [];
    const colorIndex = props.colorIndex || 0;
    // eslint-disable-next-line max-len
    const colorText = getComputedStyle(document.documentElement).getPropertyValue(`--color-raw-${props.colorIndex}`);

    // TODO: remove if not needed
    // {nested.map((subsection, index) => (
    //     <VerseBox
    //         title={subsection.title}
    //         key={index}
    //         colorIndex={colorIndex}
    //     >
    //         <VerseButton
    //             colorText={colorText}
    //             osisRange={subsection.osisRange}
    //             colorIndex={colorIndex}
    //         />
    //     </VerseBox>
    // ))}

    return (
        <>
            <VerseBox
                title={props.title}
                colorIndex={colorIndex}
                key={'container_1_' + Math.floor(Math.random() * 1000)}>
                    {children}
            </VerseBox>
        </>
    );
};

export {
    VerseBox,
    VerseContainerBox,
};
