import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import './App.css';
import './bootstrap-4/css/bootstrap.min.css';
import PulseLoader from 'react-spinners/GridLoader';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import Search from '@material-ui/icons/SearchOutlined';
import withHelpDrawer from './components/withHelpDrawer.jsx';
import ConcordanceCard from './components/Cards/ConcordanceCard';
import VerseCard from './components/Cards/VerseCard';
import { SourcePermissions } from './components/Core/TextComponents';
import { ThemeContext } from './components/Core/ThemeContext';
import {Helmet} from "react-helmet";
const querystring = require('querystring');

// import authentication from 'react-azure-adb2c';

const searchStyle = {
    background: '#ffffc9',
    padding: '15px',
    margin: '0px',
};

const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'cyan',
};

const filterOptions = [
    {
        text: 'Wisdom Literature',
        filter: '$wisdom',
    },
    {
        text: 'New Testament',
        filter: '$newTestament',
    },
    {
        text: 'Old Testament',
        filter: '$oldTestament',
    },
    {
        text: 'Gospels & Acts',
        filter: '$gospelActs',
    },
    {
        text: 'Prophets',
        filter: '$prophets',
    },
];

function LoadingPadding(props) {
    if (props.loading) {
        return (
            <h1
                style={{
                    backgroundColor: '#fff',
                    padding: '0',
                    margin: '0',
                }}
            >
&nbsp;
            </h1>
        );
    }
    return (
        <div style={{
            backgroundColor: '#fff',
        }}
        />
    );
}

const HelpPage = () => (
    <div>
        <h1>
            Help Menu Title
        </h1>
        <p>
            help menu contents and video
        </p>
    </div>
);

class ReadingListSearch extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            mounted: false,
            searchResults: [],
            filteredResults: [],
            concordance: {
                entries: [],
                sources: [],
            },
            pagedFilteredResults: [],
            search: [],
            loading: false,
            previoussearch: '',
            lastSearchData: [],
            defaultValue: [],
            filterValue: '',
            tags: '',
            tagsValue: [],
            sources: [],
            searchInput: '',
            page: 1,
            totalPages: 0,
            entriesPerPage: 30,
        };

        if (localStorage.hasOwnProperty('lastSearch')) {
            let data = localStorage.lastSearch;
            if (typeof data === 'string' || data instanceof String) {
                try {
                    console.log('Constructor: Parsing string data');
                    data = JSON.parse(data);
                    console.log(`data type: ${typeof data} ${data.search}`);
                    this.setState({ previoussearch: data.search });
                } catch (e) {
                    console.err(e);
                }
            }
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentWillMount() {
        console.log('searchprops', this.props);
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const query = params.get('query');

        if (query != null) {
            console.log(`Setting default value: ${query}`);
            this.setState({ defaultValue: [query], search: [query] });
        }
    }

    componentDidMount() {
        this.setState({ mounted: true });
        this.context.loading(false);

        // TODO: This code has been left in here in case Azure B2C is used with this
        // fetch('http://netcoreservices.azurewebsites.net/api/values')
        // fetch('https://localhost:5001/api/values', {   headers : {
        // 'Authorization':'Basic dGVzdDp0ZXN0' }}) .then(res => res.json())
        // .then((data) => {   console.log("response: " + data);   this.setState({
        // searchResults: data }) }) .catch(function(err) {   console.log('Fetch Error :-S',
        // err); }) console.log("Page1 Mount: " + this.state.mounted);

        if (localStorage.hasOwnProperty('lastSearch')) {
            console.log(`lastSearch type: ${typeof localStorage.lastSearch} ${localStorage.lastSearch}`);

            console.log(localStorage.lastSearch);
            this.displaySearch(localStorage.lastSearch, this.state.tagsValue);
        }

        console.log(this.state.searchResults);

        if (this.defaultValue !== []) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        console.log('Will Unmount');
    }

    displaySearch = function (data, filters) {
        const output = [];
        console.log(`Received data to display: ${typeof data}`);

        if (typeof data === 'string' || data instanceof String) {
            try {
                console.log('Parsing string data');
                data = JSON.parse(data);
                console.log(`data type: ${typeof data} ${data.search}`);
                this.setState({ previoussearch: '' });

                data = data.data;
                console.log(`data type inner: ${typeof data}`);
                console.log(data);
            } catch (e) {
                console.err(e);
                return;
            }
        }

        console.log('data', data);

        if (!data) return;

        if (!data.concordance) return;

        if (data.concordance) {
            this.setState({ concordance: data.concordance });
        }

        if (!data.results) return;

        data.results.forEach((element) => {
            let subgroup = element
                .Subgroup
                .replace(/([A-Z])/g, ' $1')
                .trim();

            if (subgroup === 'Gospel And Acts') { subgroup = 'The Gospels & Acts'; }

            const entry = {
                value: element.Verse,
                img: `/images/${element.BookCode}.jpg`,
                bookname: element.Book,
                summary: element.Text,
                summaryExt: element.TextExt,
                verse: element.Verse,
                subgroup,
                grouping: element.Grouping,
                book: element.Book,
                bookcode: element.BookCode,
                osisref: element.OsisRef,
            };

            output.push(entry);
        });

        const filteredResults = this.filterResults(output, filters);
        const totalPages = Math.ceil(filteredResults.length / this.state.entriesPerPage);
        const pagedFilteredResults = this.pageFilteredResults(filteredResults, totalPages);

        this.setState({
            searchResults: output, totalPages, filteredResults, pagedFilteredResults, loading: false, lastSearchData: data, sources: data.sources,
        });
    }

    pageFilteredResults = (results, pages) => {
        const paged = [];
        const elementsPerPage = this.state.entriesPerPage - ((Object.keys(this.state.concordance.entries).length > 0) ? 1 : 0);
        const start = (this.state.page - 1) * elementsPerPage;
        const end = start + elementsPerPage;

        console.log(`Pagination: ${start}/${end} ${this.state.page}`);
        for (let i = start; i < end; i++) {
            if (i < results.length) {
                paged.push(results[i]);
            }
        }

        return paged;
    }

    filterResults = (searchResults, filters) => {
        const filtered = [];

        searchResults.forEach((searchResult) => {
            let eliminated = true;

            if (filters) {
                filters.forEach((filter) => {
                    if (filter.filter != null) // We are one of the autocomplete options
                    {
                        // console.log("Filtering: [" + searchResult.grouping.replace("  "," ") + "] [" + searchResult.subgroup.replace("  "," ") +"]")
                        if (filter.filter === '$newTestament') if (searchResult.grouping === 'New Testament') eliminated = false;

                        if (filter.filter === '$oldTestament') if (searchResult.grouping === 'Old Testament') eliminated = false;

                        if (filter.filter === '$wisdom') if (searchResult.subgroup === 'Wisdom') eliminated = false;

                        if (filter.filter === '$gospelActs') if (searchResult.subgroup.replace('  ', ' ').replace('  ', ' ') === 'The Gospels & Acts') eliminated = false;

                        if (filter.filter === '$prophets') {
                            if ((searchResult.subgroup.replace('  ', ' ') === 'Minor Prophets')
                                || (searchResult.subgroup.replace('  ', ' ') === 'Major Prophets')) eliminated = false;
                        }
                    } else // we are a typed in text filter
                    { // 1. We could have a bookname
                        if (searchResult.book.toLowerCase() === filter.toLowerCase()) eliminated = false;

                        if (searchResult.summary.toLowerCase().includes(filter.toLowerCase())) eliminated = false;
                    }
                });

                if (filters.length === 0) eliminated = false;
            } else eliminated = false;

            if ((!eliminated)) filtered.push(searchResult);
        });

        console.log('Filtered', filtered);

        return filtered;
    }

    killLoader = function () {
        this.setState({ searchResults: [], loading: false });
    };

    fetchData = () => {
        const { token } = localStorage;

        this.setState({ loading: true, page: 1 });
        this.setState({ searchResults: [] });

        const searchTerm = [...this.state.search];
        if (this.state.searchInput) if (!searchTerm.includes(this.state.searchInput)) searchTerm.push(this.state.searchInput);

        const current = this;
        const body = JSON.stringify({
            search: searchTerm,
            // "startResult":0,
            // 'maxResults':5
        });

        console.log(`Search state: ${this.state.search}`);
        console.log(`Search input: ${this.state.searchInput}`);
        console.log(`Posting search: ${body}`);

        
        let endpoint = 'http://localhost:5001' + '/v1/data/search';

        if ((process.env.REACT_APP_AWS_API)||(!window.web_version))
        {
            endpoint = window.API_BASE + '/v1/data/search';
        }

        fetch(endpoint, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body,
        })
            .then((res) => res.json())
            .then((data) => {
                data = {
                    data,
                    search: this.state.search,
                };

                // TODO: EU Cookie Consent Form localStorage.lastSearch = JSON.stringify(data);

                console.log(`response: ${JSON.stringify(data)}`);

                this.displaySearch(JSON.stringify(data), this.state.tagsValue);
            })
            .catch((err) => {
                current.killLoader();
                console.log('Fetch Error :-S', err);
            });
    }

    handleTextFieldKeyDown = (event) => {
        switch (event.key) {
        case 'Enter':
            this.fetchData();
            break;
        case 'Escape':
            // etc...
            break;
        default:
            break;
        }
    }

    handlePageChange = (event, value) => {
        console.log('handlePageChange', event.target, value);
        this.setState({ page: value }, () => {
            const paged = this.pageFilteredResults(this.state.filteredResults, this.state.totalPages);
            this.setState(() => ({ pagedFilteredResults: paged }));
        });
    }

    onTagsChange = (event, values) => {
        this.setState({
            tagsValue: values,
            page: 1,
        }, () => {
            // This will output an array of objects
            // given by Autocompelte options property.
            console.log(`AddedFilter: ${JSON.stringify(this.state.tagsValue)}`);

            if (this.state.lastSearchData) {
                this.displaySearch(this.state.lastSearchData, this.state.tagsValue);
            }
        });
    }

      onSearchChange = (event, values) => {
          this.setState({
              search: values,
          }, () => {
          // This will output an array of objects
          // given by Autocompelte options property.
              console.log(`Search Value: ${JSON.stringify(this.state.search)}`);

              //   if (this.state.lastSearchData)
              //   {
              //     this.displaySearch(this.state.lastSearchData, this.state.tagsValue);
              //   }
          });
      }

    handleChange = (event) => {
        this.setState({ search: event.target.value });
    };

    handleUpdateInput = (searchText) => {
        this.setState({ searchInput: searchText.target.value });
    }

    render() {
        console.log(`Concordance: ${JSON.stringify(this.state.concordance)}`);
        // console.log("render: " + this.state.searchResults);
        // return(<li key={index}>{index}:{value}</li>);
        const HelpDrawer = withHelpDrawer(HelpPage);
        document.title = 'ai|Search';

        const searchedRec = querystring.parse(window.location.search.substr(1)).query;
        const canonical = `https://www.ai-bible.com/Search?query=${searchedRec}`;

        return (
            <div style={{
                background: '#fcfcfc',
            }}
            >
                <Helmet>
                    <link rel="canonical" href={canonical} />
                </Helmet>
                <HelpDrawer />
                <div style={searchStyle}>
                    <div style={{ display: 'flex' }}>
                        <Autocomplete
                            className="mt-1"
                            style={{ flexBasis: '100%' }}
                            multiple
                            freeSolo
                            options={[]}
                            filterSelectedOptions
                            onInputChange={this.handleUpdateInput.bind(this)}
                            id="tags-outlined"
                            defaultValue={this.state.defaultValue}
                            onChange={this.onSearchChange}
                            renderTags={(value, getTagProps) => value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Enter a word or phrase to search for"
                                    placeholder="e.g. forgiveness, love your enemies"
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}

                        />

                        <div style={{ flexBasis: 0 }} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Autocomplete
                            style={{ flexBasis: '100%' }}
                            className="mt-3"
                            multiple
                            freeSolo
                            id="tags-outlined"
                            options={filterOptions}
                            getOptionLabel={(option) => option.text}
                            defaultValue={[]}
                            filterSelectedOptions
                            onChange={this.onTagsChange}
                            renderTags={(value, getTagProps) => value.map((option, index) => (
                                <Chip variant="outlined" label={option.text ? option.text : option} {...getTagProps({ index })} />
                            ))}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Allow only results using these filters" placeholder="Select or type in a filter" />
                            )}
                        />
                        <Button className="mt-3" aria-label="search" onClick={this.fetchData} style={{ flexBasis: 57, height: 57 }}>
                            <Search style={{ height: 57 }} />
                        </Button>
                    </div>
                </div>

                <LoadingPadding loading={this.state.loading} />

                <div
                    style={{
                        display: 'block',
                        background: '#fcfcfc',
                        margin: '0',
                    }}
                >
                    <PulseLoader
                        css={override}
                        size={40}
                        radius={5} // size{"150px"} this also works
                        color="#36D7B7"
                        loading={this.state.loading}
                    />
                </div>

                <ol
                    style={{
                        padding: 0,
                    }}
                    className="p-0 mb-1"
                >
                    {
                        (this.state.concordance)
                            ? (Object.keys(this.state.concordance.entries).length > 0)
                                ? <ConcordanceCard sources={this.state.concordance.sources} sentence={this.state.concordance.entries} />
                                : null
                            : null
                    }
                </ol>

                <ol style={{
                    padding: 0,
                }}
                >
                    {this
                        .state
                        .pagedFilteredResults
                        .map((value, index) => (
                            <VerseCard
                                size="88"
                                value={value.subgroup}
                                index={index}
                                src={value.img}
                                key={index}
                                detailsheader={value.verse}
                                details={value.summary}
                                body={value.summaryExt}
                                link={value.osisref}
                            />
                        ))}
                </ol>
                {
                    (this.state.filteredResults)
                        ? ((this.state.totalPages > 1)
                            ? (
                                <Grid container justify="center">
                                    <Pagination style={{ textAlign: 'center', alignContent: 'center' }} className="mb-3 mt-0" count={this.state.totalPages} page={this.state.page} onChange={this.handlePageChange} />
                                </Grid>
                            )
                            : null)
                        : null
                }
                <div className="m-3">
                    <SourcePermissions sources={this.state.sources} />
                </div>
            </div>
        );
    }
}

export default ReadingListSearch;
